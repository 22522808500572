import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  duties: {
    defaultMessage: '{teamName} Duty',
    description: '[title] Page title',
  },
  dutyCreate: {
    defaultMessage: 'Create duty',
    description: '[title] Page title',
  },
  dutyOverrideCreate: {
    defaultMessage: 'Create override for {dutyName}',
    description: '[title] Page title',
  },
  roleBindings: {
    defaultMessage: '{teamName} accesses',
    description: '[title] Page title',
  },
  team: {
    defaultMessage: 'Team',
    description: '[title] Page title',
  },
  teams: {
    defaultMessage: 'Teams',
    description: '[title] Page title',
  },
});
