import {User} from 'domain/user/model';
import {$users} from 'domain/user/stores/main/state';
import {useStoreMap} from 'effector-react';

export const useUserManager = (user: User): User | undefined => {
  const manager = useStoreMap({
    store: $users,
    keys: [user.managerId],
    fn: (users, [managerId]) => (managerId ? (users.byLogin[users.loginById[managerId]] ?? null) : null),
  });
  return manager ?? undefined;
};
