import {DataState} from '@joomcode/deprecated-utils/dataState';
import {createEntityListStore, createEntityStore, EntityStoreState} from '@joomcode/joom-admin/dataTypes/entityStore';
import {createDutyFx, createDutyOverrideFx, deactivateDutyFx, deleteDutyOverrideFx} from 'domain/duty/stores/main';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {TeamMembers} from 'domain/team/model/members';
import {
  changeParentFx,
  createTeamFx,
  deleteTeamFx,
  getAllTeamsFx,
  requestMembershipFx,
  requestOutstaffMembershipFx,
  revokeMembershipFx,
  revokeOutstaffMembershipFx,
  updateTeamFx,
} from '.';

type State = EntityStoreState<Team>;

const updateMemberships = (state: State, teamId: TeamId, memberships: TeamMembers): State => {
  const previousTeamState = state.byId[teamId];
  if (!previousTeamState) {
    return state;
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [teamId]: {
        ...previousTeamState,
        members: memberships,
      },
    },
  };
};

export const $teams = createEntityStore({
  getEntityId: (team: Team) => team.id,
  query: {
    fx: getAllTeamsFx,
    getIdsFromParams: () => [],
  },
})
  .on(deleteTeamFx.doneData, (state, result) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [result.id]: result,
      },
      list: state.list.filter((id) => id !== result.id),
    };
  })
  .on([createTeamFx.doneData, updateTeamFx.doneData, changeParentFx.doneData], (state, result) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [result.id]: result,
      },
      byIdState: {
        ...state.byIdState,
        [result.id]: DataState.LOADED,
      },
      list: [...state.list, result.id],
    };
  })
  .on(
    [
      requestMembershipFx.done,
      revokeMembershipFx.done,
      requestOutstaffMembershipFx.done,
      revokeOutstaffMembershipFx.done,
    ],
    (state, {params, result}) => updateMemberships(state, params.teamId, result),
  )
  .on(createDutyFx.done, (state, {result, params: {params}}) => {
    const team = state.byId[params.teamId];
    if (!team) {
      return state;
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [params.teamId]: {
          ...team,
          duties: team.duties ? [...team.duties, result] : [result],
        },
      },
    };
  })
  .on(deactivateDutyFx.done, (state, {params: {id, teamId}}) => {
    const team = state.byId[teamId];
    if (!team) {
      return state;
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [teamId]: {
          ...team,
          duties: team.duties ? team.duties.filter((duty) => duty.id !== id) : [],
        },
      },
    };
  })
  .on([createDutyOverrideFx.done, deleteDutyOverrideFx.done], (state, {result, params: {params}}) => {
    const team = state.byId[params.teamId];
    if (!team) {
      return state;
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [params.teamId]: {
          ...team,
          duties: team.duties?.map((duty) => (duty.id === result.id ? result : duty)),
        },
      },
    };
  });

export const $teamList = createEntityListStore($teams);
