import {ReactComponent as CoinIcon} from '@joomcode/joom-ui/icons/core/coinBold.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {SecurePermission} from 'domain/permission/model/secure';
import {User} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: User;
};

export function SalaryManagerIcon({user}: Props) {
  const acl = useAcl();
  const intl = useIntl();

  if (!user.isSalaryManager || !acl.hasSecurePermission(SecurePermission.SECURE_ROLE_BADGE_READ)) {
    return null;
  }

  return (
    <TooltipFaq content={intl.formatMessage(messages.tooltip)}>
      <span className={styles.root}>
        <CoinIcon />
      </span>
    </TooltipFaq>
  );
}
