import {HOUR} from '@joomcode/deprecated-utils/time';

const timeZoneNameByOffset: Record<number, string> = {
  0: 'UTC',
};

export const getTimeZoneByOffset = (offset: number) => timeZoneNameByOffset[offset];

export const getDefaultTimeZone = () => getTimeZoneByOffset(0);

export const getStartOfTheDayInTimezone = (dateString: string, timezoneOffset: number): Date => {
  const date = new Date(dateString);
  const offsetInMilliseconds = timezoneOffset * HOUR;
  date.setUTCHours(0, 0, 0, 0);

  return new Date(date.getTime() + offsetInMilliseconds);
};

export const getEndOfTheDayInTimezone = (dateString: string, timezoneOffset: number): Date => {
  const date = new Date(dateString);
  const offsetInMilliseconds = timezoneOffset * HOUR;
  date.setUTCHours(23, 59, 59, 999);

  return new Date(date.getTime() + offsetInMilliseconds);
};
