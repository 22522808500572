import React, {useEffect, useState} from 'react';
import {MINUTE} from '@joomcode/deprecated-utils/time';
import {createInterval, isBoundedBy} from '../utils';
import {useTimeline} from '../Timeline/Provider';
import {useGetDatePosition} from '../hooks/useGetDatePosition';
import styles from './index.css';

const UPDATE_INTERVAL = MINUTE * 30;

export function Now() {
  const {boundary} = useTimeline();
  const [now, setNow] = useState(() => Date.now());
  const getDatePosition = useGetDatePosition();
  const interval = createInterval({start: now, end: now});

  useEffect(() => {
    let timer = setTimeout(function next() {
      setNow(Date.now());
      timer = setTimeout(next, UPDATE_INTERVAL);
    }, UPDATE_INTERVAL);

    return () => clearTimeout(timer);
  }, []);

  if (isBoundedBy(interval, boundary)) {
    const position = getDatePosition(now);
    return <div style={{left: position}} className={styles.Now} />;
  }

  return null;
}
