function generateTimeOptions(): string[] {
  const result: string[] = [];

  for (let i = 0; i < 24; i++) {
    const hour = i.toString().padStart(2, '0');
    result.push(`${hour}:00:00`, `${hour}:30:00`);
  }

  return result;
}

export function getAvailableTimeOptions(initialValue?: string, minDate?: Date): string[] {
  const options = generateTimeOptions();
  let result = options;
  let minTime = options[0];

  if (minDate) {
    const hours = minDate.getUTCHours();
    const minutes = minDate.getUTCMinutes();
    minTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  }

  if (initialValue) {
    const value = initialValue.slice(0, 5) + `:00`;
    result = options.includes(value) ? options : [value, ...options].sort();
  }

  return result.filter((time) => time >= minTime);
}
