import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';

export const dutyIdSchema = idSchema;
export const dutyLiteSchema = object({
  id: dutyIdSchema,
  name: string(),
});

export type DutyId = ExtractSchemaType<typeof dutyIdSchema>;
export type DutyLite = ExtractSchemaType<typeof dutyLiteSchema>;
