import {Item} from '@joomcode/joom-ui/Timeline';
import React, {useCallback} from 'react';
import {Duties} from '../Duties';
import {type TimelineGroupRender} from '../types';

export function useGhostDutyRender() {
  return useCallback<TimelineGroupRender<'ghostDuty'>>((interval) => {
    const users = interval.items.flatMap((item) => item.data.slot.users);
    return (
      <Item interval={interval}>
        <Duties ghost users={users} />
      </Item>
    );
  }, []);
}
