import {Avatar} from 'components/ui/Avatar';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {type TooltipItem} from '../../../types';
import styles from './styles.css';

type PropsType = {
  item: TooltipItem;
};

export function Item({item: {user, content}}: PropsType) {
  if (!content && !user) {
    return null;
  }

  return (
    <article className={styles.Item}>
      {user ? (
        <header className={styles.header}>
          <Avatar name={user.fullName} imageBundle={user.avatarBundle} size='xs' />
          <UserLink user={user} withAvatar={false} target='_blank' />
        </header>
      ) : null}
      {content ? <div className={styles.content}>{content}</div> : null}
    </article>
  );
}
