import {assertNever} from '@joomcode/deprecated-utils/types';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {ReactComponent as WarningIcon} from '@joomcode/joom-ui/icons/circle/warning.svg';
import React, {forwardRef, type ReactNode, type RefObject} from 'react';
import {Severity} from '../types';
import styles from './styles.css';

type PropsType = {
  severity: Severity;
};

function useIcon(severity: Severity): ReactNode {
  switch (severity) {
    case Severity.HIGH:
      return <CrossIcon className={styles.high} />;
    case Severity.LOW:
      return <WarningIcon className={styles.low} />;
    default:
      assertNever(severity);
      throw new Error(`Unknown severity: ${severity}`);
  }
}

export const IssueIcon = forwardRef(function Item({severity}: PropsType, ref: RefObject<HTMLDivElement>) {
  const icon = useIcon(severity);

  if (!icon) {
    return null;
  }

  return (
    <div ref={ref} className={styles.IssueIcon}>
      {icon}
    </div>
  );
});
