import {Page} from 'components/widgets/Page';
import {useManagerConsolePageTabs} from 'domain/managerConsole/hooks/useManagerConsolePageTabs';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {ManagerConsolePageTabs} from 'domain/managerConsole/widgets/PageTabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {useUserFullInfoReadAccess} from 'domain/userFullInfoRecord/hooks/useReadAccess';
import {UserFullInfoRecordList} from 'domain/userFullInfoRecord/widgets/List';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/managerConsole/titles';
import {useAcl} from 'services/acl';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';

export function ManagerConsoleSubordinatesPage() {
  const intl = useIntl();
  const {showTabs, availableTabs} = useManagerConsolePageTabs();
  const userFullInfoReadAccess = useUserFullInfoReadAccess();
  const acl = useAcl();
  const usersnapTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const usersnapApi = useUsersnapApi();

  useEffect(() => {
    // We assume that all managers that can read salary of its subordinates are salary managers
    if (
      acl.hasSecurePermission(SecurePermission.SALARY_RECORD_SUBORDINATE_READ) &&
      !acl.hasSecurePermission(SecurePermission.SALARY_RECORD_READ)
    ) {
      usersnapApi?.logEvent(UserSnapEvents.MANAGER_CONSOLE_FOR_SALARY_MANAGER);
    } else {
      usersnapApi?.logEvent(UserSnapEvents.MANAGER_CONSOLE_FOR_NON_SALARY_MANAGER);
    }

    if (usersnapApi && usersnapTimeoutId.current === null) {
      usersnapTimeoutId.current = setTimeout(
        () => usersnapApi.logEvent(UserSnapEvents.MANAGER_CONSOLE_SUBORINATES_OPEN),
        12000,
      );
    }
  }, [acl, usersnapApi, usersnapTimeoutId]);

  useEffect(() => {
    return () => {
      if (usersnapTimeoutId.current) clearTimeout(usersnapTimeoutId.current);
    };
  }, [usersnapTimeoutId]);

  if (!userFullInfoReadAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.managerConsole)}
      headerContent={
        showTabs && <ManagerConsolePageTabs availableTabs={availableTabs} activeTab={ManagerConsoleTab.SUBORDINATES} />
      }
      notionGuide='https://www.notion.so/joomteam/Joom-Space-Manager-Console-97702695d9a54f02809ce7b320c1df70'
    >
      <UserFullInfoRecordList />
    </Page>
  );
}
