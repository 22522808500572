import {ReactComponent as LeftIcon} from '@joomcode/joom-ui/icons/core/arrowLeft.svg';
import {ReactComponent as RightIcon} from '@joomcode/joom-ui/icons/core/arrowRight.svg';
import {Direction} from '@joomcode/joom-ui/Timeline';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type PropsType = {
  disabled: boolean;
  onClick: () => void;
  direction: Direction.LEFT | Direction.RIGHT;
};

export function ScrollButton({direction, ...props}: PropsType) {
  const intl = useIntl();
  const message = direction === Direction.LEFT ? messages.scrollLeftCaption : messages.scrollRightCaption;

  return (
    <button {...props} title={intl.formatMessage(message)} type='button' className={styles.ScrollButton}>
      {direction === Direction.LEFT ? <LeftIcon /> : <RightIcon />}
    </button>
  );
}
