import {useCallback} from 'react';
import {useTimeline} from '../Timeline/Provider';
import {type DateInterval, type TimelineItemPosition} from '../types';
import {createInterval} from '../utils';

export function useGetItemPosition() {
  const {
    boundary: {start},
    msToPixelRatio,
  } = useTimeline();

  return useCallback(
    (init: DateInterval): TimelineItemPosition => {
      const interval = createInterval(init);

      interval.start -= start;
      interval.end -= start;

      interval.start *= msToPixelRatio;
      interval.end *= msToPixelRatio;

      return interval;
    },
    [start, msToPixelRatio],
  );
}
