import cn from 'classnames';
import React from 'react';
import {Severity} from '../types';
import styles from './styles.css';

type PropsType = {
  severity: Severity;
};

const styleBySeverity: Record<Severity, string | undefined> = {
  [Severity.HIGH]: styles.high,
  [Severity.LOW]: styles.low,
};

export function IssueStripe({severity}: PropsType) {
  return <div className={cn(styles.IssueStripe, styleBySeverity[severity])} />;
}
