import {intranetApi} from 'apiClient';
import {Duty, dutySchema} from 'domain/duty/model';
import {DutyDiff} from 'domain/duty/model/diff';
import {TeamId} from 'domain/team/model/id';

type CreateDutyParams = {
  teamId: TeamId;
};

export type CreateDutyConfig = {
  diff: DutyDiff;
  params: CreateDutyParams;
};

export const create = async ({diff, params}: CreateDutyConfig): Promise<Duty> => {
  const {data} = await intranetApi.post('/v1/teams/duties/create', diff, {params});

  return dutySchema.runWithException(data);
};
