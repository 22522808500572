import React, {type ReactNode} from 'react';
import {useTimeline} from '../Timeline/Provider';
import {TimelineUnit} from '../types';
import styles from './index.css';

type PropsType = {
  type: 'unit' | 'noon';
  children: ReactNode;
};

export function Guides({children, type}: PropsType) {
  const {unit} = useTimeline();

  if (type !== 'noon' || unit !== TimelineUnit.WEEK) {
    return <div className={styles[type]}>{children}</div>;
  }

  return children;
}
