import {identity} from '@joomcode/deprecated-utils/function';
import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {getWeekdayName} from 'utils/date/getWeekdayName';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

const items = [1, 2, 3, 4, 5, 6, 0];

export type Props = CommonFieldProps<number>;

export function FieldWeekday({
  name,
  label,
  hint,
  disabled,
  required,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const itemToString = useCallback(
    (weekday: number | undefined) => (weekday !== undefined ? getWeekdayName(weekday, intl) : ''),
    [intl],
  );
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;
  const {input, meta} = useField<number>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    parse: identity,
    format: identity,
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Select<number>
          items={items}
          getItemKey={itemToString}
          itemToString={itemToString}
          renderItem={itemToString}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
}
