import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {DisclosureState, useDisclosureState} from '../../Disclosure';

export const RowDisclosureContext = React.createContext<DisclosureState | undefined>(undefined);

type TableDisclosureState = DisclosureState & {
  isIndeterminated: boolean;
  indeterminate(): void;
};
export const TableDisclosureContext = React.createContext<TableDisclosureState | undefined>(undefined);

function useExtendedDisclosure(baseDisclosure: DisclosureState, fn: () => void): DisclosureState {
  return useMemo(
    () => ({
      ...baseDisclosure,
      toggle: () => {
        fn();
        baseDisclosure.toggle();
      },
      collapse: () => {
        baseDisclosure.collapse();
        fn();
      },
      expand: () => {
        baseDisclosure.expand();
        fn();
      },
    }),
    [baseDisclosure, fn],
  );
}

export function useTableDisclosure(): TableDisclosureState {
  const disclosure = useDisclosureState();

  const [isIndeterminated, setIsIndeterminated] = useState<boolean>(false);
  const indeterminate = useCallback(() => {
    setIsIndeterminated(true);
    disclosure.collapse();
  }, [setIsIndeterminated, disclosure.collapse]);
  const determinate = useCallback(() => setIsIndeterminated(false), [setIsIndeterminated]);
  const extendedDisclosure = useExtendedDisclosure(disclosure, determinate);

  return useMemo(
    () => ({
      ...extendedDisclosure,
      indeterminate,
      isIndeterminated,
    }),
    [extendedDisclosure, indeterminate, isIndeterminated],
  );
}

export function useRowDisclosure({
  onExpand,
  initiallyExpanded,
}: {
  onExpand?: () => void;
  initiallyExpanded?: boolean;
}): DisclosureState {
  const parent = useContext(TableDisclosureContext);
  if (!parent) {
    throw new Error('useRowDisclosure should be used inside TableDisclosure.Provider');
  }

  const disclosure = useDisclosureState({initiallyExpanded});

  useEffect(() => {
    if (initiallyExpanded) {
      parent.indeterminate();
    }
  }, [initiallyExpanded]);

  useUpdateEffect(() => {
    if (disclosure.isExpanded && !parent.isExpanded && !parent.isIndeterminated) {
      disclosure.collapse();
    } else if (parent.isExpanded && !disclosure.isExpanded) {
      disclosure.expand();
    }
  }, [parent.isExpanded, parent.isIndeterminated]);

  useUpdateEffect(() => {
    if (disclosure.isExpanded && onExpand) {
      onExpand();
    }
  }, [disclosure.isExpanded]);

  return useExtendedDisclosure(disclosure, parent.indeterminate);
}
