import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Links',
    description: '[title] Team links panel title',
  },
  slackChannel: {
    defaultMessage: 'Slack channel',
    description: 'Team links panel: slack channel default name',
  },
  notionPage: {
    defaultMessage: 'Team documentation',
    description: 'Team links panel: notion link',
  },
  accesses: {
    defaultMessage: 'JDM Accesses',
    description: 'Team links panel: accesses link',
  },
  calendarLink: {
    defaultMessage: 'Time off calendar',
    description: 'Team links panel: calendar link',
  },
  calendarWithSubteamsLink: {
    defaultMessage: 'Time off calendar (with subteams)',
    description: 'Team links panel: calendar link',
  },
  duties: {
    defaultMessage: 'Team duty schedule',
    description: 'Team links panel: duties link',
  },
  createDuty: {
    defaultMessage: 'Create duty',
    description: 'Team links panel: create duty link',
  },
});
