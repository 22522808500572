import cn from 'classnames';
import {Markdown} from 'components/ui/Markdown';
import {Section} from 'components/ui/Section';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {LegalEntity} from 'domain/legalEntity/model';
import {Permission} from 'domain/permission/model';
import {SocialMedia} from 'domain/socialMedia/model';
import {socialMediaNames} from 'domain/socialMedia/model/messages';
import {NearestTimeOffs} from 'domain/timeOff/userTimeOff/widgets/NearestTimeOffs';
import {UserFull} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {UserMembershipList} from 'domain/user/widgets/MembershipList';
import {UserProperties} from 'domain/user/widgets/Properties';
import {SalaryManagerIcon} from 'domain/user/widgets/SalaryManagerIcon';
import {generalMessages} from 'i18n/messages/general';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {AddressSection} from './AddressSection';
import {DownloadSubordinatesMarketData} from './DownloadSubordinatesMarketData';
import {EmergencyContactSection} from './EmergencyContactSection';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: UserFull;
  legalEntity?: LegalEntity;
  privateInfo?: UserPrivateInfo;
  isSelfUser: boolean;
};

const orderedSocialMediaList = [
  SocialMedia.FACEBOOK,
  SocialMedia.INSTAGRAM,
  SocialMedia.TWITTER,
  SocialMedia.LINKED_IN,
];

export function UserProfile({user, legalEntity, privateInfo, isSelfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const hasSocialLinks = orderedSocialMediaList.some((socialMedia) => user.socialMediaLinks?.[socialMedia]);

  return (
    <div className={styles.root}>
      <h1 className={styles.fullName}>
        {intl.formatMessage(generalMessages.fullName, {firstName: user.firstName, lastName: user.lastName})}
        <SalaryManagerIcon user={user} />
      </h1>
      <div className={styles.position}>
        {user.jobTitle && <div>{user.jobTitle}</div>}
        {(user.department || user.division) && (
          <div className={styles.department}>
            {user.department}
            {user.department && user.division && ', '}
            {user.division}
          </div>
        )}
      </div>
      <NearestTimeOffs timeOffs={user.offTime} longPhrase />
      <div className={cn(styles.properties, styles.columned)}>
        <UserProperties user={user} legalEntity={legalEntity} privateInfo={privateInfo} isSelfUser={isSelfUser} />
      </div>
      {privateInfo?.address && Object.keys(privateInfo.address).length > 0 && (
        <AddressSection address={privateInfo.address} title={intl.formatMessage(messages.address)} />
      )}
      {privateInfo?.emergencyContact && Object.keys(privateInfo.emergencyContact).length > 0 && (
        <EmergencyContactSection
          emergencyContact={privateInfo.emergencyContact}
          title={intl.formatMessage(messages.emergencyContact)}
        />
      )}
      {acl.hasPermission(Permission.TEAM_READ) && user.memberships && user.memberships.length > 0 && (
        <Section title={intl.formatMessage(messages.teams)} bordered>
          <UserMembershipList memberships={user.memberships} />
        </Section>
      )}
      {(user.about || hasSocialLinks) && (
        <Section title={intl.formatMessage(messages.about)} bordered>
          {user.about && <Markdown source={user.about} />}
          {hasSocialLinks && (
            <div className={styles.socialLinks}>
              {orderedSocialMediaList.map((socialMedia) =>
                user.socialMediaLinks?.[socialMedia] ? (
                  <StyledExternalLink href={user.socialMediaLinks[socialMedia]} colored key={socialMedia}>
                    {intl.formatMessage(socialMediaNames[socialMedia])}
                  </StyledExternalLink>
                ) : null,
              )}
            </div>
          )}
        </Section>
      )}
      {user.manager && (
        <Section title={intl.formatMessage(messages.manager)} bordered>
          <ListedUser user={user.manager} withSalaryManagerIcon />
        </Section>
      )}
      {user.subordinates && user.subordinates.length > 0 && (
        <Section title={intl.formatMessage(messages.subordinates, {count: user.subordinates.length})} bordered>
          <div className={styles.subordinates}>
            {user.subordinates.map((subordinate) => (
              <ListedUser user={subordinate} key={subordinate.id} fullWidth withSalaryManagerIcon />
            ))}
          </div>
          {isSelfUser && <DownloadSubordinatesMarketData />}
        </Section>
      )}
    </div>
  );
}
