import React, {ForwardedRef, forwardRef, memo} from 'react';
import {VirtualizedList} from './Virtualized';
import {SimpleList} from './Simple';
import {ListProps} from './types';

export const List = memo(
  forwardRef(function List<Item>({virtualization, ...props}: ListProps<Item>, ref: ForwardedRef<HTMLDivElement>) {
    if (virtualization) {
      return <VirtualizedList ref={ref} {...props} virtualization={virtualization} />;
    }

    return <SimpleList ref={ref} {...props} />;
  }),
) as <Item>(props: ListProps<Item> & {ref: ForwardedRef<HTMLDivElement>}) => React.ReactNode;
