import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  show: {
    defaultMessage: 'Show confidential data',
    description: '[button] Sensitive data switch',
  },
  hide: {
    defaultMessage: 'Hide confidential data',
    description: '[button] Sensitive data switch',
  },
});
