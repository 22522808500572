export enum Direction {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export function isVerticalDirection(dir: Direction) {
  return dir === Direction.TOP || dir === Direction.BOTTOM;
}

export function isHorizontalDirection(dir: Direction) {
  return !isVerticalDirection(dir);
}
