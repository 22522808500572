import cn from 'classnames';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import React, {useCallback, useMemo} from 'react';
import type {Sorting} from '@joomcode/deprecated-utils/sort/types';
import {createLocator, type Mark} from '../../../create-locator';
import type {Column, CellAlign, StickyColumnOffset, SortableCellOptions, CellLocator, CellStyle} from '../../types';
import {Cell} from '../Cell';
import {Resizer} from '../Resizer';
import {SortingButton} from '../SortingButton';
import styles from './index.css';
import {getSortableCellOptions} from './utils';
import {HeaderTitle} from '../HeaderTitle';

type CellProps = {
  align?: CellAlign;
  column: Column<unknown>;
  dataState: DataState;
  invisibleResizer?: boolean;
  last: boolean;

  /**
   * Appearance of the cell.
   */
  cellStyle?: CellStyle;

  sorting?: Sorting;
  onSort?: (sorting?: Sorting) => void;
  stickyOffset?: StickyColumnOffset;
  width: number;
  onResize?: (column: Column<unknown>, diff: number) => void;
} & Partial<Mark<CellLocator>>;

const getHeader = ({
  sortingOptions,
  interactiveHeading,
  dataState,
  name,
}: {
  interactiveHeading?: boolean;
  sortingOptions?: SortableCellOptions;
  name: React.ReactNode;
  dataState: DataState;
}) => {
  if (dataState === DataState.FAILED || !sortingOptions?.onSort) {
    return <span>{name}</span>;
  }
  if (interactiveHeading) {
    return (
      <div className={styles.noWrapSortableContainer}>
        {name}
        <SortingButton {...sortingOptions} disabled={dataState === DataState.LOADING} />
      </div>
    );
  }

  return (
    <SortingButton {...sortingOptions} disabled={dataState === DataState.LOADING}>
      {name}
    </SortingButton>
  );
};

export function HeadingCell({
  align,
  column,
  dataState,
  invisibleResizer,
  last,
  sorting,
  stickyOffset,
  cellStyle,
  width,
  onResize,
  onSort,
  ...restProperties
}: CellProps) {
  const locator = createLocator(restProperties);
  const sortingOptions = useMemo(() => getSortableCellOptions({column, sorting, onSort}), [column, sorting, onSort]);

  const contentRenderer = useCallback(
    () =>
      getHeader({
        name: <HeaderTitle name={column.name} description={column.description} />,
        dataState,
        sortingOptions,
        interactiveHeading: column.interactiveHeading,
      }),
    [column.align, column.name, column.description, column.interactiveHeading, dataState, sortingOptions],
  );

  const separator = useMemo(() => {
    const isResizable = Boolean(column.resizable !== false && onResize);
    return (
      <div
        className={cn(styles.separator, {
          [styles.separatorDisabled]: !isResizable,
          [styles.separatorInvisible]: invisibleResizer,
          [styles.last]: last,
        })}
      >
        {isResizable && <Resizer onMove={(diff: number) => onResize?.(column, diff)} />}
      </div>
    );
  }, [onResize, invisibleResizer, last, column]);

  return (
    <Cell
      addon={separator}
      align={align}
      item={null}
      render={contentRenderer}
      stickyOffset={stickyOffset}
      width={width}
      cellStyle={cellStyle}
      heading
      noWrap
      {...locator({item: column.id})}
    />
  );
}
