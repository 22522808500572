import {isSameDay} from 'date-fns';
import {IntlShape} from 'react-intl';

export const formatDateTime = (date: string, intl: IntlShape, utc: boolean = true) => {
  return intl.formatDate(date, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: utc ? 'utc' : undefined,
  });
};

export const formatDate = (date: string, intl: IntlShape, utc: boolean = true) => {
  return intl.formatDate(date, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: utc ? 'utc' : undefined,
  });
};

export const formatTime = (date: string, intl: IntlShape, utc: boolean = true) => {
  return intl.formatTime(date, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: utc ? 'utc' : undefined,
  });
};

export const isSameDayUTC = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const offset = startDate.getTimezoneOffset();

  startDate.setMinutes(startDate.getMinutes() + offset);
  endDate.setMinutes(endDate.getMinutes() + offset);

  return isSameDay(startDate, endDate);
};
