import {getLocalHandoffTime} from 'domain/duty/utils/getLocalHandoffTime';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  offset: number;
  time: string;
  weekday: number;
};

export function LocalHandoffTime({offset, time, weekday}: Props) {
  const intl = useIntl();
  const localTime = useMemo(() => getLocalHandoffTime({offset, time, weekday, intl}), [offset, time, weekday, intl]);

  return <div className={styles.root}>{intl.formatMessage(messages.localHandoffTime, {time: localTime})}</div>;
}
