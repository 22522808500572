import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {DutiesPage} from 'pages/Duties';
import {DutyCreatePage} from 'pages/DutyCreate';
import {DutyOverrideCreatePage} from 'pages/DutyOverrideCreate';
import {TeamPage} from 'pages/Team';
import {TeamRoleBindingsPage} from 'pages/TeamRoleBindings';
import {TeamsPage} from 'pages/Teams';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {teamsUrlPatterns} from 'routes/teams/urls';

export const config: RouteConfig = {
  path: teamsUrlPatterns.teams,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(TeamPage, Permission.TEAM_READ),
      exact: true,
      path: teamsUrlPatterns.team,
    },
    {
      component: withPermission(TeamsPage, Permission.TEAM_READ),
      exact: true,
      path: teamsUrlPatterns.teams,
    },
    {
      component: withPermission(TeamRoleBindingsPage, Permission.TEAM_ROLE_BINDING_READ),
      exact: true,
      path: teamsUrlPatterns.roleBindings,
    },
    {
      component: DutiesPage,
      exact: true,
      path: teamsUrlPatterns.duties,
    },
    {
      component: DutyCreatePage,
      exact: true,
      path: teamsUrlPatterns.dutyCreate,
    },
    {
      component: DutyOverrideCreatePage,
      exact: true,
      path: teamsUrlPatterns.dutyOverrideCreate,
    },
    notFoundRouteConfig,
  ],
};
