import {KnownServerSecurePermission} from 'domain/permission/model/secure';
import {defineMessages} from 'react-intl';

export const permissionDescriptions = defineMessages<KnownServerSecurePermission>({
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_ANY]: {
    defaultMessage: 'Create compensation review request for any jooomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_SUBORDINATE]: {
    defaultMessage: 'Create compensation review request for subordinate.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY]: {
    defaultMessage: 'Download all compensation review requests.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY]: {
    defaultMessage: 'See compensation review requests of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE]: {
    defaultMessage: 'See compensation review requests created by all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED]: {
    defaultMessage: 'Set compensation as recorded in compensation review requests.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED]: {
    defaultMessage: 'Set options as recorded in compensation review requests.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE]: {
    defaultMessage: 'Approve and reject compensation review requests created by all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF]: {
    defaultMessage: 'Approve and reject compensation review requests of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.FINANCIAL_RECORD_BULK_UPLOAD]: {
    defaultMessage: 'Upload financial data.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.MARKET_DATA_RECORD_DOWNLOAD]: {
    defaultMessage: 'Download all functions and levels data.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.MARKET_DATA_RECORD_READ_ANY]: {
    defaultMessage: 'See functions and levels table of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.MARKET_DATA_RECORD_WRITE]: {
    defaultMessage: 'Create, update and delete function and level records of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_COMPENSATION_READ_ANY]: {
    defaultMessage: 'See compensation information of any job offer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_COMPENSATION_COMMENT_READ_ANY]: {
    defaultMessage: 'See comments for HR Admin of any job offer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_COMPENSATION_READ_MY]: {
    defaultMessage: 'See compensation information of the own job offers (created by the joomer themselves).',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_CREATE]: {
    defaultMessage: 'Create job offers for potential employees.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_DOWNLOAD]: {
    defaultMessage: 'Download all job offers.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_STOCK_OPTION_EXISTENCE_READ]: {
    defaultMessage: 'See the existence of an option agreement of any job offer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_STOCK_OPTION_READ_ANY]: {
    defaultMessage: 'See options information of any job offer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_STOCK_OPTION_READ_MY]: {
    defaultMessage: 'See options information of the own job offers (created by the joomer themselves).',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_UPDATE_ANY]: {
    defaultMessage: 'Update details of any job offer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.OFFER_UPDATE_MY]: {
    defaultMessage: 'Update details of the own job offers (created by the joomer themselves).',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_READ_ANY]: {
    defaultMessage: 'See one-time bonus table of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_READ_SUBORDINATE]: {
    defaultMessage: 'See one-time bonus table of all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_WRITE]: {
    defaultMessage: 'Create, update and delete one-time bonus records of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.RECOMMENDED_JOB_TITLE_READ]: {
    defaultMessage: 'See recommended job titles.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.REGULAR_BONUS_RECORD_READ_ANY]: {
    defaultMessage: 'See regular bonus table of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.REGULAR_BONUS_RECORD_READ_SUBORDINATE]: {
    defaultMessage: 'See regular bonus table of all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.REGULAR_BONUS_RECORD_WRITE]: {
    defaultMessage: 'Create, update and delete regular bonus records of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_CALCULATE_CR]: {
    defaultMessage: 'Calculate salary CR.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_CALCULATE_SUBORDINATE_CR]: {
    defaultMessage: 'Calculate salary CR of a subordinate.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_READ_AS_ADMIN]: {
    defaultMessage: 'See and download all the salary ranges.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_READ_AS_MANAGER]: {
    defaultMessage: 'See salary ranges that are available for the joomer’s role.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_UPLOAD]: {
    defaultMessage: 'Upload salary ranges.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]: {
    defaultMessage: 'See and download all the salary range versions.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RANGE_VERSION_WRITE]: {
    defaultMessage: 'Delete salary range versions.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_BULK_IMPORT]: {
    defaultMessage:
      'Bulk import. With this permission joomer can create bulk operation with salaries and apply changes from bulk.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_WRITE]: {
    defaultMessage:
      'Create, update and delete salary records for any joomer without secret information such as “Special arrangements” field.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_WRITE_EXTENDED]: {
    defaultMessage: 'Create, update and delete salary records for any joomer with all possible fields.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD]: {
    defaultMessage:
      'Download all salary records of all joomers without secret information such as “Special arrangements” field as an XLS file.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED]: {
    defaultMessage: 'Download all salary records of all joomers with all possible fields as an XLS file.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_READ]: {
    defaultMessage: 'See salary table of every joomer without secret information such as “Special arrangements” field.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_READ_EXTENDED]: {
    defaultMessage: 'See salary table of every joomer with all possible fields.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SALARY_RECORD_SUBORDINATE_READ]: {
    defaultMessage: 'See salary table of all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_PERMISSION_READ]: {
    defaultMessage: 'See all permissions.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_ROLE_BADGE_READ]: {
    defaultMessage: 'See that the joomer has some secure role, e.g., Salary Manager.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_ROLE_READ]: {
    defaultMessage: 'See all financial roles.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_ROLE_GRANT]: {
    defaultMessage: 'Add any financial role to any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_ROLE_REVOKE]: {
    defaultMessage: 'Remove any financial role from any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.SECURE_ROLE_UPDATE]: {
    defaultMessage: 'Change financial role e.g. change its name or its set of permissions.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_BULK_UPLOAD]: {
    defaultMessage: 'Upload options data.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_DOWNLOAD_ANY]: {
    defaultMessage: 'Download options data of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_READ_ANY]: {
    defaultMessage: 'See options data of every joomer with all possible fields.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_READ_SUBORDINATE]: {
    defaultMessage: 'See options data of all own direct and indirect reports.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER]: {
    defaultMessage: 'Send any joomer’s option agreement to their email.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.STOCK_OPTION_WRITE_ANY]: {
    defaultMessage: 'Create, update and delete options data of any joomer.',
    description: 'Checkbox hint',
  },
  [KnownServerSecurePermission.USER_FULL_INFO_RECORD_READ]: {
    defaultMessage: 'See full available information of any joomer in HR console.',
    description: 'Checkbox hint',
  },
});
