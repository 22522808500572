import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {createStore, Store} from 'effector';
import {getSubtreeIds} from './getSubtreeIds';
import {TreeViewEvents, TreeViewState} from './types';
import {calculateExpanded} from './utils';

export function createTreeViewStore<ItemId extends string>(
  initialState: TreeViewState<ItemId>,
  events: TreeViewEvents<ItemId>,
): Store<TreeViewState<ItemId>> {
  const store = createStore<TreeViewState<ItemId>>(initialState);
  store
    .on(events.toggleItem, (state, {id}) => {
      let itemsById: TreeViewState<ItemId>['itemsById'];

      if (!state.itemsById[id].expanded) {
        // expand item
        itemsById = {
          ...state.itemsById,
          [id]: {
            ...state.itemsById[id],
            expanded: true,
          },
        };
      } else {
        // collapse item + collapse all its descendants
        const subtreeIds = getSubtreeIds(id, state);
        itemsById = {
          ...state.itemsById,
          ...arrayToObject(subtreeIds, identity, (subtreeId) => ({
            ...state.itemsById[subtreeId],
            expanded: false,
          })),
        };
      }

      return {
        ...state,
        itemsById,
        expanded: calculateExpanded(itemsById),
      };
    })
    .on(events.resetAllItems, (state) => {
      const keys = Object.keys(state.itemsById) as ItemId[];

      return {
        ...state,
        itemsById: arrayToObject(keys, identity, (id) => ({
          ...state.itemsById[id],
          expanded: state.itemsById[id].initialExpanded,
        })),
        expanded: false,
      };
    })
    .on(events.collapseAllItems, (state) => {
      const keys = Object.keys(state.itemsById) as ItemId[];

      return {
        ...state,
        itemsById: arrayToObject(keys, identity, (id) => ({
          ...state.itemsById[id],
          expanded: false,
        })),
        expanded: false,
      };
    })
    .on(events.expandAllItems, (state) => {
      const keys = Object.keys(state.itemsById) as ItemId[];

      return {
        ...state,
        itemsById: arrayToObject(keys, identity, (id) => ({
          ...state.itemsById[id],
          expanded: true,
        })),
        expanded: true,
      };
    });
  return store;
}
