import {DataState} from '@joomcode/deprecated-utils/dataState';
import {getFieldValidator, useField, ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User, UserId} from 'domain/user/model';
import {UserMultiSelect} from 'domain/user/widgets/MultiSelect';
import React, {useCallback, useEffect, useMemo} from 'react';

export type FieldUsersValue = UserId[];

type Props = {
  disabled?: boolean;
  id?: string;
  initialValue?: FieldUsersValue;
  name: string;
  onLoadingError?: (error: Error) => void;
  placeholder?: string;
  suggestTerminatedUsers?: boolean;
  validate?: ValidationFunction<FieldUsersValue, ValidationOptions, unknown>;
  users?: User[];
};

const emptyUsers: FieldUsersValue = [];

export function FieldUsers({
  disabled,
  id,
  initialValue = emptyUsers,
  name,
  onLoadingError,
  placeholder,
  suggestTerminatedUsers = false,
  validate,
  users: externalUsers,
}: Props) {
  const {
    input: {onChange, value, ...input},
  } = useField<FieldUsersValue>(name, {
    initialValue,
    validate: validate && getFieldValidator(validate),
  });
  const {dataState, userByLogin, loginById} = useAllUsers({
    withTerminated: suggestTerminatedUsers,
    onError: onLoadingError,
  });
  const users = useMemo(() => externalUsers ?? Object.values(userByLogin), [externalUsers, userByLogin]);

  const selectValue: User[] = useMemo(() => {
    if (dataState !== DataState.LOADED || !value) {
      return [];
    }

    return value?.map((userId) => userByLogin[loginById[userId]]).filter((u) => u) ?? [];
  }, [dataState, userByLogin, loginById, value]);

  const handleChange = useCallback(
    (items: User[]) => {
      onChange(items.map((user) => user.id));
    },
    [onChange],
  );

  useEffect(() => {
    onChange(dataState === DataState.LOADED ? initialValue : emptyUsers);
  }, [dataState]);

  return (
    <UserMultiSelect
      disabled={disabled || dataState !== DataState.LOADED}
      id={id}
      items={users}
      onChange={handleChange}
      value={selectValue}
      placeholder={placeholder}
      {...input}
    />
  );
}
