import {intranetApi} from 'apiClient';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';

export type DeactiveDutyConfig = {
  id: DutyId;
  teamId: TeamId;
};

export const deactivate = async ({id}: DeactiveDutyConfig): Promise<void> => {
  return intranetApi.post('v1/teams/duties/deactivate', undefined, {params: {id}});
};
