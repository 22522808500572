import React, {useMemo} from 'react';
import {TreeContext} from './context';
import {TreeItem} from './TreeItem';

type Props = {
  noSeparators?: boolean;
  children?: React.ReactNode;
};

export function Tree({children, noSeparators}: Props) {
  const contextValue = useMemo(() => ({noSeparators}), [noSeparators]);
  return <TreeContext.Provider value={contextValue}>{children}</TreeContext.Provider>;
}

Tree.Item = TreeItem;
