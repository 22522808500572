import {intranetApi} from 'apiClient';
import {Duty, dutySchema} from 'domain/duty/model';
import {DutyId} from 'domain/duty/model/lite';
import {SlotDiff} from 'domain/duty/model/slot/diff';
import {TeamId} from 'domain/team/model/id';

type CreateOverrideParams = {
  dutyId: DutyId;
  teamId: TeamId;
};

type CreateOverrideDiff = {
  endDate: string;
  slot: SlotDiff;
  startDate: string;
};

export type CreateOverrideConfig = {
  diff: CreateOverrideDiff;
  params: CreateOverrideParams;
};

export const createOverride = async ({diff, params}: CreateOverrideConfig): Promise<Duty> => {
  const {data} = await intranetApi.post('/v1/teams/duties/overrides/create', diff, {params});

  return dutySchema.runWithException(data);
};
