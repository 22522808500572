import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Page} from 'components/widgets/Page';
import {useCompensationReviewRequestCreateAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestCreateAccess';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {TabId} from 'domain/compensationReviewRequest/model/tab';
import {CompensationReviewRequestDownloadButton} from 'domain/compensationReviewRequest/widgets/DownloadButton';
import {CompensationReviewRequestsList} from 'domain/compensationReviewRequest/widgets/List';
import {useHrConsolePageTabs} from 'domain/hrConsole/hooks/useHrConsolePageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {HrConsolePageTabs} from 'domain/hrConsole/widgets/PageTabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {NotFoundPage} from 'pages/NotFound';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';
import {hrConsoleUrls} from 'routes/hrConsole/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function HrConsoleCompensationReviewRequestsPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {showTabs, availableTabs} = useHrConsolePageTabs();
  const readAccess = useCompensationReviewRequestReadAccess();
  const createAccess = useCompensationReviewRequestCreateAccess();
  const [activeTabId, setActiveTabId] = useState<TabId>();

  if (!readAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.hrConsole)}
      actions={
        <ButtonGroup spaced size='m'>
          {acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY) &&
            activeTabId === TabId.ANY && <CompensationReviewRequestDownloadButton />}
          {createAccess && (
            <RouterLinkButton
              to={hrConsoleUrls.compensationReviewRequestCreate()}
              size='m'
              kind='primary'
              intent='primary'
              type='button'
            >
              {intl.formatMessage(messages.createButton)}
            </RouterLinkButton>
          )}
        </ButtonGroup>
      }
      headerContent={
        showTabs && (
          <HrConsolePageTabs availableTabs={availableTabs} activeTab={HrConsoleTab.COMPENSATION_REVIEW_REQUESTS} />
        )
      }
    >
      <CompensationReviewRequestsList
        defaultTabId={TabId.ANY}
        onlySubordinates={!acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY)}
        onTabChange={setActiveTabId}
      />
    </Page>
  );
}
