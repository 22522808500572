import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  button: {
    defaultMessage: 'Delete override',
    description: '[button] Override deletion button',
  },
  confirmationTitle: {
    defaultMessage: 'Delete override',
    description: '[title] Override deletion confirmation dialog: dialog title',
  },
  confirmationText: {
    defaultMessage: 'Are you sure you want to delete this override?',
    description: 'Override deletion confirmation dialog: dialog text',
  },
  confirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] Override deletion confirmation dialog: confirmation button',
  },
  success: {
    defaultMessage: 'The override has been deleted',
    description: 'Override successful deletion text',
  },
});
