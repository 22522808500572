import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';

export const slotIdSchema = idSchema;
export const slotSchema = object({
  id: optional(slotIdSchema),
  users: array(userSchema),
});

export type SlotId = ExtractSchemaType<typeof slotIdSchema>;
export type Slot = ExtractSchemaType<typeof slotSchema>;
