import React, {ForwardedRef, forwardRef} from 'react';
import {ListProps} from './types';
import {ScrollWrapper} from './ScrollWrapper';
import {InnerTable} from './InnerTable';

export const SimpleList = forwardRef(function SimpleList<Item>(
  {items, summaryPosition, renderRow, getRowKey, summaryRow}: ListProps<Item>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <ScrollWrapper ref={ref}>
      <InnerTable>
        {summaryPosition === 'top' && summaryRow}
        {items.map((item) => (
          <React.Fragment key={getRowKey(item)}>{renderRow(item)}</React.Fragment>
        ))}
        {summaryPosition === 'bottom' && summaryRow}
      </InnerTable>
    </ScrollWrapper>
  );
});
