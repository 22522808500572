import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSalaryRangesReadAccess} from 'domain/salaryRange/hooks/useSalaryRangesReadAccess';
import {useUserFullInfoReadAccess} from 'domain/userFullInfoRecord/hooks/useReadAccess';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';

type ReturnShape = {
  availableTabs: HrConsoleTab[];
  showTabs: boolean;
};

export function useHrConsolePageTabs(): ReturnShape {
  const acl = useAcl();
  const userFullInfoReadAccess = useUserFullInfoReadAccess();
  const salaryRangeReadAccess = useSalaryRangesReadAccess();
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();
  const canManageSalaryRanges = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_MANAGE);

  const tabAvailability: Record<HrConsoleTab, boolean> = useMemo(
    () => ({
      [HrConsoleTab.USERS]: Boolean(userFullInfoReadAccess),
      [HrConsoleTab.CURRENT_SALARY_RANGES]: Boolean(salaryRangeReadAccess),
      [HrConsoleTab.MANAGEMENT]: canManageSalaryRanges,
      [HrConsoleTab.COMPENSATION_REVIEW_REQUESTS]: Boolean(compensationReviewRequestReadAccess),
    }),
    [userFullInfoReadAccess, salaryRangeReadAccess, compensationReviewRequestReadAccess, canManageSalaryRanges],
  );

  const availableTabs = useMemo(
    () => getEnumValues(HrConsoleTab).filter((tab) => tabAvailability[tab]),
    [tabAvailability],
  );

  return {
    availableTabs,
    showTabs: availableTabs.length > 1,
  };
}
