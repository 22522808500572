import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useRegularBonusReadAccess} from 'domain/regularBonusRecord/hooks/useRegularBonusReadAccess';
import {useUserRegularBonusRecords} from 'domain/regularBonusRecord/hooks/useUserRegularBonusRecords';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusReadAccess} from 'domain/regularBonusRecord/model/access';
import {RegularBonusRecordDialog} from 'domain/regularBonusRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {RegularBonus} from 'domain/user/widgets/RegularBonus';
import {RegularBonusTabAddon} from 'domain/user/widgets/RegularBonusTabAddon';
import React, {useMemo, useState} from 'react';
import {filterOutFutureRecords} from 'utils/record';

export function useRegularBonusTab(user: UserFull, isSensitiveDataHidden: boolean): JobPanelTab<RegularBonusRecord> {
  const regularBonusRecords = useUserRegularBonusRecords(user);
  const regularBonusRecordDialog = usePopupState<RegularBonusRecord>();
  const readAccess = useRegularBonusReadAccess(user);
  const [needToReplaceRegularBonus, setNeedToReplaceRegularBonus] = useState(false);
  const records = useMemo(
    () =>
      readAccess === RegularBonusReadAccess.MY
        ? filterOutFutureRecords(regularBonusRecords.data)
        : regularBonusRecords.data,
    [readAccess, regularBonusRecords.data],
  );

  const result = useMemo(
    () => ({
      addon: <RegularBonusTabAddon onDialogOpen={regularBonusRecordDialog.open} />,
      content: (
        <RegularBonus
          userId={user.id}
          records={records}
          dataState={regularBonusRecords.dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={regularBonusRecordDialog.openWithPayload}
          setNeedToReplace={setNeedToReplaceRegularBonus}
        />
      ),
      dialog: (
        <RegularBonusRecordDialog
          userId={user.id}
          recordToUpdate={regularBonusRecordDialog.payload}
          needToReplace={needToReplaceRegularBonus}
          isOpen={regularBonusRecordDialog.isOpen}
          onClose={regularBonusRecordDialog.close}
        />
      ),
      records,
      dataState: regularBonusRecords.dataState,
    }),
    [
      user.id,
      records,
      regularBonusRecords.dataState,
      isSensitiveDataHidden,
      regularBonusRecordDialog,
      needToReplaceRegularBonus,
      setNeedToReplaceRegularBonus,
    ],
  );

  return result;
}
