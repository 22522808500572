import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useOfficePolicyReadAccess} from 'domain/officePolicyRecord/hooks/useOfficePolicyReadAccess';
import {useUserOfficePolicyRecords} from 'domain/officePolicyRecord/hooks/useOfficePolicyRecords';
import {OfficePolicyRecord} from 'domain/officePolicyRecord/model';
import {OfficePolicyReadAccess} from 'domain/officePolicyRecord/model/access';
import {OfficePolicyRecordDialog} from 'domain/officePolicyRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {OfficePolicy} from 'domain/user/widgets/OfficePolicy';
import {OfficePolicyTabAddon} from 'domain/user/widgets/OfficePolicyTabAddon';
import React, {useMemo} from 'react';
import {filterOutFutureRecords} from 'utils/record';

export function useOfficePolicyTab(user: UserFull): JobPanelTab<OfficePolicyRecord> {
  const officePolicyRecords = useUserOfficePolicyRecords(user);
  const officePolicyRecordDialog = usePopupState<OfficePolicyRecord>();
  const readAccess = useOfficePolicyReadAccess(user);
  const records = useMemo(
    () =>
      readAccess === OfficePolicyReadAccess.MY
        ? filterOutFutureRecords(officePolicyRecords.data)
        : officePolicyRecords.data,
    [readAccess, officePolicyRecords.data],
  );

  const result = useMemo(
    () => ({
      addon: <OfficePolicyTabAddon onDialogOpen={officePolicyRecordDialog.open} />,
      content: (
        <OfficePolicy
          userId={user.id}
          records={records}
          dataState={officePolicyRecords.dataState}
          onDialogOpenWithPayload={officePolicyRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <OfficePolicyRecordDialog
          userId={user.id}
          recordToUpdate={officePolicyRecordDialog.payload}
          isOpen={officePolicyRecordDialog.isOpen}
          onClose={officePolicyRecordDialog.close}
        />
      ),
      records,
      dataState: officePolicyRecords.dataState,
    }),
    [user, records, officePolicyRecords.dataState, officePolicyRecordDialog],
  );

  return result;
}
