import {SecurePermission} from 'domain/permission/model/secure';
import {SalaryRangesReadAccess} from 'domain/salaryRange/model/access';
import {useAcl} from 'services/acl';

export function useSalaryRangesReadAccess(): SalaryRangesReadAccess | null {
  const acl = useAcl();

  if (acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_ADMIN)) {
    return SalaryRangesReadAccess.ANY;
  }

  if (acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_MANAGER)) {
    return SalaryRangesReadAccess.SUBORDINATE;
  }

  return null;
}
