import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Role, RoleId} from 'domain/role/model';
import {getAllRolesFx} from 'domain/role/stores/main';
import {$roles} from 'domain/role/stores/main/state';
import {RoleAutocomplete} from 'domain/role/widgets/Autocomplete';
import {useStore} from 'effector-react';
import {FieldValidator} from 'final-form';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useField} from 'react-final-form';
import {toaster} from 'services/toaster';

export type FieldRoleValue = RoleId | null;

type Props = {
  clearable?: boolean;
  disabled?: boolean;
  id?: string;
  initialValue?: FieldRoleValue;
  name: string;
  onLoadingFail?: (error: Error) => void;
  validate?: FieldValidator<string>;
  allowHeadRoles?: boolean;
};

export function FieldRole({
  disabled,
  initialValue = null,
  name,
  onLoadingFail,
  validate,
  allowHeadRoles = true,
  ...restProps
}: Props) {
  const {dataState, byId} = useStore($roles);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getAllRolesFx().catch((error: Error) => {
        onLoadingFail?.(error);
        toaster.interceptThenThrowError(error);
      });
    }
  }, []);

  const roles = useMemo(() => {
    const result = Object.values(byId);
    return allowHeadRoles ? result : result.filter((role) => !role.isHead);
  }, [byId, allowHeadRoles]);

  const {
    input: {onChange, value, ...input},
  } = useField<FieldRoleValue>(name, {
    allowNull: true,
    initialValue,
    validate,
  });

  useEffect(() => {
    onChange(dataState === DataState.LOADED ? initialValue : null);
  }, [dataState]);

  const handleChange = useCallback(
    (role: Role | null) => {
      onChange(role?.id ?? null);
    },
    [onChange],
  );

  return (
    <RoleAutocomplete
      disabled={dataState !== DataState.LOADED || disabled}
      items={roles}
      onChange={handleChange}
      value={value ? (byId[value] ?? null) : null}
      {...restProps}
      {...input}
    />
  );
}
