import {pick} from '@joomcode/deprecated-utils/object/pick';
import {User, UserFull} from '.';

export function isUserFull(user: User | UserFull): user is UserFull {
  return 'isFull' in user;
}

export function userFullToUser(userFull: UserFull): User {
  return pick(userFull, [
    'avatarBundle',
    'countryOfTimeOffPolicy',
    'department',
    'division',
    'email',
    'firstName',
    'fullName',
    'githubLogin',
    'hidden',
    'hireDate',
    'id',
    'isOutstaff',
    'isSalaryManager',
    'isTerminated',
    'jobTitle',
    'lastName',
    'location',
    'login',
    'managerId',
    'monthAndDayOfBirth',
    'officePolicy',
    'offTime',
    'onDuty',
    'phone',
    'roleIds',
    'seat',
    'slackDisplayName',
    'slackLink',
    'verifiedGithubLogin',
    'workingRemotely',
  ]);
}
