import cn from 'classnames';
import {Avatar} from 'components/ui/Avatar';
import {User} from 'domain/user/model';
import React, {useMemo} from 'react';
import styles from './styles.css';

type PropsType = {
  user: User;
  plus?: number;
  short?: boolean;
  ghost?: boolean;
};

function useName(user: User, short?: boolean) {
  return useMemo(
    () => (short ? user.fullName.split(/\s+/).map((chunk) => chunk.slice(0, 1).toUpperCase()) : user.fullName),
    [user.fullName, short],
  );
}

export function Duty({user, plus, short, ghost}: PropsType) {
  const name = useName(user, short);

  return (
    <div className={cn(styles.Duty, {[styles.ghost]: ghost})}>
      <div className={cn(styles.avatar, {[styles.withPlus]: Boolean(plus)})}>
        <Avatar name={user.fullName} imageBundle={user.avatarBundle} size='s' />
        {plus ? <div className={styles.badge}>+{plus}</div> : null}
      </div>
      <div className={styles.text}>
        {name}
        {plus && !short ? <> +{plus}</> : null}
      </div>
    </div>
  );
}
