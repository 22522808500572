import {IntlShape} from 'react-intl';
import {getWeekdayName} from 'utils/date/getWeekdayName';

type Options = {
  offset: number;
  time: string;
  weekday: number;
  intl: IntlShape;
};

export function getLocalHandoffTime({offset, time, weekday, intl}: Options): string {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date();
  const currentWeekday = date.getUTCDay();
  const dayDifference = weekday - currentWeekday;

  date.setUTCDate(date.getUTCDate() + dayDifference);
  date.setUTCHours(hours, minutes, 0, 0);
  date.setMinutes(date.getMinutes() - offset);

  const formatter = new Intl.DateTimeFormat(intl.locale, {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  });

  const formattedDate = formatter.format(date);
  const [newWeekday, newTime] = formattedDate.split(' ');

  return newWeekday !== getWeekdayName(weekday, intl) ? `${newWeekday}, ${newTime}` : newTime;
}
