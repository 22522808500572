import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, boolean, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';

export const securePermissionIdSchema = idSchema;

export const serverSecurePermissionSchema = object({
  groups: array(string()),
  id: securePermissionIdSchema,
  name: string(),
  nonGrantable: optional(boolean()),
});

export type SecurePermissionId = ExtractSchemaType<typeof securePermissionIdSchema>;
export type ServerSecurePermission = ExtractSchemaType<typeof serverSecurePermissionSchema>;

export enum KnownServerSecurePermission {
  COMPENSATION_REVIEW_REQUEST_CREATE_ANY = 'CompensationReviewRequestCreateAny',
  COMPENSATION_REVIEW_REQUEST_CREATE_SUBORDINATE = 'CompensationReviewRequestCreateSubordinate',
  COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY = 'CompensationReviewRequestDownloadAny',
  COMPENSATION_REVIEW_REQUEST_READ_ANY = 'CompensationReviewRequestReadAny',
  COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE = 'CompensationReviewRequestReadSubordinate',
  COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED = 'CompensationReviewRequestSetCompensationsRecorded',
  COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED = 'CompensationReviewRequestSetStockOptionsRecorded',
  COMPENSATION_REVIEW_REQUEST_WRITE = 'CompensationReviewRequestWrite',
  COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF = 'CompensationReviewRequestWriteOnBehalf',

  FINANCIAL_RECORD_BULK_UPLOAD = 'FinancialRecordBulkUpload',

  MARKET_DATA_RECORD_DOWNLOAD = 'MarketDataRecordDownload',
  MARKET_DATA_RECORD_READ_ANY = 'MarketDataRecordReadAny',
  MARKET_DATA_RECORD_WRITE = 'MarketDataRecordWrite',

  OFFER_COMPENSATION_COMMENT_READ_ANY = 'OfferCompensationCommentReadAny',
  OFFER_COMPENSATION_READ_ANY = 'OfferCompensationReadAny',
  OFFER_COMPENSATION_READ_MY = 'OfferCompensationReadMy',
  OFFER_CREATE = 'OfferCreate',
  OFFER_DOWNLOAD = 'OfferDownload',
  OFFER_STOCK_OPTION_EXISTENCE_READ = 'OfferStockOptionExistenceRead',
  OFFER_STOCK_OPTION_READ_ANY = 'OfferStockOptionReadAny',
  OFFER_STOCK_OPTION_READ_MY = 'OfferStockOptionReadMy',
  OFFER_UPDATE_ANY = 'OfferUpdateAny',
  OFFER_UPDATE_MY = 'OfferUpdateMy',

  ONE_TIME_BONUS_RECORD_READ_ANY = 'OneTimeBonusRecordReadAny',
  ONE_TIME_BONUS_RECORD_READ_SUBORDINATE = 'OneTimeBonusRecordReadSubordinate',
  ONE_TIME_BONUS_RECORD_WRITE = 'OneTimeBonusRecordWrite',

  RECOMMENDED_JOB_TITLE_READ = 'RecommendedJobTitleRead',

  REGULAR_BONUS_RECORD_READ_ANY = 'RegularBonusRecordReadAny',
  REGULAR_BONUS_RECORD_READ_SUBORDINATE = 'RegularBonusRecordReadSubordinate',
  REGULAR_BONUS_RECORD_WRITE = 'RegularBonusRecordWrite',

  SALARY_RANGE_CALCULATE_CR = 'SalaryRangeCalculateCr',
  SALARY_RANGE_CALCULATE_SUBORDINATE_CR = 'SalaryRangeCalculateSubordinateCr',
  SALARY_RANGE_READ_AS_ADMIN = 'SalaryRangeReadAsAdmin',
  SALARY_RANGE_READ_AS_MANAGER = 'SalaryRangeReadAsManager',
  SALARY_RANGE_UPLOAD = 'SalaryRangeUpload',
  SALARY_RANGE_VERSION_READ_AS_ADMIN = 'SalaryRangeVersionReadAsAdmin',
  SALARY_RANGE_VERSION_WRITE = 'SalaryRangeVersionWrite',

  SALARY_RECORD_BULK_IMPORT = 'SalaryRecordBulkImport',
  SALARY_RECORD_DOWNLOAD = 'SalaryRecordDownload',
  SALARY_RECORD_DOWNLOAD_EXTENDED = 'SalaryRecordDownloadExtended',
  SALARY_RECORD_READ = 'SalaryRecordRead',
  SALARY_RECORD_READ_EXTENDED = 'SalaryRecordReadExtended',
  SALARY_RECORD_SUBORDINATE_READ = 'SalaryRecordSubordinateRead',
  SALARY_RECORD_WRITE = 'SalaryRecordWrite',
  SALARY_RECORD_WRITE_EXTENDED = 'SalaryRecordWriteExtended',

  SECURE_PERMISSION_READ = 'SecurePermissionRead',

  SECURE_ROLE_BADGE_READ = 'SecureRoleBadgeRead',
  SECURE_ROLE_GRANT = 'SecureRoleGrant',
  SECURE_ROLE_READ = 'SecureRoleRead',
  SECURE_ROLE_REVOKE = 'SecureRoleRevoke',
  SECURE_ROLE_UPDATE = 'SecureRoleUpdate',

  STOCK_OPTION_BULK_UPLOAD = 'StockOptionBulkUpload',
  STOCK_OPTION_DOWNLOAD_ANY = 'StockOptionDownloadAny',
  STOCK_OPTION_READ_ANY = 'StockOptionReadAny',
  STOCK_OPTION_READ_SUBORDINATE = 'StockOptionReadSubordinate',
  STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER = 'StockOptionSendAgreementToHolder',
  STOCK_OPTION_WRITE_ANY = 'StockOptionWriteAny',

  USER_FULL_INFO_RECORD_READ = 'UserFullInfoRecordRead',
}

export enum SecurePermission {
  COMPENSATION_REVIEW_REQUEST_CREATE_ANY = 'CompensationReviewRequestCreateAny',
  COMPENSATION_REVIEW_REQUEST_CREATE_SUBORDINATE = 'CompensationReviewRequestCreateSubordinate',
  COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY = 'CompensationReviewRequestDownloadAny',
  COMPENSATION_REVIEW_REQUEST_READ_ANY = 'CompensationReviewRequestReadAny',
  COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE = 'CompensationReviewRequestReadSubordinate',
  COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED = 'CompensationReviewRequestSetCompensationsRecorded',
  COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED = 'CompensationReviewRequestSetStockOptionsRecorded',
  COMPENSATION_REVIEW_REQUEST_WRITE = 'CompensationReviewRequestWrite',
  COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF = 'CompensationReviewRequestWriteOnBehalf',

  FINANCIAL_RECORD_BULK_UPLOAD = 'FinancialRecordBulkUpload',

  HR_CONSOLE_VIEW = 'HrConsoleView',
  MANAGER_CONSOLE_VIEW = 'ManagerConsoleView',

  MARKET_DATA_RECORD_DOWNLOAD = 'MarketDataRecordDownload',
  MARKET_DATA_RECORD_READ_ANY = 'MarketDataRecordReadAny',
  MARKET_DATA_RECORD_WRITE = 'MarketDataRecordWrite',

  OFFER_COMPENSATION_COMMENT_READ_ANY = 'OfferCompensationCommentReadAny',
  OFFER_COMPENSATION_READ_ANY = 'OfferCompensationReadAny',
  OFFER_COMPENSATION_READ_MY = 'OfferCompensationReadMy',
  OFFER_CREATE = 'OfferCreate',
  OFFER_DOWNLOAD = 'OfferDownload',
  OFFER_STOCK_OPTION_EXISTENCE_READ = 'OfferStockOptionExistenceRead',
  OFFER_STOCK_OPTION_READ_ANY = 'OfferStockOptionReadAny',
  OFFER_STOCK_OPTION_READ_MY = 'OfferStockOptionReadMy',
  OFFER_UPDATE_ANY = 'OfferUpdateAny',
  OFFER_UPDATE_MY = 'OfferUpdateMy',

  ONE_TIME_BONUS_RECORD_READ_ANY = 'OneTimeBonusRecordReadAny',
  ONE_TIME_BONUS_RECORD_READ_SUBORDINATE = 'OneTimeBonusRecordReadSubordinate',
  ONE_TIME_BONUS_RECORD_WRITE = 'OneTimeBonusRecordWrite',

  RECOMMENDED_JOB_TITLE_READ = 'RecommendedJobTitleRead',

  REGULAR_BONUS_RECORD_WRITE = 'RegularBonusRecordWrite',
  REGULAR_BONUS_RECORD_READ_ANY = 'RegularBonusRecordReadAny',
  REGULAR_BONUS_RECORD_READ_SUBORDINATE = 'RegularBonusRecordReadSubordinate',

  SALARY_RANGE_CALCULATE_CR = 'SalaryRangeCalculateCr',
  SALARY_RANGE_CALCULATE_SUBORDINATE_CR = 'SalaryRangeCalculateSubordinateCr',
  SALARY_RANGE_MANAGE = 'SalaryRangeManage',
  SALARY_RANGE_READ_AS_ADMIN = 'SalaryRangeReadAsAdmin',
  SALARY_RANGE_READ_AS_MANAGER = 'SalaryRangeReadAsManager',
  SALARY_RANGE_UPLOAD = 'SalaryRangeUpload',
  SALARY_RANGE_VERSION_READ_AS_ADMIN = 'SalaryRangeVersionReadAsAdmin',
  SALARY_RANGE_VERSION_WRITE = 'SalaryRangeVersionWrite',

  SALARY_RECORD_BULK_IMPORT = 'SalaryRecordBulkImport',
  SALARY_RECORD_DOWNLOAD = 'SalaryRecordDownload',
  SALARY_RECORD_DOWNLOAD_EXTENDED = 'SalaryRecordDownloadExtended',
  SALARY_RECORD_READ = 'SalaryRecordRead',
  SALARY_RECORD_READ_EXTENDED = 'SalaryRecordReadExtended',
  SALARY_RECORD_SUBORDINATE_READ = 'SalaryRecordSubordinateRead',
  SALARY_RECORD_WRITE = 'SalaryRecordWrite',
  SALARY_RECORD_WRITE_EXTENDED = 'SalaryRecordWriteExtended',

  SECURE_PERMISSION_READ = 'SecurePermissionRead',

  SECURE_ROLE_BADGE_READ = 'SecureRoleBadgeRead',
  SECURE_ROLE_GRANT = 'SecureRoleGrant',
  SECURE_ROLE_READ = 'SecureRoleRead',
  SECURE_ROLE_REVOKE = 'SecureRoleRevoke',
  SECURE_ROLE_UPDATE = 'SecureRoleUpdate',

  SECURE_SELF_SHOW_COMPENSATION_TAB = 'SecureSelfShowCompensationTab',

  SENSITIVE_DATA_DOWNLOAD = 'SensitiveDataDownload',
  SENSITIVE_DATA_VIEW_ADMIN_UI = 'SensitiveDataViewAdminUi',

  STOCK_OPTION_BULK_UPLOAD = 'StockOptionBulkUpload',
  STOCK_OPTION_DOWNLOAD_ANY = 'StockOptionDownloadAny',
  STOCK_OPTION_READ_ANY = 'StockOptionReadAny',
  STOCK_OPTION_READ_SUBORDINATE = 'StockOptionReadSubordinate',
  STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER = 'StockOptionSendAgreementToHolder',
  STOCK_OPTION_WRITE_ANY = 'StockOptionWriteAny',

  USER_FULL_INFO_RECORD_READ = 'UserFullInfoRecordRead',
}
