import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {FieldInput, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {PageLock, usePageLocker} from '@joomcode/joom-ui/PageLock';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {dutyApi} from 'domain/duty/api';
import {DutyDiff} from 'domain/duty/model/diff';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {Schedule} from 'domain/duty/model/schedule';
import {SlotDiff} from 'domain/duty/model/slot/diff';
import {createDutyFx} from 'domain/duty/stores/main';
import {getDataForGeneratingSchedule} from 'domain/duty/utils/getDataForGeneratingSchedule';
import {DutyTimeline} from 'domain/duty/widgets/DutyTimeline';
import {FieldRotation} from 'domain/duty/widgets/FieldRotation';
import {FieldSlackUserGroups} from 'domain/duty/widgets/FieldSlackUserGroups';
import {FieldTime} from 'domain/duty/widgets/FieldTime';
import {FieldTimeZone} from 'domain/duty/widgets/FieldTimeZone';
import {FieldWeekday} from 'domain/duty/widgets/FieldWeekday';
import {LocalHandoffTime} from 'domain/duty/widgets/LocalHandoffTime';
import {UnassignedTeamMembers} from 'domain/duty/widgets/UnassignedTeamMembers';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TeamId} from 'domain/team/model/id';
import {User} from 'domain/user/model';
import {FieldUser} from 'domain/user/widgets/Field';
import orderBy from 'lodash/orderBy';
import React, {useCallback, useMemo, useState} from 'react';
import {Form} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {teamsUrls} from 'routes/teams/urls';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';
import styles from './styles.css';

const today = new Date();

type Props = {
  teamId: TeamId;
  teamMembers: User[];
};

export function DutyForm({teamId, teamMembers}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const selfUserId = useSelfUserId();
  const formId = useMemo(uuid, []);
  const timeZoneOffset = useMemo(() => today.getTimezoneOffset(), []);
  const pageLocker = usePageLocker();
  const [schedule, setSchedule] = useState<Schedule | undefined>(undefined);
  const filterDate = useCallback(
    (weekday: number | undefined) => (date: Date) => weekday === undefined || date.getDay() === weekday,
    [],
  );

  const onSubmit = useCallback(
    (diff: DutyDiff) =>
      createDutyFx({params: {teamId}, diff})
        .then(() => {
          pageLocker.unlock();
          history.push(teamsUrls.duties({id: teamId}));
          toaster.success(intl.formatMessage(messages.success));
        })
        .catch(toaster.interceptThenThrowError),
    [teamId, pageLocker.unlock, history.push, intl],
  );

  const generateSchedule = useAsyncTask<[RotationSettingsDiff], void>(
    (diff) => dutyApi.generateSchedule({diff}).then(setSchedule),
    [setSchedule],
  );

  const getUnassignedTeamMembers = useCallback(
    (rotation: SlotDiff[] | undefined): User[] => {
      if (!rotation) {
        return [];
      }

      const assignedUserIds = rotation.flatMap((slot) => slot.userIds);
      return teamMembers.filter((user) => !assignedUserIds.includes(user.id));
    },
    [teamMembers],
  );

  const getRotationMembers = useCallback(
    (unassignedTeamMembers: User[]) => {
      const unassignedTeamMembersIds = unassignedTeamMembers.map((user) => user.id);
      return orderBy(teamMembers, (user: User) => !unassignedTeamMembersIds.includes(user.id));
    },
    [teamMembers],
  );

  return (
    <Panel withPadding className={styles.panel}>
      <Form<DutyDiff> onSubmit={onSubmit}>
        {({handleSubmit, invalid, submitting, values, form}) => {
          const unassignedTeamMembers = getUnassignedTeamMembers(values.rotationSettings?.rotation);
          const showUnassignedTeamMembers =
            unassignedTeamMembers.length > 0 && unassignedTeamMembers.length < teamMembers.length;

          return (
            <form id={formId} onSubmit={handleSubmit} className={styles.form}>
              <PageLock pageLocker={pageLocker} when={Boolean(schedule || values.description)} />
              <Grid>
                <Grid.Item xl={12}>
                  <FieldInput name='name' label={intl.formatMessage(labels.name)} required />
                </Grid.Item>
                <Grid.Item xl={12}>
                  <FieldTextarea
                    name='description'
                    label={intl.formatMessage(labels.description)}
                    hint={intl.formatMessage(messages.descriptionHint)}
                    required
                  />
                </Grid.Item>
                <Grid.Item xl={12}>
                  <FieldSlackUserGroups
                    name='slackUserGroupIds'
                    label={intl.formatMessage(labels.slackUserGroups)}
                    required
                  />
                </Grid.Item>
                <Grid.Item xl={12}>
                  <FormControl disabled={submitting} label={intl.formatMessage(labels.owner)} required>
                    {(formControlProps) => (
                      <FieldUser
                        disabled={submitting}
                        name='ownerId'
                        validate={validateFilled}
                        initialValue={selfUserId}
                        users={teamMembers}
                        {...formControlProps}
                      />
                    )}
                  </FormControl>
                </Grid.Item>
                <Grid.Item xl={12}>
                  <div className={styles.subtitle}>{intl.formatMessage(messages.handoffMoment)}</div>
                </Grid.Item>
                <Grid.Item xl={12}>
                  <Grid>
                    <Grid.Item xl={4}>
                      <FieldWeekday
                        name='rotationSettings.handoffMoment.weekday'
                        label={intl.formatMessage(labels.weekday)}
                        required
                      />
                    </Grid.Item>
                    <Grid.Item xl={8}>
                      <Grid>
                        <Grid.Item xl={4}>
                          <FieldTime
                            name='rotationSettings.handoffMoment.time'
                            label={intl.formatMessage(labels.time)}
                            required
                          />
                        </Grid.Item>
                        <Grid.Item xl={8}>
                          <div className={styles.timeZone}>
                            <div className={styles.timeZoneField}>
                              <FieldTimeZone
                                name='rotationSettings.handoffMoment.timeZone'
                                label={intl.formatMessage(labels.timeZone)}
                                required
                              />
                            </div>
                            {timeZoneOffset !== 0 &&
                              values.rotationSettings?.handoffMoment?.time &&
                              values.rotationSettings?.handoffMoment?.weekday && (
                                <div className={styles.localTime}>
                                  <LocalHandoffTime
                                    offset={timeZoneOffset}
                                    time={values.rotationSettings.handoffMoment.time}
                                    weekday={values.rotationSettings.handoffMoment.weekday}
                                  />
                                </div>
                              )}
                          </div>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
                <Grid.Item xl={4}>
                  <FieldDate
                    name='rotationSettings.effectiveDate'
                    label={intl.formatMessage(labels.effectiveDate)}
                    minDate={today}
                    filterDate={filterDate(values.rotationSettings?.handoffMoment?.weekday)}
                    required
                  />
                  <OnChange name='rotationSettings.handoffMoment.weekday'>
                    {() => {
                      form.change('rotationSettings', {...values?.rotationSettings, effectiveDate: undefined});
                    }}
                  </OnChange>
                </Grid.Item>
                <Grid.Item xl={12}>
                  <FieldRotation
                    name='rotationSettings.rotation'
                    label={intl.formatMessage(labels.rotation)}
                    required
                    users={getRotationMembers(unassignedTeamMembers)}
                  />
                  <OnChange name='rotationSettings'>
                    {() => {
                      const diff = getDataForGeneratingSchedule(values);
                      if (diff) {
                        generateSchedule.perform(diff);
                      }
                    }}
                  </OnChange>
                </Grid.Item>
              </Grid>
              <SubmitFormPanel>
                <div className={styles.submitPanel}>
                  <ButtonGroup spaced size='l'>
                    <Button intent='neutral' kind='secondary' onClick={history.goBack} disabled={submitting}>
                      {intl.formatMessage(messages.buttonBack)}
                    </Button>
                    <Button
                      form={formId}
                      kind='primary'
                      intent='primary'
                      type='submit'
                      disabled={invalid}
                      loading={submitting}
                    >
                      {intl.formatMessage(messages.buttonSubmit)}
                    </Button>
                  </ButtonGroup>
                  {showUnassignedTeamMembers && <UnassignedTeamMembers users={unassignedTeamMembers} />}
                </div>
              </SubmitFormPanel>
            </form>
          );
        }}
      </Form>
      {schedule && (
        <div className={styles.schedule}>
          <DutyTimeline
            duty={{
              title: intl.formatMessage(messages.main),
              mainSchedule: schedule.main,
              overrideSchedule: schedule.overrides,
              timezoneOffset: 0,
            }}
            teamId={teamId}
          />
        </div>
      )}
    </Panel>
  );
}
