import {max, min} from 'date-fns';
import {ScheduleIssue} from 'domain/duty/model/scheduleIssue';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {getEndOfTheDayInTimezone, getStartOfTheDayInTimezone} from 'domain/duty/utils/timeZone';
import {TimelineItemWithoutGroup} from '../types';

type IssueItem = TimelineItemWithoutGroup<'issueStripe'>;

function mergeIssues(issues: ScheduleIssue[], item: ScheduleItem, timezoneOffset: number): IssueItem[] {
  if (issues.length === 0) return [];

  issues.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

  const result: IssueItem[] = [];
  let currentGroup: ScheduleIssue[] = [issues[0]];
  let latestEndDate = new Date(issues[0].endDate || item.endDate);

  for (let i = 1; i < issues.length; i++) {
    const currentIssue = issues[i];
    const currentEndDate = new Date(currentIssue.endDate || item.endDate);

    if (new Date(currentIssue.startDate).getTime() <= latestEndDate.getTime()) {
      currentGroup.push(currentIssue);
      latestEndDate = max([latestEndDate, currentEndDate]);
    } else {
      result.push(createIssueItem(currentGroup, item, timezoneOffset));
      currentGroup = [currentIssue];
      latestEndDate = currentEndDate;
    }
  }

  result.push(createIssueItem(currentGroup, item, timezoneOffset));

  return result;
}

function createIssueItem(group: ScheduleIssue[], item: ScheduleItem, timezoneOffset: number): IssueItem {
  const groupStart = min(group.map((issue) => getStartOfTheDayInTimezone(issue.startDate, timezoneOffset)));
  const groupEnd = max(group.map((issue) => getEndOfTheDayInTimezone(issue.endDate || item.endDate, timezoneOffset)));
  const start = max([new Date(item.startDate), groupStart]);
  const end = min([new Date(item.endDate), groupEnd]);

  return {
    start,
    end,
    data: {
      issues: group,
      item,
    },
  };
}

export function getIssueStripeItems(items: ScheduleItem[], timezoneOffset: number): IssueItem[] {
  return items.flatMap((item) => mergeIssues(item.issues, item, timezoneOffset));
}
