import React, {useMemo} from 'react';
import cn from 'classnames';
import {ReactComponent as EllipsisVerticalIcon} from '../../../icons/core/ellipsisVertical.svg';
import {CellEmpty} from '../CellEmpty';
import {Menu} from '../../../Menu';
import {GetRowActions} from '../../types';
import styles from './index.css';

type Props<Item> = {
  item: Item;
  rowActionsAriaLabel: string;
  getRowActions: GetRowActions<Item>;
};

export const RowActionsDisclosure = <Item,>({item, rowActionsAriaLabel, getRowActions}: Props<Item>) => {
  const rowActions = useMemo(() => getRowActions(item), [item, getRowActions]);

  if (!rowActions?.length) return <CellEmpty />;

  return (
    <Menu
      ariaLabel={rowActionsAriaLabel}
      placement='bottom-end'
      // eslint-disable-next-line react/no-unstable-nested-components
      disclosure={(props, {isOpen}) => (
        <button
          {...props}
          type='button'
          className={cn(styles.disclosure, {[styles.disclosureOpen]: isOpen})}
          aria-label={rowActionsAriaLabel}
        >
          <EllipsisVerticalIcon className={styles.icon} />
        </button>
      )}
    >
      {rowActions.map((rowAction) => (
        <Menu.Item onClick={rowAction.onClick} key={rowAction.label}>
          {rowAction.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};
