import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Duty} from 'domain/duty/model';
import {DutyAddOverrideButton} from 'domain/duty/widgets/AddOverrideButton';
import {DutyDeleteButton} from 'domain/duty/widgets/DeleteButton';
import {DutyTimeline} from 'domain/duty/widgets/DutyTimeline';
import {DutyPanelHeader} from 'domain/duty/widgets/PanelHeader';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TeamId} from 'domain/team/model/id';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  duty: Duty;
  teamId: TeamId;
  isTeamOwner: boolean;
};

export function DutyPanel({duty, teamId, isTeamOwner}: Props) {
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const canManageOverrides =
    isTeamOwner ||
    duty.owner.id === selfUserId ||
    duty.rotationSettings.rotation.flatMap(({users}) => users).some(({id}) => id === selfUserId);

  return (
    <Panel
      title={<DutyPanelHeader duty={duty} />}
      withPadding
      withSeparator
      toolbarTop={
        <ButtonGroup spaced>
          {duty.owner.id === selfUserId && <DutyDeleteButton dutyId={duty.id} teamId={teamId} />}
          {canManageOverrides && <DutyAddOverrideButton dutyId={duty.id} teamId={teamId} intent='ghost' />}
        </ButtonGroup>
      }
    >
      <DutyTimeline
        duty={{
          title: intl.formatMessage(messages.main),
          mainSchedule: duty.schedule.main,
          overrideSchedule: duty.schedule.overrides,
          timezoneOffset: duty.rotationSettings.handoffMoment.timeZone,
        }}
        dutyId={duty.id}
        teamId={teamId}
        canCreateOverrides={canManageOverrides}
        canDeleteOverrides={canManageOverrides}
        showOverrides={duty.schedule.overrides.length > 0}
      >
        <div className={styles.content}>
          <div>
            {intl.formatList(
              duty.slackUserGroups.map((group) => (
                <span className={styles.group} key={group.id}>
                  {`@${group.handle}`}
                </span>
              )),
              {type: 'conjunction', style: 'narrow'},
            )}
          </div>
          {duty.description}
        </div>
      </DutyTimeline>
    </Panel>
  );
}
