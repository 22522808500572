import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {IntervalGroup, TimelineItem} from '@joomcode/joom-ui/Timeline/types';
import {isSameDay} from 'date-fns';
import {DutyId} from 'domain/duty/model/lite';
import {getDefaultTimeZone} from 'domain/duty/utils/timeZone';
import {DutyAddOverrideButton} from 'domain/duty/widgets/AddOverrideButton';
import {DutyDeleteOverrideButton} from 'domain/duty/widgets/DeleteOverrideButton';
import {ScheduleIssueBadge} from 'domain/duty/widgets/ScheduleIssueBadge';
import {TeamId} from 'domain/team/model/id';
import {User} from 'domain/user/model';
import React from 'react';
import {IntlShape} from 'react-intl';
import {Schema} from '../../types';
import {getDuration} from '../getDuration';
import {messages} from './messages';
import {formatDate, formatDateTime, formatTime, isSameDayUTC} from './utils';

type Options = {
  canCreateOverrides?: boolean;
  canDeleteOverrides?: boolean;
  dutyId?: DutyId;
  interval: IntervalGroup<TimelineItem<Schema, 'duty' | 'ghostDuty' | 'override'>>;
  intl: IntlShape;
  isOverride?: boolean;
  teamId: TeamId;
  users: User[];
};

export function getScheduleIssueTooltipItems({
  canCreateOverrides,
  canDeleteOverrides,
  dutyId,
  interval,
  intl,
  isOverride,
  teamId,
  users,
}: Options) {
  const timeZoneOffset = new Date().getTimezoneOffset();
  return interval.items.map((item) => {
    const userIds = item.data.slot.users.map(({id}) => id);
    const startDate = new Date(item.data.startDate);
    const endDate = new Date(item.data.endDate);
    const currentDate = new Date();

    const userTooltipItems = users.map((user) => {
      return {
        user,
        content: (
          <>
            {item.data.issues
              .filter(({userId}) => userId === user.id)
              .map((issue) => (
                <ScheduleIssueBadge key={issue.id} issue={issue} user={user} />
              ))}
          </>
        ),
      };
    });

    const startTooltipItem = {
      content: intl.formatMessage(messages.start, {
        date: formatDateTime(item.data.startDate, intl),
        timeZone: getDefaultTimeZone(),
      }),
    };

    const localDatesOptions = {
      withLocal: timeZoneOffset !== 0,
      localRange: isSameDay(new Date(item.data.startDate), new Date(item.data.endDate))
        ? intl.formatMessage(messages.localTimeRange, {
            startDate: formatDate(item.data.startDate, intl, false),
            startTime: formatTime(item.data.startDate, intl, false),
            endTime: formatTime(item.data.endDate, intl, false),
            br: <br />,
          })
        : intl.formatMessage(messages.localDateRange, {
            startDate: formatDateTime(item.data.startDate, intl, false),
            endDate: formatDateTime(item.data.endDate, intl, false),
            br: <br />,
          }),
    };
    const rangeTooltipItem = {
      content: isSameDayUTC(item.data.startDate, item.data.endDate)
        ? intl.formatMessage(messages.timeRange, {
            startDate: formatDate(item.data.startDate, intl),
            startTime: formatTime(item.data.startDate, intl),
            endTime: formatTime(item.data.endDate, intl),
            timeZone: getDefaultTimeZone(),
            ...localDatesOptions,
          })
        : intl.formatMessage(messages.dateRange, {
            startDate: formatDateTime(item.data.startDate, intl),
            endDate: formatDateTime(item.data.endDate, intl),
            timeZone: getDefaultTimeZone(),
            ...localDatesOptions,
          }),
    };

    const durationTooltipItem = {
      content: intl.formatMessage(messages.duration, {
        withPrefix: !isOverride,
        duration: getDuration(item.data.startDate, item.data.endDate),
      }),
    };

    const addOverrideTooltipItem =
      currentDate < endDate && canCreateOverrides && dutyId
        ? {
            content: (
              <DutyAddOverrideButton
                dutyId={dutyId}
                teamId={teamId}
                userIds={userIds}
                startDate={currentDate > startDate ? currentDate.toISOString() : item.data.startDate}
                endDate={item.data.endDate}
              />
            ),
          }
        : undefined;

    const deleteOverrideTooltipItem =
      currentDate < endDate && canDeleteOverrides && dutyId && item.data.id
        ? {
            content: <DutyDeleteOverrideButton dutyId={dutyId} teamId={teamId} id={item.data.id} />,
          }
        : undefined;

    const tooltip = isOverride
      ? [...userTooltipItems, rangeTooltipItem, durationTooltipItem, deleteOverrideTooltipItem].filter(isNotNullish)
      : [...userTooltipItems, startTooltipItem, durationTooltipItem, addOverrideTooltipItem].filter(isNotNullish);

    return {
      ...item,
      data: {
        ...item.data,
        tooltip,
      },
    };
  });
}
