import {array, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {roleSchema} from 'domain/role/model';
import {teamLiteSchema} from 'domain/team/model/lite';

export const userMembershipsSchema = array(
  object({
    ancestorTeamForCollapse: optional(teamLiteSchema),
    team: teamLiteSchema,
    teamAncestors: array(teamLiteSchema),
    roles: array(roleSchema),
  }),
);

export type UserMemberships = ExtractSchemaType<typeof userMembershipsSchema>;
