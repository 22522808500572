import {array, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {handoffMomentSchema} from 'domain/duty/model/handoffMoment';
import {slotSchema} from 'domain/duty/model/slot';
import {formattedDateSchema} from 'models/system/formattedDate';

export const rotationSettingsSchema = object({
  effectiveDate: formattedDateSchema,
  handoffMoment: handoffMomentSchema,
  rotation: array(slotSchema),
});

export type RotationSettings = ExtractSchemaType<typeof rotationSettingsSchema>;
