import React from 'react';
import type {TooltipItem} from '../../types';
import {Item} from './Item';
import styles from './styles.css';

type PropsType = {
  items: TooltipItem[];
};

export function TooltipContent({items}: PropsType) {
  return (
    <section className={styles.TooltipContent}>
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Item key={index} item={item} />
      ))}
    </section>
  );
}
