import {Badge} from '@joomcode/joom-ui/Badge';
import {Page} from 'components/widgets/Page';
import {useCompensationReviewRequestCreateAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestCreateAccess';
import {useDraftSwitching} from 'domain/compensationReviewRequest/hooks/useDraftSwitching';
import {CompensationReviewRequestForm} from 'domain/compensationReviewRequest/widgets/Form';
import {SecurePermission} from 'domain/permission/model/secure';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';
import {hrConsoleUrls} from 'routes/hrConsole/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

export function HrConsoleCompensationReviewRequestCreatePage() {
  const intl = useIntl();
  const acl = useAcl();
  const {draft, setDraft, scrollRef} = useDraftSwitching();
  const createAccess = useCompensationReviewRequestCreateAccess();

  if (!createAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.compensationReviewRequest)}
      titleAddon={
        draft ? (
          <div className={styles.badge}>
            <Badge intent='warning'>{intl.formatMessage(messages.badge)}</Badge>
          </div>
        ) : undefined
      }
      breadcrumbs={[
        {title: intl.formatMessage(pageTitles.hrConsole), href: hrConsoleUrls.compensationReviewRequests()},
      ]}
      ref={scrollRef}
    >
      <CompensationReviewRequestForm
        draft={draft}
        onlySubordinates={!acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_ANY)}
        setDraft={setDraft}
      />
    </Page>
  );
}
