import {ScheduleIssue} from 'domain/duty/model/scheduleIssue';
import {ScheduleIssueType} from 'domain/duty/model/scheduleIssue/type';
import {User} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import {messages} from './messages';
import styles from './styles.css';
import {formatDate} from './utils';

type Props = {
  user: User;
  issue: ScheduleIssue;
  withFullName?: boolean;
};

export function ScheduleIssueBadge({
  issue: {duty, endDate, publicHolidayCountry, startDate, type},
  user: {isTerminated, fullName},
  withFullName,
}: Props) {
  const intl = useIntl();
  const dates =
    startDate === endDate || !endDate
      ? formatDate(startDate, intl)
      : intl.formatMessage(messages.dateRange, {
          from: formatDate(startDate, intl),
          to: formatDate(endDate, intl),
        });

  if (type === ScheduleIssueType.TERMINATION) {
    if (isTerminated) {
      return (
        <div className={styles.high}>
          {intl.formatMessage(withFullName ? messages.terminatedUserWithFullName : messages.terminatedUser, {
            fullName,
          })}
        </div>
      );
    }

    return (
      <div className={styles.high}>
        {intl.formatMessage(withFullName ? messages.leavesCompanyWithFullName : messages.leavesCompany, {
          date: formatDate(startDate, intl),
          fullName,
        })}
      </div>
    );
  }

  if (type === ScheduleIssueType.PUBLIC_HOLIDAY && publicHolidayCountry) {
    return (
      <div className={styles.medium}>
        {intl.formatMessage(withFullName ? messages.publicHolidayWithFullName : messages.publicHoliday, {
          country: getCountryName(publicHolidayCountry, intl),
          dates,
          fullName,
        })}
      </div>
    );
  }

  if (type === ScheduleIssueType.DUTY && duty) {
    return (
      <div className={styles.medium}>
        {intl.formatMessage(withFullName ? messages.dutyWithFullName : messages.duty, {
          dutyName: duty.name.toLocaleLowerCase().includes('duty') ? duty.name : `${duty.name} duty`,
          dates,
          fullName,
        })}
      </div>
    );
  }

  if (type === ScheduleIssueType.TIME_OFF) {
    return (
      <div className={styles.medium}>
        {intl.formatMessage(withFullName ? messages.timeOffWithFullName : messages.timeOff, {dates, fullName})}
      </div>
    );
  }

  return null;
}
