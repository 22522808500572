import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {getUserInfoFx} from 'domain/compensationReviewRequest/stores/userInfo';
import {$userInfo} from 'domain/compensationReviewRequest/stores/userInfo/state';
import {UserId} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';
import {useCompensationReviewRequestCreateAccess} from './useCompensationReviewRequestCreateAccess';

type ReturnShape = {
  data: UserInfo;
  dataState: DataState;
};

export function useUserInfo(userId: UserId): ReturnShape {
  const createAccess = useCompensationReviewRequestCreateAccess();
  const data = useStoreMap({
    store: $userInfo,
    keys: [userId],
    fn: ({byUserId}, [id]) => byUserId[id],
  });
  const dataState = useStoreMap({
    store: $userInfo,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => dataStateByUserId[id] || DataState.IDLE,
  });

  useEffect(() => {
    if (createAccess && !isLoadingOrLoaded(dataState)) {
      getUserInfoFx(userId).catch((error: Error) => toaster.error(error.message));
    }
  }, [createAccess, userId]);

  return {
    data,
    dataState,
  };
}
