import {useMemo} from 'react';
import {useTimeline} from '../Timeline/Provider';
import {type Interval} from '../types';
import {createInterval, type TimelineUnitDateFns} from '../utils';

type DateIntervalType = {
  date: Date;
  interval: Interval;
};

export function useDateIntervals(dateFns: TimelineUnitDateFns, enabled = true): DateIntervalType[] {
  const {boundary, offsetMs} = useTimeline();

  return useMemo(() => {
    const result: DateIntervalType[] = [];

    if (enabled) {
      let date = dateFns.start(boundary.start + offsetMs);
      while (date.getTime() < boundary.end + offsetMs) {
        const start = date.getTime() - offsetMs;
        const end = dateFns.end(date).getTime() - offsetMs;
        result.push({
          date,
          interval: createInterval({
            start,
            end: boundary.end < end ? boundary.end : end,
          }),
        });
        date = dateFns.add(date, 1);
      }
    }

    return result;
  }, [dateFns, boundary.start, boundary.end, enabled, offsetMs]);
}
