import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {mockUser} from 'domain/user/model/mock';
import faker from 'faker';
import {Duty} from '.';
import {DutyLite} from './lite';
import {mockRotationSettings} from './rotatationSettings/mock';
import {mockSchedule} from './schedule/mock';
import {mockSlackUserGroup} from './slackUserGroup/mock';

export function mockDutyLite(data: Partial<DutyLite> = {}): DutyLite {
  return {
    id: createMockId(),
    name: `${faker.lorem.word()}.duty`,
    ...data,
  };
}

export function mockDuty(data: Partial<Duty> = {}): Duty {
  return {
    ...mockDutyLite(),
    calendarLink: faker.internet.url(),
    description: faker.lorem.sentences(),
    owner: mockUser(),
    rotationSettings: mockRotationSettings(),
    schedule: mockSchedule(),
    slackUserGroups: [mockSlackUserGroup()],
    ...data,
  };
}
