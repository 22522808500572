import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {CompensationReviewRequest, compensationReviewRequestSchema} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestDiff} from 'domain/compensationReviewRequest/model/diff';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';

export type CompensationReviewRequestCreateConfig = {
  userId: UserId;
  diff: CompensationReviewRequestDiff;
};

const createGeneric =
  (endpoint: string) =>
  async ({diff, ...params}: CompensationReviewRequestCreateConfig): Promise<CompensationReviewRequest> => {
    const {data} = await intranetApi.post(endpoint, diff, {params});

    return compensationReviewRequestSchema.runWithException(data);
  };

const createAny = createGeneric('/v1/secure/compensationReviewRequests/create');
const createSubordinate = createGeneric('/v1/secure/compensationReviewRequests/createSubordinate');

export const create = createPermissionDependentHandler(
  [{securePermission: SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_ANY, handler: createAny}],
  createSubordinate,
);
