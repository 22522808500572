import React from 'react';
import {type GroupId, type GroupProps, type HeadingGroupProps, type ItemGroupProps} from '../types';
import {HeadingGroup} from './HeadingGroup';
import {ItemGroup} from './ItemGroup';

export function Group<T extends Record<GroupId, unknown>>(props: GroupProps<T>) {
  const {group} = props;

  if ('title' in group) {
    return <HeadingGroup {...(props as HeadingGroupProps<T>)} />;
  }

  return <ItemGroup {...(props as ItemGroupProps<T>)} />;
}
