import {Badge} from '@joomcode/joom-ui/Badge';
import {Page} from 'components/widgets/Page';
import {useCompensationReviewRequestCreateAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestCreateAccess';
import {useDraftSwitching} from 'domain/compensationReviewRequest/hooks/useDraftSwitching';
import {NOTION_GUIDE} from 'domain/compensationReviewRequest/model/notionGuide';
import {CompensationReviewRequestForm} from 'domain/compensationReviewRequest/widgets/Form';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/managerConsole/titles';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {messages} from './messages';
import styles from './styles.css';

export function ManagerConsoleCompensationReviewRequestCreatePage() {
  const intl = useIntl();
  const {draft, setDraft, scrollRef} = useDraftSwitching();
  const createAccess = useCompensationReviewRequestCreateAccess();

  if (!createAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.compensationReviewRequest)}
      titleAddon={
        draft ? (
          <div className={styles.badge}>
            <Badge intent='warning'>{intl.formatMessage(messages.badge)}</Badge>
          </div>
        ) : undefined
      }
      breadcrumbs={[
        {title: intl.formatMessage(pageTitles.managerConsole), href: managerConsoleUrls.compensationReviewRequests()},
      ]}
      ref={scrollRef}
      notionGuide={NOTION_GUIDE}
    >
      <CompensationReviewRequestForm draft={draft} onlySubordinates setDraft={setDraft} />
    </Page>
  );
}
