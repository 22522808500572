import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';
import {UserId} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {teamsUrls} from 'routes/teams/urls';
import {messages} from './messages';

type Props = {
  dutyId: DutyId;
  teamId: TeamId;
  intent?: 'primary' | 'ghost';
  startDate?: string;
  endDate?: string;
  userIds?: UserId[];
};

export function DutyAddOverrideButton({dutyId, teamId, intent = 'primary', startDate, endDate, userIds}: Props) {
  const intl = useIntl();

  if (intent === 'ghost') {
    return (
      <RouterLinkButton
        size='m'
        kind='text'
        intent='ghost'
        to={teamsUrls.dutyOverrideCreate({id: teamId, dutyId})}
        iconLeft={<PlusIcon />}
      >
        {intl.formatMessage(messages.buttonFull)}
      </RouterLinkButton>
    );
  }

  return (
    <RouterLinkButton
      size='m'
      kind='primary'
      intent='primary'
      to={teamsUrls.dutyOverrideCreate({id: teamId, dutyId}, {startDate, endDate, userIds})}
    >
      {intl.formatMessage(messages.button)}
    </RouterLinkButton>
  );
}
