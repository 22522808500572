import {TeamPath} from 'domain/team/widgets/Path';
import {UserMemberships} from 'domain/user/model/memberships';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

export function UserMembershipCard({team, roles, teamAncestors}: UserMemberships[number]) {
  const intl = useIntl();

  return (
    <div>
      <TeamPath team={team} teamAncestors={teamAncestors} />
      <div className={styles.roles}>
        {intl.formatList(
          roles.map((role) => (
            <span className={styles.role} key={role.id}>
              {role.name}
            </span>
          )),
          {type: 'conjunction', style: 'narrow'},
        )}
      </div>
    </div>
  );
}
