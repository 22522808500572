import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {UserFullInfoReadAccess} from 'domain/userFullInfoRecord/model/access';
import {useAcl} from 'services/acl';

export function useUserFullInfoReadAccess(): UserFullInfoReadAccess | null {
  const acl = useAcl();
  const selfUser = useSelfUser();
  const isManager = Boolean(selfUser?.subordinates && selfUser.subordinates.length > 0);

  if (acl.hasSecurePermission(SecurePermission.USER_FULL_INFO_RECORD_READ)) {
    return UserFullInfoReadAccess.ANY;
  }

  if (isManager) {
    return UserFullInfoReadAccess.SUBORDINATE;
  }

  return null;
}
