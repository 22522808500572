import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Sorting} from '@joomcode/deprecated-utils/sort/types';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import {ExternalLink} from '@joomcode/joom-ui/ExternalLink';
import {HumanDate, HumanDateRange} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as WarningIcon} from '@joomcode/joom-ui/icons/circle/warning.svg';
import {ReactComponent as ExternalLinkIcon} from '@joomcode/joom-ui/icons/core/linkExternal.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {ClampedText} from 'components/ui/ClampedText';
import {StyledLink} from 'components/ui/StyledLink';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {CrLabel} from 'domain/compaRatio/widgets/Label';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import {getPrimaryLegalEntity} from 'domain/contract/utils/getPrimaryLegalEntity';
import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {formatContractType} from 'domain/jobContract/model/type/formatter';
import {JobContractStatusBadge} from 'domain/jobContract/widgets/StatusBadge';
import {Column} from 'domain/managerConsole/model/column';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {formatOfficePolicy} from 'domain/officePolicyRecord/model/policy/formatter';
import {FormattedRegularBonus} from 'domain/regularBonusRecord/widgets/FormattedRegularBonus';
import {getMonthlySalary} from 'domain/salaryRecord/utils/getMonthlySalary';
import {FormattedSalary} from 'domain/salaryRecord/widgets/FormattedSalary';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import {UserLink} from 'domain/user/widgets/Link';
import {SalaryManagerIcon} from 'domain/user/widgets/SalaryManagerIcon';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {UserFullInfoColumn} from 'domain/userFullInfoRecord/model/columns';
import {fieldLabels} from 'domain/userFullInfoRecord/model/columns/messages';
import {UserFullInfoColumnTreeItem} from 'domain/userFullInfoRecord/model/columns/tree';
import {getActiveJobContractRecord} from 'domain/userFullInfoRecord/utils/getActiveJobContractRecord';
import {makeColumnLockable} from 'domain/userFullInfoRecord/utils/makeColumnLockable';
import {makeColumnSensitive} from 'domain/userFullInfoRecord/utils/makeColumnSensitive';
import {MultiValue} from 'domain/userFullInfoRecord/widgets/MultiValue';
import {SalaryCrValue} from 'domain/userFullInfoRecord/widgets/SalaryCrValue';
import {SalaryRangeValue} from 'domain/userFullInfoRecord/widgets/SalaryRangeValue';
import {formatPhone} from 'models/system/phone';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {hrConsoleUrls} from 'routes/hrConsole/urls';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {usersUrls} from 'routes/users/urls';
import {getCountryName} from 'utils/country/name';
import {getHumanMonthAndDay} from 'utils/date/getHumanMonthAndDay';
import {hints, messages} from './messages';
import styles from './styles.css';

const getRowKey = ({user}: UserFullInfoRecord) => user.id;
const getRowClassName = () => styles.row;

type Props = {
  data: UserFullInfoRecord[];
  availableColumns: UserFullInfoColumnTreeItem[];
  isSensitiveDataHidden: boolean;
  showMonthlySalaries: boolean;
  onlySubordinates?: boolean;
  sorting: Sorting | undefined;
  onSort: (sorting: Sorting | undefined) => void;
  onVisibleColumnsChange: (visibleIds: string[]) => void;
};

export function UserFullInfoRecordTable({
  data,
  availableColumns,
  isSensitiveDataHidden,
  showMonthlySalaries,
  onlySubordinates,
  sorting,
  onSort,
  onVisibleColumnsChange,
}: Props) {
  const intl = useIntl();
  const parentByColumnId = useMemo(
    () =>
      arrayToObject(
        availableColumns,
        ({key}) => key,
        ({parent}) => availableColumns.find(({key}) => key === parent),
      ),
    [availableColumns],
  );
  const childrenByColumnId = useMemo(
    () =>
      arrayToObject(
        availableColumns,
        ({key}) => key,
        ({key}) => availableColumns.filter(({parent}) => parent === key),
      ),
    [availableColumns],
  );
  const columns: Column[] = useMemo(() => {
    const allColumns: Column[] = [
      {
        defaultWidth: 170,
        id: UserFullInfoColumn.USER,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.USER])}</span>,
        render: ({user}) => (
          <div className={styles.user}>
            <StyledLink colored to={usersUrls.user({login: user.login})}>
              {intl.formatMessage(messages.user, {firstName: user.firstName, lastName: user.lastName})}
            </StyledLink>
            <SalaryManagerIcon user={user} />
          </div>
        ),
        sticky: 'left',
      },
      {
        defaultWidth: 235,
        id: UserFullInfoColumn.EMAIL,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.EMAIL])}</span>,
        render: ({user}) => user.email,
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 117,
        id: UserFullInfoColumn.BIRTHDAY,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.BIRTHDAY])}</span>,
        render: ({user}) =>
          user.monthAndDayOfBirth ? getHumanMonthAndDay(user.monthAndDayOfBirth, intl) : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 130,
        id: UserFullInfoColumn.LOGIN,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.LOGIN])}</span>,
        render: ({user}) => user.login,
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 145,
        id: UserFullInfoColumn.GITHUB_LOGIN,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.GITHUB_LOGIN])}</span>,
        render: ({user}) => user.verifiedGithubLogin ?? <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 150,
        id: UserFullInfoColumn.PHONE,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.PHONE])}</span>,
        render: ({user}) => formatPhone(user.phone) || user.phone || <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 102,
        id: UserFullInfoColumn.HIRE_DATE,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.HIRE_DATE])}</span>,
        render: ({user}) =>
          user.hireDate ? <HumanDate format='short' value={user.hireDate} utc /> : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 215,
        id: UserFullInfoColumn.PRIMARY_LEGAL_ENTITY,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.PRIMARY_LEGAL_ENTITY])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.PRIMARY_LEGAL_ENTITY]),
        render: ({contracts}) => {
          const legalEntity = contracts && getPrimaryLegalEntity(contracts);
          return legalEntity ? legalEntity.title : <DataTable.CellEmpty />;
        },
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 110,
        id: UserFullInfoColumn.TIME_OFF_POLICY,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.TIME_OFF_POLICY])}</span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.TIME_OFF_POLICY]),
        render: ({user}) =>
          user.countryOfTimeOffPolicy ? (
            formatTimeOffPolicy(user.countryOfTimeOffPolicy, intl)
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 137,
        id: UserFullInfoColumn.LOCATION,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.LOCATION])}</span>,
        render: ({user}) =>
          user.location && user.officePolicy !== OfficePolicy.REMOTE ? user.location : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 90,
        id: UserFullInfoColumn.OFFICE_POLICY,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.OFFICE_POLICY])}</span>,
        render: ({user}) => (user.officePolicy ? formatOfficePolicy(user.officePolicy, intl) : <DataTable.CellEmpty />),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 87,
        align: 'right',
        id: UserFullInfoColumn.REMOTE_WORK_BALANCE,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.REMOTE_WORK_BALANCE])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.REMOTE_WORK_BALANCE]),
        render: ({user, remoteDaysBalance}) =>
          !user.officePolicy || user.officePolicy === OfficePolicy.NOT_APPLICABLE ? (
            <DataTable.CellEmpty />
          ) : (
            <FormattedTimeOffDays days={remoteDaysBalance || 0} />
          ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 99,
        align: 'right',
        id: UserFullInfoColumn.VACATION_BALANCE,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.VACATION_BALANCE])}</span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.VACATION_BALANCE]),
        render: ({vacationBalance}) => <FormattedTimeOffDays days={vacationBalance || 0} />,
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 140,
        id: UserFullInfoColumn.DIVISION,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.DIVISION])}</span>,
        render: ({user}) => user.division,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 160,
        id: UserFullInfoColumn.DEPARTMENT,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.DEPARTMENT])}</span>,
        render: ({user}) => user.department,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 160,
        id: UserFullInfoColumn.JOB_TITLE,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_TITLE])}</span>,
        render: ({activeJobInfoRecord}) => activeJobInfoRecord?.jobTitle,
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 170,
        id: UserFullInfoColumn.MANAGER,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.MANAGER])}</span>,
        render: ({activeJobInfoRecord}) =>
          activeJobInfoRecord?.manager ? (
            <UserLink withAvatar={false} colored user={activeJobInfoRecord.manager} />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 165,
        id: UserFullInfoColumn.USER_FUNCTION,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.USER_FUNCTION])}</span>,
        render: ({activeMarketDataRecord}) => activeMarketDataRecord?.userFunction,
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 70,
        id: UserFullInfoColumn.LEVEL_CODE,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.LEVEL_CODE])}</span>,
        render: ({activeMarketDataRecord}) => activeMarketDataRecord?.levelCode,
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 102,
        id: UserFullInfoColumn.SALARY_START_DATE,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_START_DATE])}</span>
        ),
        render: ({contracts}) => {
          const salaries = getSalariesFromContractRecords(contracts);

          return salaries && salaries.length > 0 ? (
            <MultiValue
              values={salaries.map(({startDate}) => (
                <HumanDate format='short' value={startDate} utc />
              ))}
            />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 215,
        id: UserFullInfoColumn.SALARY_LEGAL_ENTITY,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_LEGAL_ENTITY])}
          </span>
        ),
        render: ({contracts}) => {
          const salaries = getSalariesFromContractRecords(contracts);

          return salaries && salaries.length > 0 ? (
            <MultiValue values={salaries.map(({legalEntity}) => legalEntity.title)} />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 197,
        id: UserFullInfoColumn.SALARY_FIX_BASE,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_FIX_BASE])}</span>
        ),
        render: ({contracts}) => {
          const salaries = getSalariesFromContractRecords(contracts);

          return salaries && salaries.length > 0 ? (
            <MultiValue
              values={salaries.map((salary) => {
                if (showMonthlySalaries) {
                  const monthlySalary = getMonthlySalary(salary);
                  if (!monthlySalary) {
                    return (
                      <TooltipFaq content={intl.formatMessage(messages.monthlySalaryError)}>
                        <WarningIcon className={styles.icon} />
                      </TooltipFaq>
                    );
                  }
                  salary = monthlySalary;
                }

                const {amount, currency, frequency} = salary;
                return <FormattedSalary money={{amount, currency}} frequency={frequency} />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
      },
      {
        defaultWidth: 100,
        align: 'right',
        id: UserFullInfoColumn.SALARY_EMPLOYMENT_RATE,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_EMPLOYMENT_RATE])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_EMPLOYMENT_RATE]),
        render: ({contracts}) => {
          const salaries = getSalariesFromContractRecords(contracts);

          return salaries && salaries.length > 0 ? (
            <MultiValue values={salaries.map(({employmentRate}) => intl.formatNumber(employmentRate))} />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
      },
      {
        defaultWidth: 105,
        id: UserFullInfoColumn.SALARY_REGION,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_REGION])}</span>,
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_REGION]),
        render: ({contracts}) => {
          const salaries = getSalariesFromContractRecords(contracts);

          return salaries && salaries.length > 0 ? (
            <MultiValue values={salaries.map(({region}) => getCountryName(region, intl))} />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 82,
        id: UserFullInfoColumn.SALARY_CR,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_CR])}</span>,
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_CR]),
        render: (record) => (
          <SalaryCrValue
            record={record}
            renderValue={(cr) => {
              if (!cr) {
                return null;
              }

              return cr.value ? (
                <CrLabel cr={cr} />
              ) : (
                <TooltipFaq content={<CrValue cr={cr} withHint={false} />}>
                  <WarningIcon className={styles.icon} />
                </TooltipFaq>
              );
            }}
          />
        ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 113,
        align: 'right',
        id: UserFullInfoColumn.SALARY_RANGE_MINIMUM,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_RANGE_MINIMUM])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_RANGE_MINIMUM]),
        render: (record) => (
          <SalaryRangeValue
            record={record}
            renderValue={(salaryRange) => salaryRange && intl.formatNumber(salaryRange.minAmount)}
          />
        ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 111,
        align: 'right',
        id: UserFullInfoColumn.SALARY_RANGE_TARGET,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_RANGE_TARGET])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_RANGE_TARGET]),
        render: (record) => (
          <SalaryRangeValue
            record={record}
            renderValue={(salaryRange) => salaryRange && intl.formatNumber(salaryRange.targetAmount)}
          />
        ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 116,
        align: 'right',
        id: UserFullInfoColumn.SALARY_RANGE_MAXIMUM,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_RANGE_MAXIMUM])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_RANGE_MAXIMUM]),
        render: (record) => (
          <SalaryRangeValue
            record={record}
            renderValue={(salaryRange) => salaryRange && intl.formatNumber(salaryRange.maxAmount)}
          />
        ),
        hideable: true,
        sortable: true,
      },
      {
        defaultWidth: 227,
        id: UserFullInfoColumn.SALARY_RANGE_COMMENT,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_RANGE_COMMENT])}
          </span>
        ),
        render: (record) => (
          <SalaryRangeValue
            record={record}
            renderValue={(salaryRange) =>
              salaryRange?.comment && <ClampedText text={salaryRange.comment} maxLines={1} />
            }
          />
        ),
        hideable: true,
      },
      {
        defaultWidth: 95,
        id: UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK]),
        render: (record) => (
          <SalaryRangeValue
            record={record}
            renderValue={(salaryRange) => {
              if (!salaryRange) {
                return null;
              }

              const url = onlySubordinates ? managerConsoleUrls.salaryRanges : hrConsoleUrls.currentSalaryRanges;
              return (
                <ExternalLink
                  kind='primary'
                  href={url({
                    region: salaryRange.region,
                    userFunction: salaryRange.userFunction,
                  })}
                >
                  <ExternalLinkIcon />
                </ExternalLink>
              );
            }}
          />
        ),
        hideable: true,
      },
      {
        defaultWidth: 118,
        id: UserFullInfoColumn.REGULAR_BONUS_PERIOD,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.REGULAR_BONUS_PERIOD])}
          </span>
        ),
        render: ({activeRegularBonusRecords}) => {
          return activeRegularBonusRecords && activeRegularBonusRecords.length > 0 ? (
            <MultiValue
              values={activeRegularBonusRecords.map(({startDate, endDate}) =>
                endDate ? (
                  <HumanDateRange range={{from: startDate, to: endDate}} format='short' utc />
                ) : (
                  <HumanDate format='short' value={startDate} utc />
                ),
              )}
            />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 197,
        id: UserFullInfoColumn.REGULAR_BONUS,
        name: <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.REGULAR_BONUS])}</span>,
        render: ({activeRegularBonusRecords}) => {
          return activeRegularBonusRecords && activeRegularBonusRecords.length > 0 ? (
            <MultiValue
              values={activeRegularBonusRecords.map(({comment, ...bonus}) => (
                <FormattedRegularBonus {...bonus} />
              ))}
            />
          ) : (
            <DataTable.CellEmpty />
          );
        },
        hideable: true,
      },
      {
        defaultWidth: 215,
        id: UserFullInfoColumn.JOB_CONTRACT_LEGAL_ENTITY,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_LEGAL_ENTITY])}
          </span>
        ),
        render: ({contracts}) => {
          const titles = contracts?.map(({contract}) => contract?.legalEntity.title).filter(Boolean) || [];
          return titles.length > 0 ? <MultiValue values={titles.map((title) => title)} /> : <DataTable.CellEmpty />;
        },
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 90,
        id: UserFullInfoColumn.JOB_CONTRACT_CONTRACT_TYPE,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_CONTRACT_TYPE])}
          </span>
        ),
        render: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <MultiValue
              values={contracts.map((contract) => {
                const contractType = getActiveJobContractRecord(contract)?.contractType;
                return contractType ? formatContractType(contractType, intl) : <DataTable.CellEmpty />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 102,
        id: UserFullInfoColumn.JOB_CONTRACT_EFFECTIVE_DATE,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_EFFECTIVE_DATE])}
          </span>
        ),
        render: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <MultiValue
              values={contracts.map(({contract}) => {
                const date = contract?.jobContractRecords.at(-1)?.effectiveDate;
                return date ? <HumanDate format='short' value={date} utc /> : <DataTable.CellEmpty />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 96,
        id: UserFullInfoColumn.JOB_CONTRACT_STATUS,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_STATUS])}
          </span>
        ),
        render: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <MultiValue
              values={contracts.map((contract) => {
                const status = getActiveJobContractRecord(contract)?.status;
                return status ? <JobContractStatusBadge status={status} /> : <DataTable.CellEmpty />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 270,
        id: UserFullInfoColumn.JOB_CONTRACT_COMMENT,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_COMMENT])}
          </span>
        ),
        render: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <MultiValue
              values={contracts.map((contract) => {
                const comment = getActiveJobContractRecord(contract)?.comment;
                return comment ? <ClampedText text={comment} maxLines={1} /> : <DataTable.CellEmpty />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 105,
        id: UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY])}
          </span>
        ),
        description: intl.formatMessage(hints[UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY]),
        render: ({contracts}) =>
          contracts && contracts.length > 0 ? (
            <MultiValue
              values={contracts.map((contract) => {
                const policy = getActiveJobContractRecord(contract)?.countryOfTimeOffPolicy;
                return policy ? formatTimeOffPolicy(policy, intl) : <DataTable.CellEmpty />;
              })}
            />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 120,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_GRANTED_TOTAL,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_GRANTED_TOTAL])}
          </span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? (
            <FormattedOptionsAmount amount={stockOptions.summary.granted.total} />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 95,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_GRANTED_HISTORIC,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_GRANTED_HISTORIC])}
          </span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? (
            <FormattedOptionsAmount amount={stockOptions.summary.granted.byType.historic} />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 95,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_GRANTED_GENERAL,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_GRANTED_GENERAL])}
          </span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? (
            <FormattedOptionsAmount amount={stockOptions.summary.granted.byType.general} />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 95,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_GRANTED_PERFORMANCE,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_GRANTED_PERFORMANCE])}
          </span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? (
            <FormattedOptionsAmount amount={stockOptions.summary.granted.byType.performance} />
          ) : (
            <DataTable.CellEmpty />
          ),
        hideable: true,
        defaultHidden: true,
      },
      {
        defaultWidth: 95,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_VESTED,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_VESTED])}</span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? <FormattedOptionsAmount amount={stockOptions.summary.vested} /> : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 100,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_AVAILABLE,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_AVAILABLE])}</span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? <FormattedOptionsAmount amount={stockOptions.summary.available} /> : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 105,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_NEXT_12_MONTHS,
        name: (
          <span className={styles.title}>
            {intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_NEXT_12_MONTHS])}
          </span>
        ),
        render: ({stockOptions}) =>
          stockOptions ? <FormattedOptionsAmount amount={stockOptions.summary.nextYear} /> : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
      {
        defaultWidth: 130,
        align: 'right',
        id: UserFullInfoColumn.OPTIONS_AGREEMENTS,
        name: (
          <span className={styles.title}>{intl.formatMessage(fieldLabels[UserFullInfoColumn.OPTIONS_AGREEMENTS])}</span>
        ),
        render: ({stockOptions}) =>
          stockOptions?.agreements.length ? intl.formatNumber(stockOptions.agreements.length) : <DataTable.CellEmpty />,
        hideable: true,
        defaultHidden: true,
        sortable: true,
      },
    ];

    return allColumns
      .map(makeColumnLockable)
      .map((column) => makeColumnSensitive(column, isSensitiveDataHidden))
      .filter(({id}) => availableColumns.some(({key}) => key === id));
  }, [intl, availableColumns, isSensitiveDataHidden, showMonthlySalaries, onlySubordinates]);

  return (
    <DataTable
      columns={columns}
      dataState={DataState.LOADED}
      data={data}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='userFullInfoRecords.list'
      sorting={sorting}
      onSort={onSort}
      onVisibleColumnsChange={onVisibleColumnsChange}
      columnTreeOptions={{
        items: availableColumns,
        getItemChildren: (item: UserFullInfoColumnTreeItem) => childrenByColumnId[item.key],
        getItemParent: (item: UserFullInfoColumnTreeItem) => parentByColumnId[item.key],
        getItemKey: (item: UserFullInfoColumnTreeItem) => item.key,
        renderItem: (item: UserFullInfoColumnTreeItem) => intl.formatMessage(fieldLabels[item.key]),
      }}
    />
  );
}
