import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import cn from 'classnames';
import {Tree} from 'components/ui/Tree';
import {User, UserId} from 'domain/user/model';
import {UserContact} from 'domain/user/widgets/Contact';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {TreeItemViewState} from 'hooks/useTreeView/types';
import React, {KeyboardEventHandler, MouseEventHandler, useCallback, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import styles from '../styles.css';

type Props = React.PropsWithChildren<{
  depth: number;
  viewItem: TreeItemViewState<UserId>;
  highlightSearch: (s: string) => React.ReactNode;
  selectedUserId?: UserId;
  user: User;
  onArrowClick(): void;
  linkToUserPane: (user: User) => string;
}>;

export function UserTreeItem({
  children,
  depth,
  highlightSearch,
  viewItem,
  selectedUserId,
  user,
  onArrowClick,
  linkToUserPane,
}: Props) {
  const history = useHistory();
  const usersnapApi = useUsersnapApi();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const selected = user.id === selectedUserId;
  const {expanded, descendantsCount} = viewItem;

  useEffect(() => {
    if (!selected) {
      return;
    }
    requestAnimationFrame(() => {
      itemRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    });
  }, [selected]);

  const openUserPane = useCallback(() => {
    history.push(linkToUserPane(user));
    usersnapApi?.logEvent(UserSnapEvents.USE_USER_TREE);
  }, [user]);

  const onRowClick: MouseEventHandler = useCallback(
    (event) => {
      const el = event.target;
      if (
        (el instanceof HTMLElement || el instanceof SVGElement) &&
        (el.tagName === 'A' || el.tagName === 'BUTTON' || el.closest('a, button'))
      ) {
        // Don't click through links and buttons
        return;
      }
      openUserPane();
    },
    [openUserPane],
  );

  const onRowKeypress: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.target === document.activeElement && event.key === Key.ENTER) {
        openUserPane();
      }
    },
    [openUserPane],
  );

  return (
    <Tree.Item
      content={
        <>
          <div className={styles.user}>
            <ListedUser
              highlightSearch={highlightSearch}
              user={user}
              subordinateCount={descendantsCount}
              withSalaryManagerIcon
            />
          </div>
          <div className={cn(styles.contact, styles.leftColumn)}>
            <UserContact highlightSearch={highlightSearch} type='slack' user={user} />
            <UserContact highlightSearch={highlightSearch} type='github' user={user} />
          </div>
          <div className={cn(styles.contact, styles.rightColumn)}>
            <UserContact highlightSearch={highlightSearch} type='email' user={user} />
          </div>
        </>
      }
      depth={depth}
      expanded={expanded}
      onClick={onRowClick}
      onKeyPress={onRowKeypress}
      onArrowClick={onArrowClick}
      ref={itemRef}
      selected={selected}
    >
      {children}
    </Tree.Item>
  );
}
