import {HrConsoleRedirect} from 'domain/hrConsole/widgets/Redirect';
import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {withVpn} from 'hocs/withVpn';
import {RegularLayout} from 'layouts/Regular';
import {HrConsoleCompensationReviewRequestPage} from 'pages/HrConsoleCompensationReviewRequest';
import {HrConsoleCompensationReviewRequestCreatePage} from 'pages/HrConsoleCompensationReviewRequestCreate';
import {HrConsoleCompensationReviewRequestsPage} from 'pages/HrConsoleCompensationReviewRequests';
import {HrConsoleManagementPage} from 'pages/HrConsoleManagement';
import {HrConsoleSalaryRangesPage} from 'pages/HrConsoleSalaryRanges';
import {HrConsoleUsersPage} from 'pages/HrConsoleUsers';
import {NotFoundPage} from 'pages/NotFound';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {hrConsoleUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: hrConsoleUrlPatterns.root,
  component: withVpn(withAuth(RegularLayout), NotFoundPage),
  routes: [
    {
      component: HrConsoleRedirect,
      exact: true,
      path: hrConsoleUrlPatterns.root,
    },
    {
      component: HrConsoleUsersPage,
      exact: true,
      path: hrConsoleUrlPatterns.users,
    },
    {
      component: HrConsoleSalaryRangesPage,
      exact: true,
      path: hrConsoleUrlPatterns.currentSalaryRanges,
    },
    {
      component: withSecurePermission(HrConsoleManagementPage, SecurePermission.SALARY_RANGE_MANAGE),
      exact: true,
      path: hrConsoleUrlPatterns.management,
    },
    {
      component: HrConsoleCompensationReviewRequestsPage,
      exact: true,
      path: hrConsoleUrlPatterns.compensationReviewRequests,
    },
    {
      component: HrConsoleCompensationReviewRequestCreatePage,
      exact: true,
      path: hrConsoleUrlPatterns.compensationReviewRequestCreate,
    },
    {
      component: HrConsoleCompensationReviewRequestPage,
      exact: true,
      path: hrConsoleUrlPatterns.compensationReviewRequest,
    },
    notFoundRouteConfig,
  ],
};
