import {defineMessages} from 'react-intl';
import {RotationValidatorError} from './validate';

export const messages = defineMessages({
  addButton: {
    defaultMessage: 'Add a shift',
    description: 'Field rotation: button label',
  },
});

export const errorMessages = defineMessages({
  [RotationValidatorError.PARTIALLY_FILLED]: {
    defaultMessage: 'Some of the shifts are not filled',
    description: 'Field rotation: error message',
  },
});
