import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Select a duty for the shift',
    description: 'Field rotation: input placeholder',
  },
  removeButton: {
    defaultMessage: 'Remove the shift',
    description: 'Field rotation: button label',
  },
});
