import {DataState} from '@joomcode/deprecated-utils/dataState';
import {isNullish} from '@joomcode/deprecated-utils/function';
import React from 'react';
import {Placeholder as JoomUiPlaceholder} from '../Placeholder';
import {Preloader} from '../Preloader';
import styles from './index.css';

export type Props<Data> = {
  state: DataState;
  data: Data | null | undefined;
  children: (data: Data) => React.ReactNode;
  showDataWhileFailed?: boolean;
  showDataWhileLoading?: boolean;
  renderPlaceholder?: () => React.ReactNode;
  renderError?: () => React.ReactNode;
};

function Placeholder() {
  return (
    <div className={styles.loader}>
      <Preloader />
    </div>
  );
}

export function StateHandler<Data>({
  state,
  data,
  children,
  showDataWhileFailed,
  showDataWhileLoading,
  renderPlaceholder = () => <Placeholder />,
  renderError = () => <JoomUiPlaceholder.Error />,
}: Props<Data>) {
  if (state === DataState.IDLE) {
    return null;
  }

  if (state === DataState.LOADING && !(showDataWhileLoading && data)) {
    return <>{renderPlaceholder()}</>;
  }

  if (isNullish(data) || (!showDataWhileFailed && state === DataState.FAILED)) {
    return <>{renderError()}</>;
  }

  return <>{children(data)}</>;
}

StateHandler.Placeholder = Placeholder;
