import {Item} from '@joomcode/joom-ui/Timeline';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {Override} from '../Override';
import {Tooltip} from '../Tooltip';
import {type TimelineGroupRender} from '../types';
import {getScheduleIssueTooltipItems} from '../utils/getScheduleItemTooltipItems';

type Props = {
  canCreateOverrides?: boolean;
  canDeleteOverrides?: boolean;
  dutyId?: DutyId;
  teamId: TeamId;
};

export function useOverrideRender(props: Props) {
  const intl = useIntl();

  return useCallback<TimelineGroupRender<'override'>>(
    (interval) => {
      const users = interval.items.flatMap((item) => item.data.slot.users);
      const tooltipItems = getScheduleIssueTooltipItems({interval, users, intl, isOverride: true, ...props});
      const isNew = interval.items.some((item) => item.data.id === undefined);

      return (
        <Tooltip items={tooltipItems}>
          <Item interval={interval}>
            <Override isNew={isNew} />
          </Item>
        </Tooltip>
      );
    },
    [intl],
  );
}
