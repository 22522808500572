import {StyledExternalLink} from 'components/ui/StyledLink';
import React from 'react';
import {ReactComponent as GoogleCalendarIcon} from './icons/googleCalendar.svg';
import styles from './styles.css';

type Props = {
  link: string;
};

export function DutyCalendarLink({link}: Props) {
  return (
    <StyledExternalLink className={styles.root} href={link}>
      <GoogleCalendarIcon />
    </StyledExternalLink>
  );
}
