import {Button} from '@joomcode/joom-ui/Button';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import type {DutyTimelineSize} from '../../types';
import {messages} from './messages';

type PropsType = {
  size: DutyTimelineSize;
  selected: boolean;
  onClick: (size: DutyTimelineSize) => void;
};

export function SizeButton({size, selected, onClick}: PropsType) {
  const intl = useIntl();
  const caption = intl.formatMessage(messages[size]);

  const handleClick = useCallback(() => {
    if (!selected) {
      onClick(size);
    }
  }, [onClick, selected]);

  return (
    <Button onClick={handleClick} intent={selected ? 'primary' : 'neutral'} kind='primary'>
      {caption}
    </Button>
  );
}
