import {defineMessages, type MessageDescriptor} from 'react-intl';
import type {DutyTimelineSize} from '../../types';

export const messages = defineMessages({
  week: {
    defaultMessage: 'Week',
    description: 'DutyTimeline: size button',
  },
  month: {
    defaultMessage: 'Month',
    description: 'DutyTimeline: size button',
  },
  quarter: {
    defaultMessage: '3 Months',
    description: 'DutyTimeline: size button',
  },
}) as Record<DutyTimelineSize, MessageDescriptor>;
