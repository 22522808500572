import {HintIcon} from 'components/ui/HintIcon';
import {StyledExternalLink} from 'components/ui/StyledLink';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.css';
import {messages} from './messages';

const JDM_INBOX_LINK = 'https://joom-team.slack.com/archives/C05527NNQ9H';

export function ResourceHintIcon() {
  const intl = useIntl();

  return (
    <HintIcon>
      {intl.formatMessage(messages.hint, {
        link: (text) => (
          <StyledExternalLink colored href={JDM_INBOX_LINK} className={styles.link}>
            {text}
          </StyledExternalLink>
        ),
      })}
    </HintIcon>
  );
}
