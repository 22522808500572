import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Page} from 'components/widgets/Page';
import {useCompensationReviewRequestCreateAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestCreateAccess';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {NOTION_GUIDE} from 'domain/compensationReviewRequest/model/notionGuide';
import {CompensationReviewRequestsList} from 'domain/compensationReviewRequest/widgets/List';
import {useManagerConsolePageTabs} from 'domain/managerConsole/hooks/useManagerConsolePageTabs';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {ManagerConsolePageTabs} from 'domain/managerConsole/widgets/PageTabs';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/managerConsole/titles';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {messages} from './messages';

export function ManagerConsoleCompensationReviewRequestsPage() {
  const intl = useIntl();
  const {showTabs, availableTabs} = useManagerConsolePageTabs();
  const readAccess = useCompensationReviewRequestReadAccess();
  const createAccess = useCompensationReviewRequestCreateAccess();
  const usersnapApi = useUsersnapApi();

  useEffect(() => {
    usersnapApi?.logEvent(UserSnapEvents.MANAGER_CONSOLE_COMPENSATION_REVIEW_OPEN);
  }, [usersnapApi]);

  if (!readAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.managerConsole)}
      actions={
        createAccess && (
          <RouterLinkButton
            to={managerConsoleUrls.compensationReviewRequestCreate()}
            size='m'
            kind='primary'
            intent='primary'
            type='button'
          >
            {intl.formatMessage(messages.createButton)}
          </RouterLinkButton>
        )
      }
      headerContent={
        showTabs && (
          <ManagerConsolePageTabs
            availableTabs={availableTabs}
            activeTab={ManagerConsoleTab.COMPENSATION_REVIEW_REQUESTS}
          />
        )
      }
      notionGuide={NOTION_GUIDE}
    >
      <CompensationReviewRequestsList onlySubordinates />
    </Page>
  );
}
