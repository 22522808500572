import React, {Fragment, type Key} from 'react';
import {useTimeline} from '../Timeline/Provider';
import {Item} from '../Item';
import {Sticky} from '../Sticky';
import {type GroupId, type HeadingGroupProps} from '../types';
import styles from './HeadingGroup.css';
import {useGroupIntervals} from '../hooks/useGroupIntervals';

export function HeadingGroup<T extends Record<GroupId, unknown>>(props: HeadingGroupProps<T>) {
  const context = useTimeline();
  const {group} = props;

  const items = useGroupIntervals(props.items);
  const children = items.map((item) => {
    if (group.render) {
      return <Fragment key={item.start}>{group.render(item, context)}</Fragment>;
    }
    return <Item key={item.start} interval={item} />;
  });

  return (
    <article className={styles.HeadingGroup} key={group.id as Key}>
      <header className={styles.header}>
        <Sticky>
          <span className={styles.text}>{group.title}</span>
        </Sticky>
      </header>
      <div className={styles.items}>{children}</div>
    </article>
  );
}
