import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {JobInfoReadAccess} from 'domain/jobInfoRecord/model/access';
import {getMyRecordsFx, getRecordsByUserIdFx} from 'domain/jobInfoRecord/stores/main';
import {$jobInfoRecords} from 'domain/jobInfoRecord/stores/main/state';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';
import {useJobInfoReadAccess} from './useJobInfoReadAccess';

type ReturnShape = {
  data: JobInfoRecord[];
  dataState: DataState;
};

export function useUsersJobInfoRecords(user: UserFull): ReturnShape {
  const readAccess = useJobInfoReadAccess(user);

  const [data, dataState] = useStoreMap({
    store: $jobInfoRecords,
    keys: [user.id],
    fn: ({byUserId, dataStateByUserId}, [id]) => [byUserId[id] || [], dataStateByUserId[id] || DataState.IDLE],
  });

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      (readAccess === JobInfoReadAccess.ANY ? getRecordsByUserIdFx : getMyRecordsFx)(user.id).catch(
        toaster.interceptThenThrowError,
      );
    }
  }, [readAccess, user.id]);

  return {data, dataState};
}
