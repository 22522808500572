import {CompensationReviewRequestCreateAccess} from 'domain/compensationReviewRequest/model/access';
import {SecurePermission} from 'domain/permission/model/secure';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';

export function useCompensationReviewRequestCreateAccess(): CompensationReviewRequestCreateAccess | null {
  const acl = useAcl();
  const compensationReviewRequestFeature = useFeature(Feature.COMPENSATION_REVIEW_REQUEST);

  if (!compensationReviewRequestFeature.isAvailable) {
    return null;
  }

  if (acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_ANY)) {
    return CompensationReviewRequestCreateAccess.ANY;
  }

  if (acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE_SUBORDINATE)) {
    return CompensationReviewRequestCreateAccess.SUBORDINATE;
  }

  return null;
}
