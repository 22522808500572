import {JobInfoReadAccess} from 'domain/jobInfoRecord/model/access';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useJobInfoReadAccess(user: UserFull): JobInfoReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const canReadAny = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_READ);

  if (!user) {
    return null;
  }

  if (canReadAny) {
    return JobInfoReadAccess.ANY;
  }
  if (user.id === selfUserId) {
    return JobInfoReadAccess.MY;
  }

  return null;
}
