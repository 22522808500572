import {Interval, DateInterval, type IntervalGroup} from '../types';
import {getTimestamp} from './date';

/**
 *
 * @param init Date interval or interval
 * @param roundBy Enables aproximation to the nearest multiple of roundBy.
 * @returns Interval. With provided `roundBy` will be not less than `roundBy`.
 */
export function createInterval(init: DateInterval | Interval, roundBy = 1): Interval {
  let start = getTimestamp(init.start);
  let end = getTimestamp(init.end);

  if (roundBy !== 1) {
    start = Math.round(start / roundBy) * roundBy;
    end = Math.round(end / roundBy) * roundBy;

    if (start === end) {
      end += roundBy;
    }
  }

  return {
    start,
    end,
  };
}

export function hasOverlap(a: Interval, b: Interval): boolean {
  return a.start < b.end && a.end > b.start;
}

export function isBoundedBy(interval: Interval, boundary: Interval): boolean {
  return interval.start <= boundary.end && interval.end >= boundary.start;
}

export function createNonOverlappingIntervals<T extends DateInterval>(
  intervals: T[],
  roundBy?: number,
): IntervalGroup<T>[] {
  if (intervals.length === 0) return [];

  // Extract all unique time points (start and end of every interval)
  const pointsSet: Set<number> = new Set();
  const intervalsTimestamps = intervals.map((init) => {
    const interval = createInterval(init, roundBy);
    pointsSet.add(interval.start);
    pointsSet.add(interval.end);
    return {interval, original: init};
  });

  // Sort the unique time points
  const uniquePoints = Array.from(pointsSet).sort((a, b) => a - b);

  // Create non-overlapping intervals between these points
  const groups: IntervalGroup<T>[] = [];
  for (let i = 0; i < uniquePoints.length - 1; i++) {
    const intevalGroup: IntervalGroup<T> = {start: uniquePoints[i], end: uniquePoints[i + 1], items: []};

    // Collect intervals that overlap with this time segment
    intervalsTimestamps.forEach(({interval, original}) => {
      if (hasOverlap(intevalGroup, interval)) {
        intevalGroup.items.push(original);
      }
    });

    if (intevalGroup.items.length > 0) {
      groups.push(intevalGroup);
    }
  }

  return groups;
}
