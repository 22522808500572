import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {SlackUserGroup} from 'domain/duty/model/slackUserGroup';
import {getVacantSlackUserGroupsFx} from 'domain/duty/stores/slackUserGroups';
import {$slackUserGroups} from 'domain/duty/stores/slackUserGroups/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: SlackUserGroup[];
  dataState: DataState;
};

export function useSlackUserGroups(): ReturnShape {
  const {data, dataState} = useStore($slackUserGroups);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getVacantSlackUserGroupsFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {
    data,
    dataState,
  };
}
