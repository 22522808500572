import {Item} from '@joomcode/joom-ui/Timeline';
import {DutyId} from 'domain/duty/model/lite';
import {TeamId} from 'domain/team/model/id';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {Duties} from '../Duties';
import {Tooltip} from '../Tooltip';
import {type TimelineGroupRender} from '../types';
import {getScheduleIssueTooltipItems} from '../utils/getScheduleItemTooltipItems';

type Props = {
  canCreateOverrides?: boolean;
  canDeleteOverrides?: boolean;
  dutyId?: DutyId;
  teamId: TeamId;
};

export function useDutyRender(props: Props) {
  const intl = useIntl();

  return useCallback<TimelineGroupRender<'duty'>>(
    (interval) => {
      const users = interval.items.flatMap((item) => item.data.slot.users);
      const tooltipItems = getScheduleIssueTooltipItems({interval, users, intl, ...props});

      return (
        <Tooltip items={tooltipItems}>
          <Item interval={interval}>
            <Duties users={users} />
          </Item>
        </Tooltip>
      );
    },
    [intl],
  );
}
