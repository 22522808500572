import {array, ExtractSchemaType, intersection, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';
import {dutyLiteSchema} from './lite';
import {rotationSettingsSchema} from './rotatationSettings';
import {scheduleSchema} from './schedule';
import {slackUserGroupSchema} from './slackUserGroup';

export const dutySchema = intersection(
  dutyLiteSchema,
  object({
    calendarLink: string(),
    description: string(),
    owner: userSchema,
    rotationSettings: rotationSettingsSchema,
    schedule: scheduleSchema,
    slackUserGroups: array(slackUserGroupSchema),
  }),
);

export type Duty = ExtractSchemaType<typeof dutySchema>;
