import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {DutyId} from 'domain/duty/model/lite';
import {deactivateDutyFx} from 'domain/duty/stores/main';
import {TeamId} from 'domain/team/model/id';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  dutyId: DutyId;
  teamId: TeamId;
};

export function DutyDeleteButton({dutyId, teamId}: Props) {
  const intl = useIntl();
  const handleClick = useCallback(() => {
    confirm(
      {
        title: intl.formatMessage(messages.confirmationTitle),
        text: intl.formatMessage(messages.confirmationText),
        confirmationText: intl.formatMessage(messages.confirmationButton),
        intent: 'negative',
        onConfirm: () =>
          deactivateDutyFx({id: dutyId, teamId})
            .then(() => toaster.success(intl.formatMessage(messages.success)))
            .catch(toaster.interceptThenThrowError),
      },
      intl,
    );
  }, [intl, dutyId, teamId]);

  return (
    <Button size='m' kind='primary' intent='negative' onClick={handleClick}>
      {intl.formatMessage(messages.button)}
    </Button>
  );
}
