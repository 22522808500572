import {useCallback} from 'react';
import {useTimeline} from '../Timeline/Provider';
import {type DateLike} from '../types';
import {getTimestamp} from '../utils';

export function useGetDatePosition() {
  const {
    boundary: {start},
    msToPixelRatio,
  } = useTimeline();

  return useCallback(
    (dateLike: DateLike) => {
      const ms = getTimestamp(dateLike) - start;
      return ms * msToPixelRatio;
    },
    [start, msToPixelRatio],
  );
}
