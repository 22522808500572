import {type Duration} from 'date-fns';
import {DAY, HOUR} from '@joomcode/deprecated-utils/time';
import {TimelineUnit} from '../types';
import {day, week, type TimelineUnitDateFns} from './date';

export const DEFAULT_GROUP_HEIGHT = 30;

export const DEFAULT_ITEM_COLOR = 'var(--joom-global-color-blue-700)';

export const DEFAULT_ITEM_GAP = 1;

export const unitDateFns: Record<TimelineUnit, TimelineUnitDateFns> = {
  [TimelineUnit.DAY]: day,
  [TimelineUnit.DAY_NARROW]: day,
  [TimelineUnit.WEEK]: week,
};

export const unitWidth: Record<TimelineUnit, number> = {
  [TimelineUnit.DAY]: 164,
  [TimelineUnit.DAY_NARROW]: 38,
  [TimelineUnit.WEEK]: 83,
};

export const unitMs: Record<TimelineUnit, number> = {
  [TimelineUnit.DAY]: DAY,
  [TimelineUnit.DAY_NARROW]: DAY,
  [TimelineUnit.WEEK]: DAY * 7,
};

export const unitRoundBy: Record<TimelineUnit, number> = {
  [TimelineUnit.DAY]: HOUR,
  [TimelineUnit.DAY_NARROW]: HOUR * 4,
  [TimelineUnit.WEEK]: HOUR * 12,
};

export const unitTimelineSize: Record<TimelineUnit, Duration> = {
  [TimelineUnit.DAY]: {
    weeks: 1,
  },
  [TimelineUnit.DAY_NARROW]: {
    months: 1,
  },
  [TimelineUnit.WEEK]: {
    months: 3,
  },
};
