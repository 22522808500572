import React, {ForwardedRef, forwardRef, memo, useLayoutEffect, useMemo} from 'react';
import {areEqual, FixedSizeList, ListOnScrollProps} from 'react-window';
import {DataTableVirtualizationOptions} from '../../types';
import {ListProps} from './types';
import {ManualScrollWrapper, ScrollWrapper} from './ScrollWrapper';
import {InnerTable} from './InnerTable';

type RowData<Item> = {
  renderRow: ListProps<Item>['renderRow'];
  items: Item[];
};

type RowProps<Item> = {
  index: number;
  style: React.CSSProperties;
  data: RowData<Item>;
};

const VirtualizedRow = memo(function VirtualizedRow<Item>({index, style, data: {renderRow, items}}: RowProps<Item>) {
  return renderRow(items[index], {...style, width: 'auto'});
}, areEqual);

type Props<Item> = ListProps<Item> & {virtualization: DataTableVirtualizationOptions};

export const VirtualizedList = forwardRef(function VirtualizedList<Item>(
  {items, renderRow, getRowKey, virtualization: {rowHeight, maxVisibleRows, manual}}: Props<Item>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const componentRef = React.useRef<FixedSizeList>(null);
  const height = Math.min(items.length, maxVisibleRows) * rowHeight;
  const itemData: RowData<Item> = useMemo(() => ({renderRow, items}), [renderRow, items]);

  useLayoutEffect(() => {
    if (manual && componentRef.current) {
      componentRef.current.setState(
        (prevState: ListOnScrollProps): Partial<ListOnScrollProps> => ({
          scrollOffset: manual?.scrollOffset,
          scrollDirection: prevState.scrollOffset < manual?.scrollOffset ? 'forward' : 'backward',
        }),
      );
    }
  }, [manual?.scrollOffset]);

  return (
    <FixedSizeList<RowData<Item>>
      ref={componentRef}
      width='auto'
      outerRef={ref}
      outerElementType={manual ? ManualScrollWrapper : ScrollWrapper}
      innerElementType={InnerTable}
      height={height}
      itemCount={items.length}
      itemSize={rowHeight}
      itemKey={(index) => getRowKey(items[index])}
      itemData={itemData}
      overscanCount={Math.floor(maxVisibleRows / 2)}
      useIsScrolling={false}
    >
      {VirtualizedRow}
    </FixedSizeList>
  );
});
