import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as OwnerIcon} from './icons/owner.svg';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  owner: User;
};

export function DutyOwnerIcon({owner}: Props) {
  const intl = useIntl();

  return (
    <TooltipFaq
      content={intl.formatMessage(messages.owner, {owner: <UserLink user={owner} colored withAvatar={false} />})}
    >
      <div className={styles.icon}>
        <OwnerIcon />
      </div>
    </TooltipFaq>
  );
}
