import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {AddRecordButton} from 'domain/jobContract/widgets/AddRecordButton';
import {JobContractOneTimeBonusTab} from 'domain/jobContract/widgets/OneTimeBonusTab';
import {JobContractPanelTab} from 'domain/jobContract/widgets/Panel';
import {LegalEntityId} from 'domain/legalEntity/model';
import {useOneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/hooks/useOneTimeBonusReadAccess';
import {useUserOneTimeBonusRecords} from 'domain/oneTimeBonusRecord/hooks/useUserOneTimeBonusRecords';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/model/access';
import {OneTimeBonusRecordDialog} from 'domain/oneTimeBonusRecord/widgets/Dialog';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserFull} from 'domain/user/model';
import React, {useMemo} from 'react';
import {useAcl} from 'services/acl';
import {filterOutFutureRecords} from 'utils/record';

type Props = {
  user: UserFull;
  legalEntityId: LegalEntityId;
  isSensitiveDataHidden: boolean;
};

export function useOneTimeBonusTab({
  user,
  legalEntityId,
  isSensitiveDataHidden,
}: Props): JobContractPanelTab<OneTimeBonusRecord> {
  const acl = useAcl();
  const {data, dataState} = useUserOneTimeBonusRecords(user, legalEntityId);
  const readAccess = useOneTimeBonusReadAccess(user);
  const records = useMemo(
    () => (readAccess === OneTimeBonusReadAccess.MY ? filterOutFutureRecords(data) : data),
    [readAccess, data],
  );
  const oneTimeBonusRecordDialog = usePopupState<OneTimeBonusRecord>();
  const canEdit = acl.hasSecurePermission(SecurePermission.ONE_TIME_BONUS_RECORD_WRITE);

  const result = useMemo(
    () => ({
      addon: canEdit ? <AddRecordButton onClick={oneTimeBonusRecordDialog.open} /> : null,
      content: (
        <JobContractOneTimeBonusTab
          userId={user.id}
          legalEntityId={legalEntityId}
          records={records}
          dataState={dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={oneTimeBonusRecordDialog.openWithPayload}
        />
      ),
      dialog: canEdit ? (
        <OneTimeBonusRecordDialog
          userId={user.id}
          legalEntityId={legalEntityId}
          recordToUpdate={oneTimeBonusRecordDialog.payload}
          isOpen={oneTimeBonusRecordDialog.isOpen}
          onClose={oneTimeBonusRecordDialog.close}
        />
      ) : null,
      records,
      dataState,
    }),
    [records, dataState, isSensitiveDataHidden, oneTimeBonusRecordDialog, canEdit, user.id, legalEntityId],
  );

  return result;
}
