import {TimelineUnit, useTimeline} from '@joomcode/joom-ui/Timeline';
import cn from 'classnames';
import {User} from 'domain/user/model';
import React from 'react';
import {Duty} from './Duty';
import styles from './styles.css';

type PropsType = {
  ghost?: boolean;
  users: User[];
};

export function Duties({ghost, users}: PropsType) {
  const {unit} = useTimeline();

  if (!users[0]) {
    return null;
  }

  const props = {
    ghost,
    short: unit === TimelineUnit.WEEK,
  };

  return (
    <div className={cn(styles.Duties, {[styles.ghost]: ghost})}>
      <div className={styles.content}>
        <Duty user={users[0]} plus={users.length - 1} {...props} />
      </div>
    </div>
  );
}
