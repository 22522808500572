import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  scrollLeftCaption: {
    defaultMessage: 'Scroll left',
    description: 'DutyTimeline: scroll button',
  },
  scrollRightCaption: {
    defaultMessage: 'Scroll right',
    description: 'DutyTimeline: scroll button',
  },
});
