import cn from 'classnames';
import React from 'react';
import styles from './styles.css';

type Props = {
  isNew: boolean;
};

export function Override({isNew}: Props) {
  return <div className={cn(styles.root, {[styles.isNew]: isNew})} />;
}
