import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {useMemo} from 'react';
import {Schema} from '../types';

export function useDutyItems<T extends keyof Schema>(group: T, schedule: ScheduleItem[]) {
  return useMemo(
    () =>
      schedule.map((item) => ({
        group,
        start: item.startDate,
        end: item.endDate,
        data: item,
      })) ?? [],
    [schedule, group],
  );
}
