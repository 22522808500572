import {Tree} from 'components/ui/Tree';
import {UserMemberships} from 'domain/user/model/memberships';
import {UserMembershipTreeItem} from 'domain/user/widgets/MembershipTreeItem';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import styles from './styles.css';

type Props = {
  memberships: UserMemberships;
};

export function UserMembershipList({memberships}: Props) {
  const sortedMemberships: UserMemberships = useMemo(
    () =>
      sortBy(
        memberships,
        ({teamAncestors}) => teamAncestors.length, // sort memberships by team depth
      ),
    [memberships],
  );

  const membershipsByParentId = useMemo(
    () =>
      sortedMemberships.reduce((result, membership) => {
        const parentId = membership.ancestorTeamForCollapse?.id;
        const currentMemberships = result.get(parentId) || [];

        return result.set(parentId, [...currentMemberships, membership]);
      }, new Map<string | undefined, UserMemberships>()),
    [sortedMemberships],
  );

  const rootMemberships = membershipsByParentId.get(undefined);

  return (
    <div className={styles.root}>
      <Tree noSeparators>
        {rootMemberships?.map((rootMembership) => (
          <UserMembershipTreeItem
            key={rootMembership.team.id}
            membership={rootMembership}
            membershipsByParentId={membershipsByParentId}
          />
        ))}
      </Tree>
    </div>
  );
}
