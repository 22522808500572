import {DutyDiff} from 'domain/duty/model/diff';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {checkRotationIsFilled} from './checkRotationIsFilled';

export function getDataForGeneratingSchedule(state: DutyDiff): RotationSettingsDiff | undefined {
  if (
    !state.rotationSettings ||
    !state.rotationSettings?.handoffMoment?.time ||
    state.rotationSettings?.handoffMoment?.weekday === undefined ||
    state.rotationSettings?.handoffMoment?.timeZone === undefined ||
    !state.rotationSettings?.effectiveDate ||
    !checkRotationIsFilled(state.rotationSettings?.rotation)
  ) {
    return undefined;
  }

  return state.rotationSettings;
}
