import {PageTabs} from 'components/ui/PageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {hrConsoleTabNames, hrConsoleTabNamesForManager} from 'domain/hrConsole/model/tabs/messages';
import {pathByTab} from 'domain/hrConsole/model/tabs/paths';
import {useUserFullInfoReadAccess} from 'domain/userFullInfoRecord/hooks/useReadAccess';
import {UserFullInfoReadAccess} from 'domain/userFullInfoRecord/model/access';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  availableTabs: HrConsoleTab[];
  activeTab: HrConsoleTab;
};

export function HrConsolePageTabs({availableTabs, activeTab}: Props) {
  const intl = useIntl();
  const userFullInfoReadAccess = useUserFullInfoReadAccess();
  const titles =
    userFullInfoReadAccess === UserFullInfoReadAccess.ANY
      ? hrConsoleTabNames
      : {...hrConsoleTabNames, ...hrConsoleTabNamesForManager};

  return (
    <PageTabs>
      {availableTabs.map((tab) => (
        <PageTabs.Tab
          title={intl.formatMessage(titles[tab])}
          path={pathByTab[tab]}
          active={activeTab === tab}
          key={tab}
        />
      ))}
    </PageTabs>
  );
}
