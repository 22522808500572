import * as Sentry from '@sentry/react';
import {config} from 'config';
import type {History} from 'history';

export const initSentry = (history: History) => {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: window.intranetEnv.APP_ENV,
    release: window.intranetEnv.BUILD_CALVER || '0.0.1',
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({history})],
    ignoreErrors: [
      // All errors that occur in scripts loaded from other domains.
      // @see https://sentry.io/answers/script-error/
      'Script error.',
    ],
    tracesSampleRate: 0.1,
  });
};
