import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {getConfigColumn} from '../columns/configColumn';
import {getSelectionColumn} from '../columns/selectionColumn';
import {
  Column,
  ColumnsConfig,
  ColumnsWidthMap,
  ColumnTreeOptions,
  SelectionType,
  SortableColumnOption,
  GetRowActions,
} from '../types';
import {
  computeColumnsHash,
  getDefaultColumnsWidthMap,
  getDefaultColumnWidth,
  getDefaultVisibleColumnIds,
  getVisibleColumns,
} from '../utils';
import {useStorableConfig} from './useStorableConfig';

const MIN_COLUMN_WIDTH = 32;

type Options<Item> = {
  columns: Column<unknown>[];
  columnSelectAriaLabel: string;
  columnTreeOptions?: ColumnTreeOptions;
  isSelectionDisabled: boolean;
  isEveryItemSelectionDisabled?: boolean;
  expandable: boolean;
  expandableHeader?: boolean;
  getRowActions?: GetRowActions<Item>;
  rowActionsAriaLabel: string;
  selectable: boolean;
  storageName: string;
  selectionType?: SelectionType;
  selectionColumnSortable?: SortableColumnOption;
  onVisibleColumnsChange?: (visibleIds: string[]) => void;
};

type ColumnsState<Item> = {
  columns: Column<Item>[];
  tableWidth: number;
  getColumnWidth: (column: Column<Item>) => number;
  onColumnResize: (column: Column<unknown>, diff: number) => void;
  onWidthMapChange: (widthMap: (prev: ColumnsWidthMap) => ColumnsWidthMap) => void;
};

export function useColumns<Item>({
  columns: availableColumns,
  columnSelectAriaLabel,
  columnTreeOptions,
  isSelectionDisabled,
  isEveryItemSelectionDisabled = false,
  expandable,
  expandableHeader = true,
  getRowActions,
  rowActionsAriaLabel,
  selectable,
  storageName,
  selectionColumnSortable,
  selectionType,
  onVisibleColumnsChange,
}: Options<Item>): ColumnsState<Item> {
  // compute hash for static columns only, because dynamic columns come later and trigger hash update
  const columnsHash = useMemo(
    () => computeColumnsHash(availableColumns.filter((column) => !column.dynamic)),
    [availableColumns],
  );

  const {config, persist: persistConfig, storage} = useStorableConfig({columnsHash, storageName});
  const [widthById, setWidthById] = useState(config.columnsWidth || getDefaultColumnsWidthMap(availableColumns));
  const [visibleIds, setVisibleIds] = useState(config.visibleColumns || getDefaultVisibleColumnIds(availableColumns));
  const persist = useCallback(
    (partial: Partial<ColumnsConfig>) =>
      persistConfig({columnsHash, columnsWidth: widthById, visibleColumns: visibleIds, ...partial}),
    [widthById, visibleIds, columnsHash],
  );

  const isColumnsSelectable = useMemo(() => availableColumns.some(({hideable}) => hideable), [availableColumns]);

  const visibleColumns = useMemo<Column<Item>[]>(() => {
    return getVisibleColumns(availableColumns, visibleIds);
  }, [availableColumns, visibleIds]);

  const getColumnWidth = useCallback(
    (column: Column<Item>) => widthById[column.id] || getDefaultColumnWidth(column),
    [widthById],
  );

  const onColumnResize = useCallback(
    (column: Column<unknown>, diff: number): void => {
      setWidthById((prevWidthById) => {
        const prevWidth = prevWidthById[column.id] || getDefaultColumnWidth(column);

        const columnsWidth = {
          ...prevWidthById,
          [column.id]: Math.max(prevWidth + diff, MIN_COLUMN_WIDTH),
        };
        persist({columnsWidth});
        return columnsWidth;
      });
    },
    [persist, setWidthById],
  );

  const selectionColumn = useMemo<Column<Item> | null>(
    () =>
      selectable || expandable
        ? getSelectionColumn({
            disabled: isSelectionDisabled,
            disabledAllSelection: isEveryItemSelectionDisabled,
            expandable,
            expandableHeader,
            selectable,
            selectionType,
            sortable: selectionColumnSortable,
          })
        : null,
    [isSelectionDisabled, expandable, expandableHeader, selectable, selectionType],
  );

  const configColumn = useMemo<Column<Item> | null>(
    () =>
      isColumnsSelectable || getRowActions
        ? getConfigColumn({
            ariaLabel: columnSelectAriaLabel,
            availableColumns,
            columnTreeOptions,
            getRowActions,
            rowActionsAriaLabel,
            visibleColumnIds: visibleIds,
            onColumnsVisibilityChange: setVisibleIds,
          })
        : null,
    [isColumnsSelectable, availableColumns, visibleIds, setVisibleIds],
  );

  const columns = useMemo<Column<Item>[]>(
    () => [selectionColumn, ...visibleColumns, configColumn].filter(isNotNullish),
    [selectionColumn, visibleColumns, configColumn],
  );

  const tableWidth = useMemo(
    () => columns.map(getColumnWidth).reduce((acc, width) => acc + width, 0),
    [columns, getColumnWidth],
  );

  useUpdateEffect(() => {
    storage.remove();
    setWidthById(getDefaultColumnsWidthMap(columns));
    setVisibleIds(getDefaultVisibleColumnIds(columns));
  }, [columnsHash]);

  useUpdateEffect(() => {
    persist({visibleColumns: visibleIds});
  }, [visibleIds]);

  useEffect(() => {
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(visibleIds);
    }
  }, [onVisibleColumnsChange, visibleIds]);

  return {
    columns,
    tableWidth,
    getColumnWidth,
    onColumnResize,
    onWidthMapChange: setWidthById,
  };
}
