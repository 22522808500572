import {ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {SlotDiff} from 'domain/duty/model/slot/diff';

export enum RotationValidatorError {
  PARTIALLY_FILLED = 'partiallyFilled',
}

export const validateRotation: ValidationFunction<SlotDiff[], ValidationOptions, RotationValidatorError> = (fields) => {
  if (fields.some(({userIds}) => userIds.length === 0)) {
    return {code: RotationValidatorError.PARTIALLY_FILLED};
  }
  return undefined;
};
