import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {PageLocker} from '@joomcode/joom-ui/PageLock';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {CompensationReviewRequestDraft} from 'domain/compensationReviewRequest/model/draft';
import {createCompensationReviewRequestFx} from 'domain/compensationReviewRequest/stores/main';
import {CompensationReviewRequestDraftDetails} from 'domain/compensationReviewRequest/widgets/DraftDetails';
import {CompensationReviewRequestBody} from 'domain/compensationReviewRequest/widgets/RequestBody';
import {convertRegularBonusValue} from 'domain/regularBonusRecord/utils/convertFormValues';
import React from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {hrConsoleUrlPatterns, hrConsoleUrls} from 'routes/hrConsole/urls';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  draft: CompensationReviewRequestDraft;
  goBack: () => void;
  onlySubordinates?: boolean;
  pageLocker: PageLocker;
};

export function CompensationReviewRequestDraft({draft, goBack, onlySubordinates, pageLocker}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const createRequest = useAsyncTask(
    () =>
      createCompensationReviewRequestFx({
        userId: draft.user.id,
        diff: {
          effectiveDate: draft.effectiveDate,
          reason: draft.reason,
          stockOptionsNewGrant: draft.stockOptionsNewGrant,
          type: draft.type,
          userInfo: {
            general: {
              ...draft.newUserInfo.general,
              managerId: draft.newUserInfo.general.manager.id,
            },
            salaries: draft.salaries,
            oneTimeBonuses: draft.newUserInfo.oneTimeBonuses,
            regularBonuses: draft.newUserInfo.regularBonuses?.map((regularBonus) => ({
              ...regularBonus,
              value: convertRegularBonusValue(regularBonus.type, regularBonus.value),
            })),
          },
        },
      })
        .then(({id}) => {
          toaster.success(intl.formatMessage(messages.success));
          pageLocker.unlock();
          history.push(
            history.location.pathname === hrConsoleUrlPatterns.compensationReviewRequestCreate
              ? hrConsoleUrls.compensationReviewRequest({id})
              : managerConsoleUrls.compensationReviewRequest({id}),
          );
        })
        .catch(toaster.interceptThenThrowError),
    [draft, history.push, onlySubordinates, pageLocker.unlock],
  );

  return (
    <div className={styles.root}>
      <CompensationReviewRequestDraftDetails draft={draft} />
      <CompensationReviewRequestBody {...draft} />
      <SubmitFormPanel>
        <ButtonGroup spaced size='l'>
          <Button
            kind='primary'
            intent='primary'
            type='button'
            onClick={createRequest.perform}
            loading={createRequest.isPerforming}
          >
            {intl.formatMessage(messages.buttonSubmit)}
          </Button>
          <Button intent='neutral' kind='secondary' onClick={goBack} disabled={createRequest.isPerforming}>
            {intl.formatMessage(messages.buttonBack)}
          </Button>
        </ButtonGroup>
      </SubmitFormPanel>
    </div>
  );
}
