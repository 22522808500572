import React, {type ReactNode} from 'react';
import {TimelineUnit} from '../types';
import {useTimeline} from '../Timeline/Provider';
import {Guides} from '../Guides';

type PropsType = {
  children: ReactNode;
};

export function Noons({children}: PropsType) {
  const {unit} = useTimeline();

  if (unit !== TimelineUnit.DAY) {
    return children;
  }

  return <Guides type='noon'>{children}</Guides>;
}
