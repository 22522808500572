import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {UserTimeOffBalances} from 'domain/user/model/timeOffBalances';
import {getMyPrivateInfoFx, getUserPrivateInfoByIdFx} from 'domain/user/stores/privateInfo';
import {$usersPrivateInfo} from 'domain/user/stores/privateInfo/state';
import {getSubordinateTimeOffBalancesFx} from 'domain/user/stores/timeOffBalances';
import {$userTimeOffBalances} from 'domain/user/stores/timeOffBalances/state';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: UserTimeOffBalances | null;
  dataState: DataState;
  hasReadAccess: boolean;
};

export const useUserTimeOffBalances = (user: UserFull): ReturnShape => {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const userId = user.id;

  const isSelfUser = selfUserId === user.id;
  const isManager = Boolean(selfUserId && user.ancestorIds.includes(selfUserId));
  const canReadOthersPrivateInfo = acl.hasPermission(Permission.USER_PRIVATE_INFO_READ);
  const canSeePrivateInfo = isSelfUser || canReadOthersPrivateInfo;
  const hasReadAccess = canSeePrivateInfo || isManager;

  const userPrivateInfo = useStoreMap({
    store: $usersPrivateInfo,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id ? (byUserId[id] ?? null) : null),
  });
  const userPrivateInfoDataState = useStoreMap({
    store: $usersPrivateInfo,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });
  const userTimeOffBalances = useStoreMap({
    store: $userTimeOffBalances,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id ? (byUserId[id] ?? null) : null),
  });
  const userTimeOffBalancesDataState = useStoreMap({
    store: $userTimeOffBalances,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  let dataSource = null;
  let dataState = DataState.IDLE;

  if (canSeePrivateInfo) {
    dataSource = userPrivateInfo;
    dataState = userPrivateInfoDataState;
  } else if (isManager) {
    dataSource = userTimeOffBalances;
    dataState = userTimeOffBalancesDataState;
  }

  useEffect(() => {
    if (user.isOutstaff) {
      return;
    }
    if (canSeePrivateInfo) {
      if (!isLoadingOrLoaded(userPrivateInfoDataState)) {
        (canReadOthersPrivateInfo ? getUserPrivateInfoByIdFx : getMyPrivateInfoFx)({userId}).catch(
          toaster.interceptThenThrowError,
        );
      }
    } else if (isManager) {
      if (!isLoadingOrLoaded(userTimeOffBalancesDataState)) {
        getSubordinateTimeOffBalancesFx({userId}).catch(toaster.interceptThenThrowError);
      }
    }
  }, [userId, canSeePrivateInfo, isManager, canReadOthersPrivateInfo]);

  return {
    hasReadAccess,
    data: dataSource
      ? {
          availableRemoteWorkDays: dataSource.availableRemoteWorkDays,
          availableVacationDays: dataSource.availableVacationDays,
          plannedVacationDays: dataSource.plannedVacationDays,
        }
      : null,
    dataState,
  };
};
