import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useMarketDataReadAccess} from 'domain/marketDataRecord/hooks/useMarketDataReadAccess';
import {useUserMarketDataRecords} from 'domain/marketDataRecord/hooks/useMarketDataRecords';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {MarketDataReadAccess} from 'domain/marketDataRecord/model/access';
import {MarketDataRecordDialog} from 'domain/marketDataRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {MarketData} from 'domain/user/widgets/MarketData';
import {MarketDataTabAddon} from 'domain/user/widgets/MarketDataTabAddon';
import React, {useMemo} from 'react';
import {filterOutFutureRecords} from 'utils/record';

export function useMarketDataTab(user: UserFull, isSensitiveDataHidden: boolean): JobPanelTab<MarketDataRecord> {
  const marketDataRecords = useUserMarketDataRecords(user);
  const marketDataRecordDialog = usePopupState<MarketDataRecord>();
  const readAccess = useMarketDataReadAccess(user);
  const records = useMemo(
    () =>
      readAccess === MarketDataReadAccess.MY ? filterOutFutureRecords(marketDataRecords.data) : marketDataRecords.data,
    [readAccess, marketDataRecords.data],
  );

  const result = useMemo(
    () => ({
      addon: <MarketDataTabAddon onDialogOpen={marketDataRecordDialog.open} />,
      content: (
        <MarketData
          userId={user.id}
          records={records}
          dataState={marketDataRecords.dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={marketDataRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <MarketDataRecordDialog
          userId={user.id}
          recordToUpdate={marketDataRecordDialog.payload}
          isOpen={marketDataRecordDialog.isOpen}
          onClose={marketDataRecordDialog.close}
        />
      ),
      records,
      dataState: marketDataRecords.dataState,
    }),
    [user, records, marketDataRecords.dataState, isSensitiveDataHidden, marketDataRecordDialog],
  );

  return result;
}
