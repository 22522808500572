import React, {forwardRef, useMemo, type RefObject} from 'react';
import {DEFAULT_ITEM_COLOR, DEFAULT_ITEM_GAP} from '../utils';
import {useGetItemPosition} from '../hooks/useGetItemPosition';
import type {Interval} from '../types';
import styles from './index.css';

type PropsType = {
  interval: Interval;
  children?: React.ReactNode;
  color?: string;
  gap?: number;
};

export const Item = forwardRef(function Item(
  {children, interval, color, gap = DEFAULT_ITEM_GAP}: PropsType,
  ref: RefObject<HTMLDivElement>,
) {
  const backgroundColor = color ?? (children ? undefined : DEFAULT_ITEM_COLOR);
  const getItemPosition = useGetItemPosition();
  const style = useMemo(() => {
    const {start, end} = getItemPosition(interval);
    return {
      left: `${start}px`,
      width: `${end - start - gap}px`,
      backgroundColor,
    };
  }, [getItemPosition, interval, backgroundColor, gap]);

  return (
    <div ref={ref} className={styles.Item} style={style}>
      {children}
    </div>
  );
});
