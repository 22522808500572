import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {scheduleIssueSchema} from 'domain/duty/model/scheduleIssue';
import {slotSchema} from 'domain/duty/model/slot';
import {formattedDateSchema} from 'models/system/formattedDate';

export const scheduleItemIdSchema = idSchema;
export const scheduleItemSchema = object({
  endDate: formattedDateSchema,
  id: optional(scheduleItemIdSchema),
  issues: array(scheduleIssueSchema),
  slot: slotSchema,
  startDate: formattedDateSchema,
});

export type ScheduleItemId = ExtractSchemaType<typeof scheduleItemIdSchema>;
export type ScheduleItem = ExtractSchemaType<typeof scheduleItemSchema>;
