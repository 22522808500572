import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {ScheduleIssue, scheduleIssueSchema} from 'domain/duty/model/scheduleIssue';
import {CreateOverrideConfig} from './createOverride';

export const getIssues = async ({diff, params}: CreateOverrideConfig): Promise<ScheduleIssue[]> => {
  const {data} = await intranetApi.post('/v1/teams/duties/overrides/getIssues', diff, {params});

  return array(scheduleIssueSchema).runWithException(data);
};
