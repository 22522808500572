import React, {type ReactNode} from 'react';
import cn from 'classnames';
import styles from './index.css';

type PropsType = {
  overflow?: 'visible' | 'hidden' | 'ellipsis';
  children: ReactNode;
};

export function Sticky({children, overflow = 'visible'}: PropsType) {
  return <span className={cn(styles.Sticky, styles[overflow])}>{children}</span>;
}
