import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  publicHoliday: {
    defaultMessage: 'Public holiday in {country}: {dates}',
    description: 'Human schedule issue',
  },
  publicHolidayWithFullName: {
    defaultMessage: '{fullName} is on public holiday in {country}: {dates}',
    description: 'Human schedule issue',
  },
  dateRange: {
    defaultMessage: '{from} – {to}',
    description: 'Date range',
  },
  leavesCompany: {
    defaultMessage: 'Leaves Joom on {date}',
    description: 'Human schedule issue',
  },
  leavesCompanyWithFullName: {
    defaultMessage: '{fullName} leaves Joom on {date}',
    description: 'Human schedule issue',
  },
  terminatedUser: {
    defaultMessage: 'Former employee',
    description: 'Human schedule issue',
  },
  terminatedUserWithFullName: {
    defaultMessage: '{fullName} is a former employee',
    description: 'Human schedule issue',
  },
  duty: {
    defaultMessage: 'On {dutyName}: {dates}',
    description: 'Human schedule issue',
  },
  dutyWithFullName: {
    defaultMessage: '{fullName} is on {dutyName}: {dates}',
    description: 'Human schedule issue',
  },
  timeOff: {
    defaultMessage: 'Out of office: {dates}',
    description: 'Human schedule issue',
  },
  timeOffWithFullName: {
    defaultMessage: '{fullName} is out of office: {dates}',
    description: 'Human schedule issue',
  },
});
