import {FilledValidatorErrorCode, validateFilled, ValidationError, ValidationResult} from '@joomcode/joom-form';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import getDate from 'date-fns/getDate';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {TimeOffRequestCreationConfig} from 'domain/timeOff/request/model';
import {TimeOffRequestFormError} from 'domain/timeOff/request/model/error';
import {validateDatesOrder} from 'domain/timeOff/request/utils/validate';
import {TimeOffType} from 'domain/timeOff/type/model';
import {User} from 'domain/user/model';

type ValidatorOptions = {
  restrictDatesInterval: boolean;
  user: User;
};

const VACATION_PREPARATION_DAYS = 5;
const today = new Date();

export const isEndOfTheMonthRestricted = ({countryOfTimeOffPolicy}: User) =>
  countryOfTimeOffPolicy === TimeOffPolicy.GERMANY || countryOfTimeOffPolicy === TimeOffPolicy.PORTUGAL;

export const getMinDateByTimeOffType = (type: TimeOffType, user: User) => {
  if (type !== TimeOffType.VACATION) {
    return today;
  }

  if (!isEndOfTheMonthRestricted(user)) {
    return addDays(today, VACATION_PREPARATION_DAYS);
  }

  const day = getDate(today);
  if (day >= 20) {
    const nextMonthStart = addDays(endOfMonth(today), 1);
    const daysToNextMonthStart = differenceInCalendarDays(nextMonthStart, today);

    if (daysToNextMonthStart >= VACATION_PREPARATION_DAYS) {
      return nextMonthStart;
    }
    return addDays(nextMonthStart, VACATION_PREPARATION_DAYS - daysToNextMonthStart);
  }
  return addDays(today, VACATION_PREPARATION_DAYS);
};

export const getMaxDateByTimeOffType = (type: TimeOffType) =>
  type === TimeOffType.REMOTE_WORK ? endOfYear(today) : undefined;

const validateDateValue = (
  value: string | undefined,
  {type}: Partial<TimeOffRequestCreationConfig>,
  user: User,
): ValidationError<TimeOffRequestFormError> | undefined => {
  if (!value || !type) {
    return undefined;
  }

  const date = new Date(value);
  const minDate = getMinDateByTimeOffType(type, user);
  const maxDate = getMaxDateByTimeOffType(type);

  if (differenceInCalendarDays(date, minDate) < 0) {
    return {code: TimeOffRequestFormError.TOO_EARLY};
  }
  if (maxDate && differenceInCalendarDays(date, maxDate) > 0) {
    return {code: TimeOffRequestFormError.TOO_LATE};
  }

  return undefined;
};

const validateEndDate = (
  endDate: string | undefined,
  {startDate}: Partial<TimeOffRequestCreationConfig>,
): ValidationError<TimeOffRequestFormError> | undefined => {
  return validateDatesOrder(startDate, endDate);
};

export const validateTimeOffCreationForm = (
  values: Partial<TimeOffRequestCreationConfig>,
  {restrictDatesInterval, user}: ValidatorOptions,
): Partial<
  Record<
    keyof TimeOffRequestCreationConfig,
    | ValidationResult<TimeOffRequestFormError | FilledValidatorErrorCode, Record<string, unknown>>
    | Promise<ValidationResult<TimeOffRequestFormError | FilledValidatorErrorCode, Record<string, unknown>>>
  >
> => {
  return {
    startDate:
      validateFilled(values.startDate) ??
      (restrictDatesInterval ? validateDateValue(values.startDate, values, user) : undefined),
    endDate:
      validateFilled(values.endDate) ??
      (restrictDatesInterval ? validateDateValue(values.endDate, values, user) : undefined) ??
      validateEndDate(values.endDate, values),
  };
};
