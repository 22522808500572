import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {dutyIdSchema} from 'domain/duty/model/lite';

export const slackUserGroupIdSchema = idSchema;
export const slackUserGroupSchema = object({
  dutyId: optional(dutyIdSchema),
  handle: string(),
  id: slackUserGroupIdSchema,
  link: string(),
  name: string(),
});

export type SlackUserGroupId = ExtractSchemaType<typeof slackUserGroupIdSchema>;
export type SlackUserGroup = ExtractSchemaType<typeof slackUserGroupSchema>;
