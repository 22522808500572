import {Tippy} from '@joomcode/joom-ui/Tippy';
import type {ComponentProps} from 'react';
import React, {useMemo} from 'react';
import type {TooltipItem} from '../types';
import {TooltipContent} from './TooltipContent';

type ItemWithTooltip = {
  data: {
    tooltip?: TooltipItem[];
  };
};

type PropsType = {
  items: ItemWithTooltip[];
  children: ComponentProps<typeof Tippy>['children'];
};

export function Tooltip({children, items}: PropsType) {
  const tooltipItems = useMemo(() => items?.flatMap((item) => item.data?.tooltip || []), [items]);

  if (tooltipItems?.length) {
    const content = <TooltipContent items={tooltipItems} />;
    return (
      <Tippy content={content} interactive boundary='viewport' maxWidth='23rem'>
        {children}
      </Tippy>
    );
  }

  return children;
}
