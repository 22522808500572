import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {useSalaryRangesReadAccess} from 'domain/salaryRange/hooks/useSalaryRangesReadAccess';
import {useUserFullInfoReadAccess} from 'domain/userFullInfoRecord/hooks/useReadAccess';
import {useMemo} from 'react';

type ReturnShape = {
  availableTabs: ManagerConsoleTab[];
  showTabs: boolean;
};

export function useManagerConsolePageTabs(): ReturnShape {
  const userFullInfoReadAccess = useUserFullInfoReadAccess();
  const salaryRangesReadAccess = useSalaryRangesReadAccess();
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();

  const tabAvailability: Record<ManagerConsoleTab, boolean> = useMemo(
    () => ({
      [ManagerConsoleTab.SUBORDINATES]: Boolean(userFullInfoReadAccess),
      [ManagerConsoleTab.SALARY_RANGES]: Boolean(salaryRangesReadAccess),
      [ManagerConsoleTab.COMPENSATION_REVIEW_REQUESTS]: Boolean(compensationReviewRequestReadAccess),
    }),
    [userFullInfoReadAccess, salaryRangesReadAccess, compensationReviewRequestReadAccess],
  );

  const availableTabs = useMemo(
    () => getEnumValues(ManagerConsoleTab).filter((tab) => tabAvailability[tab]),
    [tabAvailability],
  );

  return {
    availableTabs,
    showTabs: availableTabs.length > 1,
  };
}
