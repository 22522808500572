import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useJobInfoReadAccess} from 'domain/jobInfoRecord/hooks/useJobInfoReadAccess';
import {useUsersJobInfoRecords} from 'domain/jobInfoRecord/hooks/useUsersJobInfoRecords';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {JobInfoReadAccess} from 'domain/jobInfoRecord/model/access';
import {JobInfoRecordDialog} from 'domain/jobInfoRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobInfo} from 'domain/user/widgets/JobInfo';
import {JobInfoTabAddon} from 'domain/user/widgets/JobInfoTabAddon';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import React, {useMemo} from 'react';
import {filterOutFutureRecords} from 'utils/record';

export function useJobInfoTab(user: UserFull): JobPanelTab<JobInfoRecord> {
  const jobInfo = useUsersJobInfoRecords(user);
  const jobInfoRecordDialog = usePopupState<JobInfoRecord>();
  const readAccess = useJobInfoReadAccess(user);
  const records = useMemo(
    () => (readAccess === JobInfoReadAccess.MY ? filterOutFutureRecords(jobInfo.data) : jobInfo.data),
    [readAccess, jobInfo.data],
  );
  const activeRecord = useMemo(() => records.find(({active}) => active), [records]);

  const result = useMemo(
    () => ({
      addon: <JobInfoTabAddon onDialogOpen={jobInfoRecordDialog.open} />,
      content: (
        <JobInfo
          userId={user.id}
          records={records}
          dataState={jobInfo.dataState}
          onDialogOpenWithPayload={jobInfoRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <JobInfoRecordDialog
          activeRecord={activeRecord}
          isOpen={jobInfoRecordDialog.isOpen}
          onClose={jobInfoRecordDialog.close}
          recordToUpdate={jobInfoRecordDialog.payload}
          userId={user.id}
        />
      ),
      records,
      dataState: jobInfo.dataState,
    }),
    [user.id, records, jobInfo.dataState, activeRecord, jobInfoRecordDialog],
  );

  return result;
}
