import {identity} from '@joomcode/deprecated-utils/function';
import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {getAvailableTimeOptions} from './utils';

export type Props = CommonFieldProps<string> & {
  minDate?: Date;
};

export function FieldTime({
  name,
  label,
  hint,
  disabled,
  required,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  minDate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const items = useMemo(() => getAvailableTimeOptions(initialValue, minDate), [initialValue, minDate]);
  const renderItem = useCallback((time: string) => time.slice(0, 5), []);
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;
  const {input, meta} = useField<string>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <SimpleAutocomplete<string>
          getItemKey={identity}
          items={items}
          placeholder='--:--'
          renderItem={renderItem}
          itemToString={renderItem}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
}
