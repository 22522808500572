import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import {getTimeZoneByOffset} from 'domain/duty/utils/timeZone';
import React from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

type Props = CommonFieldProps<number>;

export function FieldTimeZone({
  name,
  label,
  hint,
  required,
  initialValue = 0,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const items = [0];
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;
  const {input, meta} = useField<number>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Select<number>
          items={items}
          disabled
          getItemKey={getTimeZoneByOffset}
          itemToString={getTimeZoneByOffset}
          {...formControlProps}
          {...input}
          {...restProps}
        />
      )}
    </FormControl>
  );
}
