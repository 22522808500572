import {DataState} from '@joomcode/deprecated-utils/dataState';
import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {useResources} from 'domain/resource/hooks/useResources';
import {Resource, ResourceId} from 'domain/resource/model';
import {ResourceStatus} from 'domain/resource/model/status';
import {ResourceAutocomplete} from 'domain/resource/widgets/Autocomplete';
import {ResourceHintIcon} from 'domain/resource/widgets/HintIcon';
import React, {useMemo} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

export type Props = {
  label: React.ReactNode;
  name: string;
  submitting: boolean;
};

type ResourceSelectValue = Resource | null;

export function ResourceSelectFormControl({label, name, submitting}: Props) {
  const intl = useIntl();
  const filters = useMemo(() => ({statuses: [ResourceStatus.APPROVED]}), []);
  const pagination = useMemo(() => ({initialLimit: 100, initialPage: 1}), []);
  const {dataState, resources, resourceById} = useResources(filters, pagination);
  const resourcesLoadingError =
    dataState === DataState.FAILED ? intl.formatMessage(messages.resourcesLoadingFailed) : undefined;
  const disabled = submitting || dataState !== DataState.LOADED;

  return (
    <Field<ResourceId> name={name} validate={validateFilled}>
      {({input: {onChange, value, ...input}, meta}) => (
        <FormControl
          disabled={disabled}
          label={
            <>
              {label}
              <ResourceHintIcon />
            </>
          }
          error={getFieldErrorText(meta, {intl}) ?? resourcesLoadingError}
          required
        >
          {(formControlProps) => (
            <ResourceAutocomplete
              items={resources}
              disabled={disabled}
              onChange={(newValue: ResourceSelectValue) => onChange(newValue?.id ?? null)}
              value={resourceById[value] ?? null}
              {...formControlProps}
              {...input}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
