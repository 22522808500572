import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Button} from '@joomcode/joom-ui/Button';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {useResource} from 'domain/resource/hooks/useResource';
import {resourceIdSchema} from 'domain/resource/model';
import {ResourceRoleBindingsPanel} from 'domain/resource/widgets/RoleBindingsPanel';
import {ResourceSummaryPanel} from 'domain/resource/widgets/SummaryPanel';
import {ResourceTeamsPanel} from 'domain/resource/widgets/TeamsPanel';
import {ResourceUpdateDialog} from 'domain/resource/widgets/UpdateDialog';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/jdm/titles';
import {jdmUrls} from 'routes/jdm/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function ResourcePage() {
  const acl = useAcl();
  const intl = useIntl();
  const {resourceId} = useTypedParams({resourceId: resourceIdSchema});
  const {resource, dataState, clientErrorStatus, isSelfOwner} = useResource(resourceId);
  const updateDialog = usePopupState();

  if (!resourceId) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={resource} entityError={clientErrorStatus} state={dataState}>
      {(resourceData) => {
        return (
          <Page
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.services),
                href: jdmUrls.services(),
              },
              {
                title: resourceData.service.name,
                href: jdmUrls.service({serviceId: resourceData.service.id}),
              },
            ]}
            title={resourceData.displayName}
            actions={
              acl.hasPermission(Permission.RESOURCE_WRITE) &&
              isSelfOwner && (
                <Button kind='primary' intent='primary' size='m' onClick={updateDialog.open}>
                  {intl.formatMessage(messages.btnEdit)}
                </Button>
              )
            }
          >
            <ResourceSummaryPanel resource={resourceData} />
            {acl.hasPermission(Permission.ROLE_BINDING_READ) && (
              <ResourceRoleBindingsPanel resourceId={resourceData.id} canManage={isSelfOwner} />
            )}
            {acl.hasPermission(Permission.TEAM_READ) && <ResourceTeamsPanel resourceId={resourceData.id} />}
            {updateDialog.isOpen && <ResourceUpdateDialog resource={resourceData} onClose={updateDialog.close} />}
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
