import {intranetApi} from 'apiClient';
import {Duty, dutySchema} from 'domain/duty/model';
import {DutyId} from 'domain/duty/model/lite';
import {ScheduleItemId} from 'domain/duty/model/scheduleItem';
import {TeamId} from 'domain/team/model/id';

export type DeleteOverrideConfig = {
  params: {
    dutyId: DutyId;
    id: ScheduleItemId;
    teamId: TeamId;
  };
};

export const deleteOverride = async ({params}: DeleteOverrideConfig): Promise<Duty> => {
  const {data} = await intranetApi.post('/v1/teams/duties/overrides/delete', undefined, {params});

  return dutySchema.runWithException(data);
};
