import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  duration: {
    defaultMessage: '{withPrefix, select, true{Duration: } other {}}{duration}',
    description: 'Duration of the schedule item',
  },
  start: {
    defaultMessage: 'Start duty: {date} {timeZone}',
    description: 'Start date of the schedule item',
  },
  dateRange: {
    defaultMessage: '{startDate} – {endDate} {timeZone}{withLocal, select, true{{localRange}} other {}}',
    description: 'Schedule item dates',
  },
  timeRange: {
    defaultMessage: '{startDate}, {startTime} – {endTime} {timeZone}{withLocal, select, true{{localRange}} other {}}',
    description: 'Schedule item dates',
  },
  localDateRange: {
    defaultMessage: '{br}Local time: {startDate} – {endDate}',
    description: 'Schedule item dates',
  },
  localTimeRange: {
    defaultMessage: '{br}Local time: {startDate}, {startTime} – {endTime}',
    description: 'Schedule item dates',
  },
});
