interface RecordWithDate {
  date: string;
}
interface RecordWithEffectiveDate {
  effectiveDate: string;
}
interface RecordWithStartDate {
  startDate: string;
}

type Record = RecordWithDate | RecordWithEffectiveDate | RecordWithStartDate;

const today = new Date();

function hasEffectiveDate(record: Record): record is RecordWithEffectiveDate {
  return 'effectiveDate' in record;
}

function hasStartDate(record: Record): record is RecordWithStartDate {
  return 'startDate' in record;
}

export function filterOutFutureRecords<T extends Record>(records: T[]): T[] {
  return records.filter((record) => {
    if (hasEffectiveDate(record)) {
      return new Date(record.effectiveDate) <= today;
    }
    if (hasStartDate(record)) {
      return new Date(record.startDate) <= today;
    }
    return new Date(record.date) <= today;
  });
}
