import {Button} from '@joomcode/joom-ui/Button';
import {Grid} from '@joomcode/joom-ui/Grid';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TimeOffRequestCreationConfig} from 'domain/timeOff/request/model';
import {errorMessages} from 'domain/timeOff/request/model/error/messages';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {createTimeOffRequestFx, createTimeOffRequestOnBehalfFx} from 'domain/timeOff/request/stores/main';
import {TimeOffType} from 'domain/timeOff/type/model';
import {FieldTimeOffType} from 'domain/timeOff/type/widgets/Field';
import {User} from 'domain/user/model';
import {resetUserPrivateInfo} from 'domain/user/stores/privateInfo';
import {stringToDate} from 'models/system/formattedDate';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {timeOffUrls} from 'routes/timeOff/urls';
import {toaster} from 'services/toaster';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import uuid from 'uuid/v4';
import {Balance} from './Balance';
import {labels, messages} from './messages';
import styles from './styles.css';
import {getMaxDateByTimeOffType, getMinDateByTimeOffType, validateTimeOffCreationForm} from './validate';

type Props = {
  availableRemoteWorkDays?: number;
  availableVacationDays?: number;
  user: User;
};

export function TimeOffRequestForm({availableRemoteWorkDays, availableVacationDays, user}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const formId = useMemo(uuid, []);
  const selfUserId = useSelfUserId();
  const isSelfUser = user.id === selfUserId;
  const validateForm = useCallback(
    (values: Partial<TimeOffRequestCreationConfig>) =>
      validateTimeOffCreationForm(values, {restrictDatesInterval: isSelfUser, user}),
    [isSelfUser],
  );
  const usersnapApi = useUsersnapApi();

  const onSubmit: FormProps<TimeOffRequestCreationConfig>['onSubmit'] = (config) =>
    (isSelfUser ? createTimeOffRequestFx(config) : createTimeOffRequestOnBehalfFx({userId: user.id, diff: config}))
      .then(() => {
        resetUserPrivateInfo({userId: user.id});
        history.push(
          timeOffUrls.requests({mode: isSelfUser ? TimeOffRequestsTableMode.MY : TimeOffRequestsTableMode.ALL}),
        );
        usersnapApi?.logEvent(UserSnapEvents.CREATE_TIME_OFF_REQUEST);
      })
      .catch(toaster.interceptThenThrowError);

  return (
    <Form<TimeOffRequestCreationConfig> onSubmit={onSubmit} validate={validateForm}>
      {({handleSubmit, submitting, invalid, values}) => (
        <div className={styles.layout}>
          <form className={styles.form} id={formId} onSubmit={handleSubmit}>
            <Grid>
              <Grid.Item xl={12} xs={12}>
                <FieldTimeOffType
                  name='type'
                  label={intl.formatMessage(labels.type)}
                  initialValue={TimeOffType.VACATION}
                  required
                  hint={
                    values.type === TimeOffType.FAMILY_LEAVE ? intl.formatMessage(messages.familyLeaveHint) : undefined
                  }
                />
              </Grid.Item>
              <Grid.Item xl={6} xs={12}>
                <FieldDate
                  name='startDate'
                  label={intl.formatMessage(labels.startDate)}
                  required
                  extraErrorMessages={errorMessages}
                  minDate={isSelfUser ? getMinDateByTimeOffType(values.type, user) : undefined}
                  maxDate={isSelfUser ? getMaxDateByTimeOffType(values.type) : undefined}
                  selectsStart
                  endDate={stringToDate(values.endDate) ?? undefined}
                />
              </Grid.Item>
              <Grid.Item xl={6} xs={12}>
                <FieldDate
                  name='endDate'
                  label={intl.formatMessage(labels.endDate)}
                  required
                  minDate={isSelfUser ? getMinDateByTimeOffType(values.type, user) : undefined}
                  maxDate={isSelfUser ? getMaxDateByTimeOffType(values.type) : undefined}
                  selectsEnd
                  startDate={stringToDate(values.startDate) ?? undefined}
                  extraErrorMessages={errorMessages}
                />
              </Grid.Item>
            </Grid>
            <SubmitFormPanel>
              <Button
                form={formId}
                size='l'
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </SubmitFormPanel>
          </form>
          {values.type === TimeOffType.VACATION && availableVacationDays !== undefined && (
            <div className={styles.calculator}>
              <Balance availableDays={availableVacationDays} type={TimeOffType.VACATION} user={user} />
            </div>
          )}
          {values.type === TimeOffType.REMOTE_WORK && availableRemoteWorkDays !== undefined && (
            <div className={styles.calculator}>
              <Balance availableDays={availableRemoteWorkDays} type={TimeOffType.REMOTE_WORK} user={user} />
            </div>
          )}
        </div>
      )}
    </Form>
  );
}
