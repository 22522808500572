import {useMemo} from 'react';
import {DutiesDescriptor} from '../types';

export function useTimelineBoundary({mainSchedule}: DutiesDescriptor): [number, number] {
  return useMemo(
    () => [
      new Date(mainSchedule[0].startDate).getTime(),
      new Date(mainSchedule[mainSchedule.length - 1].endDate).getTime(),
    ],
    [mainSchedule],
  );
}
