import {assertNever} from '@joomcode/deprecated-utils/types';
import React from 'react';
import {useIntl, type FormatDateOptions} from 'react-intl';
import {Item} from '../Item';
import {Sticky} from '../Sticky';
import {useTimeline} from '../Timeline/Provider';
import {TimelineUnit} from '../types';
import {useDateIntervals} from '../hooks/useDateIntervals';
import {unitDateFns} from '../utils';
import styles from './index.css';

function useDateFormat(): FormatDateOptions {
  const {unit} = useTimeline();
  switch (unit) {
    case TimelineUnit.DAY:
      return {
        day: 'numeric',
        month: 'long',
        weekday: 'short',
      };
    case TimelineUnit.DAY_NARROW:
      return {
        day: 'numeric',
        weekday: 'narrow',
      };
    case TimelineUnit.WEEK:
      return {
        day: 'numeric',
        month: 'short',
      };
    default:
      assertNever(unit);
      throw new Error(`Unknown unit: ${unit}`);
  }
}

export function Units() {
  const {unit} = useTimeline();
  const units = useDateIntervals(unitDateFns[unit]);
  const intl = useIntl();
  const dateFormat = useDateFormat();

  return (
    <div className={styles.Dates}>
      {units.map((item) => (
        <Item key={item.date.getTime()} interval={item.interval}>
          <Sticky overflow='hidden'>
            <time className={styles.item} key={item.date.getTime()}>
              {intl.formatDate(item.date, dateFormat)}
            </time>
          </Sticky>
        </Item>
      ))}
    </div>
  );
}
