import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {CompensationReviewRequestApproveDiff} from 'domain/compensationReviewRequest/api/approve';
import {CompensationReviewRequestRejectDiff} from 'domain/compensationReviewRequest/api/reject';
import {CompensationReviewRequestSetRecordedDiff} from 'domain/compensationReviewRequest/api/setRecorded';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {ChangeValue} from 'domain/compensationReviewRequest/model/changeValue';
import {Status} from 'domain/compensationReviewRequest/model/status';
import {
  approveCompensationReviewRequestFx,
  approveCompensationReviewRequestOnBehalfFx,
  cancelCompensationReviewRequestFx,
  rejectCompensationReviewRequestFx,
  rejectCompensationReviewRequestOnBehalfFx,
  setCompensationsRecordedFx,
  setStockOptionsRecordedFx,
} from 'domain/compensationReviewRequest/stores/main';
import {getCurrentDecision, getFutureDecisions} from 'domain/compensationReviewRequest/utils/decisions';
import {CompensationReviewRequestDialogApprove} from 'domain/compensationReviewRequest/widgets/DialogApprove';
import {CompensationReviewRequestDialogReject} from 'domain/compensationReviewRequest/widgets/DialogReject';
import {CompensationReviewRequestDialogSetRecorded} from 'domain/compensationReviewRequest/widgets/DialogSetRecorded';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFullName} from 'domain/user/widgets/FullName';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  request: CompensationReviewRequest;
};

export function CompensationReviewRequestActions({
  request: {compensationChange, createdBy, decisions, id, status, stockOptionsChange},
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const canWrite = acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE);
  const canWriteOnBehalf = acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF);
  const canSetCompensationsRecorded = acl.hasSecurePermission(
    SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED,
  );
  const canSetStockOptionsRecorded = acl.hasSecurePermission(
    SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED,
  );
  const currentDecision = getCurrentDecision(decisions);
  const futureDecisions = getFutureDecisions(decisions);
  const isCurrentApprover = selfUserId === currentDecision?.user.id;
  const isFutureApprover = futureDecisions.some((decision) => decision.user.id === selfUserId);
  const {open: openApproveDialog, close: closeApproveDialog, isOpen: isApproveDialogOpen} = usePopupState();
  const {open: openRejectDialog, close: closeRejectDialog, isOpen: isRejectDialogOpen} = usePopupState();
  const {
    open: openApproveOnBehalfDialog,
    close: closeApproveOnBehalfDialog,
    isOpen: isApproveOnBehalfDialogOpen,
  } = usePopupState();
  const {
    open: openRejectOnBehalfDialog,
    close: closeRejectOnBehalfDialog,
    isOpen: isRejectOnBehalfDialogOpen,
  } = usePopupState();
  const {
    open: openSetCompensationsRecordedDialog,
    close: closeSetCompensationsRecordedDialog,
    isOpen: isSetCompensationsRecordedDialogOpen,
  } = usePopupState();
  const {
    open: openSetStockOptionsRecordedDialog,
    close: closeSetStockOptionsRecordedDialog,
    isOpen: isSetStockOptionsRecordedDialogOpen,
  } = usePopupState();

  const approve = useAsyncTask(
    async (diff: CompensationReviewRequestApproveDiff) =>
      approveCompensationReviewRequestFx({...diff, id: id})
        .then(() => toaster.success(intl.formatMessage(messages.successfulApprove, {fullName: undefined})))
        .catch(toaster.interceptThenThrowError),
    [id, intl],
  );
  const approveOnBehalf = useAsyncTask(
    async (diff: CompensationReviewRequestApproveDiff) =>
      approveCompensationReviewRequestOnBehalfFx({...diff, id: id})
        .then(() =>
          toaster.success(
            intl.formatMessage(messages.successfulApprove, {
              fullName: currentDecision ? <UserFullName user={currentDecision.user} /> : undefined,
            }),
          ),
        )
        .catch(toaster.interceptThenThrowError),
    [id, intl, currentDecision],
  );
  const cancel = useAsyncTask(
    async () =>
      cancelCompensationReviewRequestFx(id)
        .then(() => toaster.success(intl.formatMessage(messages.successfulCancel)))
        .catch(toaster.interceptThenThrowError),
    [id, intl],
  );
  const reject = useAsyncTask(
    async (diff: CompensationReviewRequestRejectDiff) =>
      rejectCompensationReviewRequestFx({...diff, id: id})
        .then(() => toaster.success(intl.formatMessage(messages.successfulReject, {fullName: undefined})))
        .catch(toaster.interceptThenThrowError),
    [id, intl],
  );
  const rejectOnBehalf = useAsyncTask(
    async (diff: CompensationReviewRequestRejectDiff) =>
      rejectCompensationReviewRequestOnBehalfFx({...diff, id: id})
        .then(() =>
          toaster.success(
            intl.formatMessage(messages.successfulReject, {
              fullName: currentDecision ? <UserFullName user={currentDecision.user} /> : undefined,
            }),
          ),
        )
        .catch(toaster.interceptThenThrowError),
    [id, intl, currentDecision],
  );
  const setCompensationsRecorded = useAsyncTask(
    async (diff: CompensationReviewRequestSetRecordedDiff) =>
      setCompensationsRecordedFx({id, ...diff})
        .then(() => toaster.success(intl.formatMessage(messages.successfulSetRecorded)))
        .catch(toaster.interceptThenThrowError),
    [id, intl],
  );
  const setStockOptionsRecorded = useAsyncTask(
    async (diff: CompensationReviewRequestSetRecordedDiff) =>
      setStockOptionsRecordedFx({id, ...diff})
        .then(() => toaster.success(intl.formatMessage(messages.successfulSetStockOptionsRecorded)))
        .catch(toaster.interceptThenThrowError),
    [id, intl],
  );

  const onCancelClick = useCallback(
    () =>
      confirm(
        {
          denialText: intl.formatMessage(messages.cancelDialogDenyButton),
          title: intl.formatMessage(messages.cancelDialogTitle),
          text: intl.formatMessage(messages.cancelDialogText),
          confirmationText: intl.formatMessage(messages.cancelDialogConfirmButton),
          onConfirm: cancel.perform,
        },
        intl,
      ),
    [cancel.perform, intl],
  );
  const onApproveSubmit = useCallback(
    (diff: CompensationReviewRequestApproveDiff) => approve.perform(diff).then(closeApproveDialog),
    [approve.perform, closeApproveDialog],
  );
  const onApproveOnBehalfSubmit = useCallback(
    (diff: CompensationReviewRequestApproveDiff) => approveOnBehalf.perform(diff).then(closeApproveOnBehalfDialog),
    [approveOnBehalf.perform, closeApproveOnBehalfDialog],
  );
  const onRejectSubmit = useCallback(
    (diff: CompensationReviewRequestRejectDiff) => reject.perform(diff).then(closeRejectDialog),
    [reject.perform, closeRejectDialog],
  );
  const onRejectOnBehalfSubmit = useCallback(
    (diff: CompensationReviewRequestRejectDiff) => rejectOnBehalf.perform(diff).then(closeRejectOnBehalfDialog),
    [rejectOnBehalf.perform, closeRejectOnBehalfDialog],
  );
  const onSetCompensationsRecordedSubmit = useCallback(
    (diff: CompensationReviewRequestSetRecordedDiff) =>
      setCompensationsRecorded.perform(diff).then(closeSetCompensationsRecordedDialog),
    [setCompensationsRecorded.perform, closeSetCompensationsRecordedDialog],
  );
  const onSetStockOptionsRecordedSubmit = useCallback(
    (diff: CompensationReviewRequestSetRecordedDiff) =>
      setStockOptionsRecorded.perform(diff).then(closeSetStockOptionsRecordedDialog),
    [setStockOptionsRecorded.perform, closeSetStockOptionsRecordedDialog],
  );

  return (
    <ButtonGroup spaced size='m'>
      {status === Status.IN_PROCESS && createdBy.id === selfUserId && (
        <Button type='button' intent='primary' kind='primary' onClick={onCancelClick}>
          {intl.formatMessage(messages.buttonCancel)}
        </Button>
      )}
      {status === Status.IN_PROCESS && canWrite && (isCurrentApprover || (isFutureApprover && !canWriteOnBehalf)) && (
        <>
          <TooltipFaq
            content={intl.formatMessage(messages.disabledDecisionButtonHint)}
            enabled={isFutureApprover}
            placement='left'
          >
            <span>
              <Button
                type='button'
                intent='primary'
                kind='primary'
                disabled={isFutureApprover}
                onClick={openApproveDialog}
              >
                {intl.formatMessage(messages.buttonApprove)}
              </Button>
            </span>
          </TooltipFaq>
          <TooltipFaq
            content={intl.formatMessage(messages.disabledDecisionButtonHint)}
            enabled={isFutureApprover}
            placement='left'
          >
            <span>
              <Button
                type='button'
                intent='negative'
                kind='primary'
                disabled={isFutureApprover}
                onClick={openRejectDialog}
              >
                {intl.formatMessage(messages.buttonReject)}
              </Button>
            </span>
          </TooltipFaq>
          {isCurrentApprover && (
            <>
              <CompensationReviewRequestDialogApprove
                isOpen={isApproveDialogOpen}
                onClose={closeApproveDialog}
                onSubmit={onApproveSubmit}
              />
              <CompensationReviewRequestDialogReject
                isOpen={isRejectDialogOpen}
                onClose={closeRejectDialog}
                onSubmit={onRejectSubmit}
              />
            </>
          )}
        </>
      )}
      {status === Status.IN_PROCESS && !isCurrentApprover && canWriteOnBehalf && (
        <>
          <Button type='button' intent='primary' kind='primary' onClick={openApproveOnBehalfDialog}>
            {intl.formatMessage(messages.buttonApproveOnBehalf)}
          </Button>
          <Button type='button' intent='negative' kind='primary' onClick={openRejectOnBehalfDialog}>
            {intl.formatMessage(messages.buttonRejectOnBehalf)}
          </Button>
          <CompensationReviewRequestDialogApprove
            isOpen={isApproveOnBehalfDialogOpen}
            onBehalfOf={currentDecision?.user}
            onClose={closeApproveOnBehalfDialog}
            onSubmit={onApproveOnBehalfSubmit}
          />
          <CompensationReviewRequestDialogReject
            isOpen={isRejectOnBehalfDialogOpen}
            onBehalfOf={currentDecision?.user}
            onClose={closeRejectOnBehalfDialog}
            onSubmit={onRejectOnBehalfSubmit}
          />
        </>
      )}
      {status === Status.APPROVED && canSetCompensationsRecorded && compensationChange === ChangeValue.YES && (
        <>
          <Button type='button' intent='primary' kind='primary' size='m' onClick={openSetCompensationsRecordedDialog}>
            {intl.formatMessage(messages.buttonRecorded)}
          </Button>
          <CompensationReviewRequestDialogSetRecorded
            isOpen={isSetCompensationsRecordedDialogOpen}
            onClose={closeSetCompensationsRecordedDialog}
            onSubmit={onSetCompensationsRecordedSubmit}
          />
        </>
      )}
      {status === Status.APPROVED && canSetStockOptionsRecorded && stockOptionsChange === ChangeValue.YES && (
        <>
          <Button type='button' intent='primary' kind='primary' size='m' onClick={openSetStockOptionsRecordedDialog}>
            {intl.formatMessage(messages.buttonStockOptionsRecorded)}
          </Button>
          <CompensationReviewRequestDialogSetRecorded
            isOpen={isSetStockOptionsRecordedDialogOpen}
            onClose={closeSetCompensationsRecordedDialog}
            onSubmit={onSetStockOptionsRecordedSubmit}
          />
        </>
      )}
    </ButtonGroup>
  );
}
