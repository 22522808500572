import React, {createContext, useContext, useMemo, type ReactNode} from 'react';
import {add} from 'date-fns';
import {TimelineUnit, type TimelineConfig, type TimelineConfigInit} from '../types';
import {unitWidth, getTimestamp, unitMs, unitTimelineSize, createInterval} from '../utils';

type ConfigProviderProps = TimelineConfigInit & {
  children: ReactNode;
};

const ConfigContext = createContext<TimelineConfig | undefined>(undefined);

export function useTimeline() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useTimeline must be used within a TimelineProvider');
  }
  return context;
}

export function Provider({
  start,
  end,
  unit = TimelineUnit.DAY,
  offset = new Date().getTimezoneOffset(),
  children,
}: ConfigProviderProps) {
  const contextValue = useMemo(() => {
    // mixed offset from local timezone to target timezone
    // by default we will use local timzeone
    const offsetMs = new Date().getTimezoneOffset() * 60 * 1000 - offset * 60 * 1000;
    const startPosition = getTimestamp(start);
    const boundary = createInterval({start: startPosition, end: end ?? add(startPosition, unitTimelineSize[unit])});
    const units = (boundary.end - boundary.start) / unitMs[unit];

    const config: TimelineConfig = {
      boundary,
      unit,
      unitWidth: unitWidth[unit],
      offsetMs,
      width: units * unitWidth[unit],
      msToPixelRatio: unitWidth[unit] / unitMs[unit],
    };

    return config;
  }, [unit, start, end, offset]);

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
}
