import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ResourceId} from 'domain/resource/model';
import {Team} from 'domain/team/model';
import {createStore} from 'effector';
import {getResourceTeamsFx} from '.';

type ResourceTeamsState = {
  byResourceId: Record<ResourceId, Team[]>;
  byResourceIdState: Record<ResourceId, DataState>;
};

export const $resourceTeams = createStore<ResourceTeamsState>({
  byResourceId: {},
  byResourceIdState: {},
})
  .on(getResourceTeamsFx, (state, resourceId) => {
    return {
      ...state,
      byResourceIdState: {
        ...state.byResourceIdState,
        [resourceId]: DataState.LOADING,
      },
    };
  })
  .on(getResourceTeamsFx.fail, (state, {params: resourceId}) => {
    return {
      ...state,
      byResourceIdState: {
        ...state.byResourceIdState,
        [resourceId]: DataState.FAILED,
      },
    };
  })
  .on(getResourceTeamsFx.done, (state, {params: resourceId, result}) => {
    return {
      ...state,
      byResourceId: {
        ...state.byResourceId,
        [resourceId]: result,
      },
      byResourceIdState: {
        ...state.byResourceIdState,
        [resourceId]: DataState.LOADED,
      },
    };
  });
