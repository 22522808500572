import {Breadcrumb} from 'components/widgets/Page';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {getTeamBreadcrumbs} from 'domain/team/utils/getTeamBreadcrumbs';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/teams/titles';
import {teamsUrls} from 'routes/teams/urls';

type Options = {
  teamById: Record<TeamId, Team | undefined>;
  id: TeamId | undefined;
  includeCurrentTeam?: boolean;
};

export function useTeamBreadcrumbs({teamById, id, includeCurrentTeam = false}: Options): Breadcrumb[] {
  const intl = useIntl();

  return useMemo(() => {
    if (!id) {
      return [];
    }
    const teamBreadcrumbs: Breadcrumb[] = getTeamBreadcrumbs(id, teamById, includeCurrentTeam).map((team) => ({
      title: team.name,
      href: teamsUrls.team({id: team.id}),
    }));
    return [{title: intl.formatMessage(pageTitles.teams), href: teamsUrls.teams()}, ...teamBreadcrumbs];
  }, [id, teamById, intl]);
}
