export function mapGroupBy<K extends string, T extends Record<K, unknown>>(items: T[], key: K): Map<unknown, T[]> {
  const groups = new Map<T[K], T[]>();
  items.forEach((item) => {
    if (groups.has(item[key])) {
      groups.get(item[key])!.push(item);
    } else {
      groups.set(item[key], [item]);
    }
  });
  return groups;
}
