import {TotalCountData} from '@joomcode/joom-admin/dataTypes/entityStore';
import {QueryRequestPayload} from 'api/types';
import {CompensationReviewRequestApproveConfig} from 'domain/compensationReviewRequest/api/approve';
import {CompensationReviewRequestCreateConfig} from 'domain/compensationReviewRequest/api/create';
import {CompensationReviewRequestRejectConfig} from 'domain/compensationReviewRequest/api/reject';
import {CompensationReviewRequestSetRecordedConfig} from 'domain/compensationReviewRequest/api/setRecorded';
import {CompensationReviewRequest, CompensationReviewRequestId} from 'domain/compensationReviewRequest/model';
import {Filters} from 'domain/compensationReviewRequest/model/filters';
import {createEffect} from 'effector';

export const approveCompensationReviewRequestFx = createEffect<
  CompensationReviewRequestApproveConfig,
  CompensationReviewRequest
>('approve compensation review request');

export const approveCompensationReviewRequestOnBehalfFx = createEffect<
  CompensationReviewRequestApproveConfig,
  CompensationReviewRequest
>('approve compensation review request on behalf');

export const cancelCompensationReviewRequestFx = createEffect<CompensationReviewRequestId, CompensationReviewRequest>(
  'cancel compensation review request',
);

export const createCompensationReviewRequestFx = createEffect<
  CompensationReviewRequestCreateConfig,
  CompensationReviewRequest
>('create compensation review request');

export const getAllCompensationReviewRequestsFx = createEffect<
  QueryRequestPayload<Filters>,
  TotalCountData<CompensationReviewRequest>
>('get all compensation review requests');

export const getCompensationReviewRequestByIdFx = createEffect<CompensationReviewRequestId, CompensationReviewRequest>(
  'get compensation review request by id',
);

export const getMyCompensationReviewRequestsFx = createEffect<
  QueryRequestPayload<Filters>,
  TotalCountData<CompensationReviewRequest>
>('get my compensation review requests');

export const getSubordinateCompensationReviewRequestsFx = createEffect<
  QueryRequestPayload<Filters>,
  TotalCountData<CompensationReviewRequest>
>('get subordinate compensation review requests');

export const rejectCompensationReviewRequestFx = createEffect<
  CompensationReviewRequestRejectConfig,
  CompensationReviewRequest
>('reject compensation review request');

export const rejectCompensationReviewRequestOnBehalfFx = createEffect<
  CompensationReviewRequestRejectConfig,
  CompensationReviewRequest
>('reject compensation review request on behalf');

export const setCompensationsRecordedFx = createEffect<
  CompensationReviewRequestSetRecordedConfig,
  CompensationReviewRequest
>('get compensations recorded');

export const setStockOptionsRecordedFx = createEffect<
  CompensationReviewRequestSetRecordedConfig,
  CompensationReviewRequest
>('set stock options recorded');
