import type {ReactNode, UIEventHandler} from 'react';

export type Timestamp = number;

export type DateInterval = {
  /**
   * The start of the interval. Date or timestamp.
   */
  start: DateLike;
  /**
   * The end of the interval. Date or timestamp.
   */
  end: DateLike;
};

export type Interval = {
  /**
   * The start of the interval in milliseconds. Can be negative or relative.
   */
  start: number;
  /**
   * The end of the interval in milliseconds. Can be negative or relative.
   */
  end: number;
};

export type IntervalGroup<T> = Interval & {items: T[]};

export type DateLike = Date | string | number;

export type GroupId = string;

export type TimelineSchema = Record<GroupId, unknown>;

export type TimelineNoData = never;

/**
 * The difference, in minutes, between this date as evaluated in the UTC
 * time zone, and the same date as evaluated in the local time zone.
 */
export type TimezoneOffset = number;

export enum TimelineUnit {
  DAY = 'day',
  DAY_NARROW = 'day-narrow',
  WEEK = 'week',
}

type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;

type IsAny<T> = IfAny<T, true, never>;

export type TimelineItem<T extends TimelineSchema = TimelineSchema, K extends keyof T = GroupId> = {
  start: DateLike;
  end: DateLike;
  group: K;
} & (T[K] extends TimelineNoData | IsAny<T[K]> ? {data?: unknown} : {data: T[K]});

export type TimelineItems<T extends TimelineSchema = TimelineSchema> = {
  [K in keyof T]: TimelineItem<T, K>;
}[keyof T];

export type TimelineHeadingGroup<T extends TimelineSchema, K extends keyof T> = {
  title: ReactNode;
  id?: K;
  render?: (data: IntervalGroup<TimelineItem<T, K>>, config: TimelineConfig) => ReactNode;
};

export type TimelineItemGroup<T extends TimelineSchema, K extends keyof T> = {
  height?: number | string;
  id: K;
  color?: string;
  render?: (data: IntervalGroup<TimelineItem<T, K>>, config: TimelineConfig) => ReactNode;
};

export type TimelineGroup<T extends TimelineSchema, K extends keyof T> =
  | TimelineHeadingGroup<T, K>
  | TimelineItemGroup<T, K>;

export type TimelineGroups<T extends TimelineSchema> = {
  [K in keyof T]: TimelineGroup<T, K>;
}[keyof T];

export type TimelineItemGroups<T extends TimelineSchema> = {
  [K in keyof T]: TimelineItemGroup<T, K>;
}[keyof T];

export type TimelineHeadingGroups<T extends TimelineSchema> = {
  [K in keyof T]: TimelineHeadingGroup<T, K>;
}[keyof T];

export type GroupProps<T extends TimelineSchema> = {
  group: TimelineGroups<T>;
  items: TimelineItems<T>[];
};

export type ItemGroupProps<T extends TimelineSchema> = {
  group: TimelineItemGroups<T>;
  items: TimelineItems<T>[];
};

export type HeadingGroupProps<T extends TimelineSchema> = {
  group: TimelineHeadingGroups<T>;
  items: TimelineItems<T>[];
};

export type TimelineConfigInit = {
  /**
   * The start date of the timeline.
   */
  start: DateLike;
  /**
   * The end date of the timeline.
   * If not provided, the timeline will be calclulated based on `unit` and `start`:
   * - `TimelineUnit.DAY`: 1 week after `start`
   * - `TimelineUnit.DAY_NARROW`: 1 month after `start`
   * - `TimelineUnit.WEEK`: 3 months after `start`
   */
  end?: DateLike;
  /**
   * The unit of the timeline.
   * Default is `TimelineUnit.DAY`.
   */
  unit?: TimelineUnit;
  /**
   * With of cells.
   */
  unitWidth?: number;
  /**
   * Offset between the local time zone and the UTC time zone.
   * Default is local offset from UTC: `new Date().getTimezoneOffset()`.
   */
  offset?: TimezoneOffset;
};

export type TimelineProps<T extends TimelineSchema> = TimelineConfigInit & {
  /**
   * Represented lines in the timeline.
   */
  groups: TimelineGroups<T>[];
  /**
   * Timeline items.
   */
  items: TimelineItems<T>[];
  /**
   * Timeline items.
   */
  onScroll?: UIEventHandler<HTMLDivElement>;
};

export type TimelineConfig = Readonly<{
  boundary: Interval;
  /**
   * Timilne unit.
   */
  unit: TimelineUnit;
  /**
   * Unit width in pixels.
   */
  unitWidth: number;
  /**
   * Timeline width in pixels.
   */
  width: number;
  /**
   * 1 ms in pixels.
   */
  msToPixelRatio: number;
  /**
   * Offset in milliseconds to add to `Timestamp` to get timezoned local time.
   */
  offsetMs: number;
}>;

export type TimelineItemPosition = {
  /**
   * The start position of the item in pixels.
   */
  start: number;
  /**
   * The end position of the item in pixels.
   */
  end: number;
};
