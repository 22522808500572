import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonFull: {
    defaultMessage: 'Add override',
    description: '[button] Duty override create button',
  },
  button: {
    defaultMessage: 'Override',
    description: '[button] Duty override create button',
  },
});
