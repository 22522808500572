import {Item, TimelineUnit} from '@joomcode/joom-ui/Timeline';
import {IntervalGroup, TimelineItem} from '@joomcode/joom-ui/Timeline/types';
import {ScheduleIssueType} from 'domain/duty/model/scheduleIssue/type';
import {ScheduleIssueBadge} from 'domain/duty/widgets/ScheduleIssueBadge';
import {User} from 'domain/user/model';
import React, {useCallback} from 'react';
import {IssueIcon} from '../IssueIcon';
import {Tooltip} from '../Tooltip';
import {Schema, Severity, type TimelineGroupRender} from '../types';

function getTooltipItems(interval: IntervalGroup<TimelineItem<Schema, 'issueIcon'>>, users: User[]) {
  return interval.items.map((item) => {
    return {
      ...item,
      data: {
        ...item.data,
        tooltip: users
          .filter((user) => {
            return item.data.item.issues.filter(({userId}) => userId === user.id).length > 0;
          })
          .map((user) => {
            return {
              user,
              content: (
                <>
                  {item.data.item.issues
                    .filter(({userId}) => userId === user.id)
                    .map((issue) => (
                      <ScheduleIssueBadge key={issue.id} issue={issue} user={user} />
                    ))}
                </>
              ),
            };
          }),
      },
    };
  });
}

export function useIssueIconRender(unit: TimelineUnit) {
  return useCallback<TimelineGroupRender<'issueIcon'>>(
    (interval) => {
      if (unit !== TimelineUnit.WEEK) {
        return null;
      }

      const users = interval.items.flatMap((item) => item.data.item.slot.users);
      const tooltipItems = getTooltipItems(interval, users);
      const severity = interval.items.reduce(
        (acc, item) =>
          Math.max(
            acc,
            item.data.item.issues.some((issue) => issue.type === ScheduleIssueType.TERMINATION)
              ? Severity.HIGH
              : Severity.LOW,
          ),
        Severity.LOW,
      );

      return (
        <Item interval={interval}>
          <Tooltip items={tooltipItems}>
            <IssueIcon severity={severity} />
          </Tooltip>
        </Item>
      );
    },
    [unit],
  );
}
