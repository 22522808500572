import {defineMessages, MessageDescriptor} from 'react-intl';
import {HrConsoleTab} from '.';

export const hrConsoleTabNames = defineMessages<HrConsoleTab>({
  [HrConsoleTab.USERS]: {
    defaultMessage: 'Current joomers information',
    description: '[title] HR console tab name',
  },
  [HrConsoleTab.CURRENT_SALARY_RANGES]: {
    defaultMessage: 'Salary ranges',
    description: '[title] HR console tab name',
  },
  [HrConsoleTab.MANAGEMENT]: {
    defaultMessage: 'Salary ranges management',
    description: '[title] HR console tab name',
  },
  [HrConsoleTab.COMPENSATION_REVIEW_REQUESTS]: {
    defaultMessage: 'Compensation review',
    description: '[title] HR console tab name',
  },
});

export const hrConsoleTabNamesForManager: Partial<Record<HrConsoleTab, MessageDescriptor>> = defineMessages({
  [HrConsoleTab.USERS]: {
    defaultMessage: 'Reports information',
    description: '[title] HR console tab name',
  },
});
