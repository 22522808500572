import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  startDate: {
    defaultMessage: 'Start date',
    description: 'Duty override form field label',
  },
  startTime: {
    defaultMessage: 'Start time',
    description: 'Duty override form field label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Duty override form field label',
  },
  endTime: {
    defaultMessage: 'End time',
    description: 'Duty override form field label',
  },
  timeZone: {
    defaultMessage: 'Time zone',
    description: 'Duty override form field label',
  },
  overrideDuty: {
    defaultMessage: 'Override duty',
    description: 'Duty override form field label',
  },
});

export const messages = defineMessages({
  buttonSubmit: {
    defaultMessage: 'Create',
    description: '[button] Submit duty override form',
  },
  buttonBack: {
    defaultMessage: 'Back',
    description: '[button] Going back from duty override form',
  },
  success: {
    defaultMessage: 'Override for {dutyName} from {startDate} {timeZone} to {endDate} is set for {users}.',
    description: '[message] Duty override creation success message',
  },
  main: {
    defaultMessage: 'Main',
    description: 'Duty override form timeline group',
  },
  localTime: {
    defaultMessage: 'Local time: {time}',
    description: 'Local time message',
  },
});
