import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {ScheduleIssue} from 'domain/duty/model/scheduleIssue';
import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {User} from 'domain/user/model';
import {dateToString} from 'models/system/formattedDate';
import {IntlShape} from 'react-intl';
import {FormState} from '.';

export const getDate = (date: string, time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const result = new Date(date);

  result.setUTCHours(hours);
  result.setUTCMinutes(minutes);
  result.setUTCSeconds(seconds);

  return result;
};

export const getFormattedDate = (date: string, time: string, intl: IntlShape) =>
  intl.formatDate(getDate(date, time), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'utc',
  });

export const getDiffFromState = (state: FormState) => ({
  slot: state.slot,
  startDate: getDate(state.startDate, state.startTime).toISOString(),
  endDate: getDate(state.endDate, state.endTime).toISOString(),
});

export const getNewScheduleItem = (
  state: FormState,
  issues: ScheduleIssue[],
  teamMembers: User[],
): ScheduleItem | undefined => {
  const startDate = getDate(state.startDate, state.startTime);
  const endDate = getDate(state.endDate, state.endTime);
  const users = state.slot?.userIds.map((id) => teamMembers.find((user) => user.id === id)).filter(isNotNullish);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || !users.length) {
    return undefined;
  }

  return {
    id: undefined,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    slot: {
      id: undefined,
      users,
    },
    issues,
  };
};

export const getInitialDate = (name: string, search: string): string | undefined => {
  const param = new URLSearchParams(search).get(name);
  const date = param ? new Date(param) : null;

  return dateToString(date) || undefined;
};

export const getInitialTime = (name: string, search: string): string | undefined => {
  const param = new URLSearchParams(search).get(name);
  const date = param ? new Date(param) : null;

  if (!date) {
    return undefined;
  }

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

type GetLocalTimeProps = {
  offset: number;
  time: string;
  date: string | undefined;
  intl: IntlShape;
};

export const getLocalTime = ({date, time, offset, intl}: GetLocalTimeProps): string => {
  const [hours, minutes] = time.split(':').map(Number);

  const givenDate = date ? new Date(date) : new Date();
  const utcDate = new Date(
    Date.UTC(givenDate.getUTCFullYear(), givenDate.getUTCMonth(), givenDate.getUTCDate(), hours, minutes - offset),
  );

  const formattedDate = intl.formatDate(utcDate, {year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'utc'});
  const formattedTime = intl.formatTime(utcDate, {hour: '2-digit', minute: '2-digit', timeZone: 'utc'});
  const isDateChanged = date && givenDate.getDate() !== utcDate.getDate();

  return isDateChanged ? `${formattedDate}, ${formattedTime}` : formattedTime;
};
