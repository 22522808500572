import {intranetApi} from 'apiClient';
import {DutyId} from 'domain/duty/model/lite';
import {RotationSettingsDiff} from 'domain/duty/model/rotatationSettings/diff';
import {Schedule, scheduleSchema} from 'domain/duty/model/schedule';

type GenerateScheduleParams = {
  id: DutyId;
};

export type GenerateScheduleConfig = {
  diff: RotationSettingsDiff;
  params?: GenerateScheduleParams;
};

export const generateSchedule = async ({diff, params}: GenerateScheduleConfig): Promise<Schedule> => {
  const {data} = await intranetApi.post('/v1/teams/duties/generateSchedule', diff, {params});

  return scheduleSchema.runWithException(data);
};
