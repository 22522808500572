import {differenceInDays, differenceInHours, formatDuration} from 'date-fns';

export function getDuration(startDate: string, endDate: string): string {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const totalHours = differenceInHours(end, start);
  const days = differenceInDays(end, start);
  const hours = totalHours - days * 24;

  return formatDuration({days, hours});
}
