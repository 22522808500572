import cn from 'classnames';
import React from 'react';
import {TooltipFaq} from '../../../TooltipFaq';
import styles from './index.css';

type Props = {
  name: React.ReactNode;
  description?: React.ReactNode;
};

export function HeaderTitle({name, description}: Props) {
  return (
    <div className={styles.title}>
      {description ? (
        <TooltipFaq content={description} placement='top'>
          <span className={cn(styles.name, styles.hover)}>{name}</span>
        </TooltipFaq>
      ) : (
        <span className={styles.name}>{name}</span>
      )}
    </div>
  );
}
