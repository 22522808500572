import {Page} from 'components/widgets/Page';
import {useHrConsolePageTabs} from 'domain/hrConsole/hooks/useHrConsolePageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {HrConsolePageTabs} from 'domain/hrConsole/widgets/PageTabs';
import {useUserFullInfoReadAccess} from 'domain/userFullInfoRecord/hooks/useReadAccess';
import {UserFullInfoRecordList} from 'domain/userFullInfoRecord/widgets/List';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';

export function HrConsoleUsersPage() {
  const intl = useIntl();
  const userFullInfoReadAccess = useUserFullInfoReadAccess();
  const {showTabs, availableTabs} = useHrConsolePageTabs();

  if (!userFullInfoReadAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.hrConsole)}
      headerContent={showTabs && <HrConsolePageTabs availableTabs={availableTabs} activeTab={HrConsoleTab.USERS} />}
    >
      <UserFullInfoRecordList />
    </Page>
  );
}
