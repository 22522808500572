import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {ReactComponent as HandWithKeyIcon} from '@joomcode/joom-ui/icons/core/handWithKey.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {LinkWithIcon} from 'components/ui/LinkWithIcon';
import {SocialContact} from 'components/ui/SocialContact';
import {Permission} from 'domain/permission/model';
import {Team} from 'domain/team/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {teamsUrls} from 'routes/teams/urls';
import {useAcl} from 'services/acl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {Feature, useFeature} from 'services/features';
import {ReactComponent as DutyIcon} from './icons/duty.svg';
import {ReactComponent as GoogleCalendarIcon} from './icons/googleCalendar.svg';
import {messages} from './messages';
import styles from './styles.css';

enum AnalyticsElement {
  JDM_ACCESSES = 'team_links_jdm_accesses',
  SLACK_CHANNEL = 'team_links_slack_channel',
  NOTION_PAGE = 'team_links_notion_page',
  TIME_OFF_CALENDAR = 'team_links_time_off_calendar',
  TIME_OFF_CALENDAR_WITH_SUBTEAMS = 'team_links_time_off_calendar_with_subteams',
  DUTIES = 'team_links_duties',
  CREATE_DUTY = 'team_links_create_duty',
}

type Props = {
  isTeamOwner: boolean;
  team: Team;
};

export function TeamLinksPanel({isTeamOwner, team}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const canReadTeamRoleBindings = acl.hasPermission(Permission.TEAM_ROLE_BINDING_READ);
  const {id, notionLink, slackChannelLink, slackChannelName, calendarLink, calendarWithSubteamsLink} = team;
  const dutyFeature = useFeature(Feature.DUTY);
  const clickHandlers = useMemo(
    () =>
      arrayToObject(
        getEnumValues(AnalyticsElement),
        (element) => element,
        (element) => () => gtag.logEvent('element_click', {element}),
      ),
    [gtag],
  );

  return (
    <Panel stretchHeight>
      <Panel.Header title={intl.formatMessage(messages.title)} withSeparator />
      <div className={styles.contentWrap}>
        {canReadTeamRoleBindings && (
          <div className={styles.link}>
            <LinkWithIcon
              link={teamsUrls.roleBindings({id})}
              coloredLink
              icon={<HandWithKeyIcon />}
              iconLabel={intl.formatMessage(messages.accesses)}
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.JDM_ACCESSES]}
            >
              {intl.formatMessage(messages.accesses)}
            </LinkWithIcon>
          </div>
        )}
        {slackChannelLink && (
          <div className={styles.link}>
            <SocialContact
              type='slack'
              link={slackChannelLink}
              externalLink
              coloredLink
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.SLACK_CHANNEL]}
            >
              {slackChannelName || intl.formatMessage(messages.slackChannel)}
            </SocialContact>
          </div>
        )}
        {notionLink && (
          <div className={styles.link}>
            <SocialContact
              type='notion'
              link={notionLink}
              externalLink
              coloredLink
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.NOTION_PAGE]}
            >
              {intl.formatMessage(messages.notionPage)}
            </SocialContact>
          </div>
        )}
        {calendarLink && (
          <div className={styles.link}>
            <LinkWithIcon
              link={calendarLink}
              externalLink
              coloredLink
              icon={<GoogleCalendarIcon />}
              iconLabel={intl.formatMessage(messages.calendarLink)}
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.TIME_OFF_CALENDAR]}
            >
              {intl.formatMessage(messages.calendarLink)}
            </LinkWithIcon>
          </div>
        )}
        {calendarWithSubteamsLink && (
          <div className={styles.link}>
            <LinkWithIcon
              link={calendarWithSubteamsLink}
              externalLink
              coloredLink
              icon={<GoogleCalendarIcon />}
              iconLabel={intl.formatMessage(messages.calendarWithSubteamsLink)}
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.TIME_OFF_CALENDAR_WITH_SUBTEAMS]}
            >
              {intl.formatMessage(messages.calendarWithSubteamsLink)}
            </LinkWithIcon>
          </div>
        )}
        {dutyFeature.isAvailable && team.duties?.length && (
          <div className={styles.link}>
            <LinkWithIcon
              link={teamsUrls.duties({id})}
              coloredLink
              icon={<DutyIcon />}
              iconLabel={intl.formatMessage(messages.duties)}
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.DUTIES]}
            >
              {intl.formatMessage(messages.duties)}
            </LinkWithIcon>
          </div>
        )}
        {dutyFeature.isAvailable && isTeamOwner && !team.duties?.length && (
          <div className={styles.link}>
            <LinkWithIcon
              link={teamsUrls.dutyCreate({id})}
              coloredLink
              icon={<DutyIcon />}
              iconLabel={intl.formatMessage(messages.createDuty)}
              iconClassName={styles.icon}
              onClick={clickHandlers[AnalyticsElement.CREATE_DUTY]}
            >
              {intl.formatMessage(messages.createDuty)}
            </LinkWithIcon>
          </div>
        )}
      </div>
    </Panel>
  );
}
