import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ResourceId} from 'domain/resource/model';
import {getResourceTeamsFx} from 'domain/resource/stores/teams';
import {$resourceTeams} from 'domain/resource/stores/teams/state';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {useStoreMap} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: Team[] | null;
  dataState: DataState;
  byId: Record<TeamId, Team> | undefined;
};

export function useResourceTeams(id?: ResourceId): ReturnShape {
  const data = useStoreMap({
    store: $resourceTeams,
    keys: [id],
    fn: ({byResourceId}, [resourceId]) => (resourceId && byResourceId[resourceId]) || null,
  });
  const dataState = useStoreMap({
    store: $resourceTeams,
    keys: [id],
    fn: ({byResourceIdState}, [resourceId]) => (resourceId && byResourceIdState[resourceId]) || DataState.IDLE,
  });

  const byId = useMemo(() => arrayToObject(data || [], (team) => team.id), [data]);

  useEffect(() => {
    if (id && !isLoadingOrLoaded(dataState)) {
      getResourceTeamsFx(id).catch((error: Error) => toaster.error(error.message));
    }
  }, [id]);

  return {
    dataState,
    data,
    byId,
  };
}
