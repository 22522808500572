import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {ResourceId} from 'domain/resource/model';
import {Team, teamSchema} from 'domain/team/model';

export const getTeamsWithAccess = async (id: ResourceId): Promise<Team[]> => {
  const {data} = await intranetApi.get('/v1/jdm/resources/getTeamsWithAccess', {params: {id}});

  return array(teamSchema).runWithException(data);
};
