import React, {Fragment, type Key} from 'react';
import {Item} from '../Item';
import {useTimeline} from '../Timeline/Provider';
import {type GroupId, type ItemGroupProps} from '../types';
import {DEFAULT_GROUP_HEIGHT} from '../utils';
import styles from './ItemGroup.css';
import {useGroupIntervals} from '../hooks/useGroupIntervals';

export function ItemGroup<T extends Record<GroupId, unknown>>(props: ItemGroupProps<T>) {
  const context = useTimeline();
  const {group} = props;

  const intervals = useGroupIntervals(props.items);
  const children = intervals.map((interval) => {
    if (group.render) {
      return <Fragment key={interval.start}>{group.render(interval, context)}</Fragment>;
    }
    return <Item key={interval.start} interval={interval} color={group.color} />;
  });

  return (
    <article className={styles.ItemGroup} key={group.id as Key}>
      <div style={{height: group.height ?? DEFAULT_GROUP_HEIGHT}} className={styles.items}>
        {children}
      </div>
    </article>
  );
}
