import {TimelineUnit} from '@joomcode/joom-ui/Timeline';
import {DutyTimelineSize} from '../types';

export function useTimelineUnit(size: DutyTimelineSize): TimelineUnit {
  switch (size) {
    case DutyTimelineSize.WEEK:
      return TimelineUnit.DAY;
    case DutyTimelineSize.MONTH:
      return TimelineUnit.DAY_NARROW;
    default:
      return TimelineUnit.WEEK;
  }
}
