import {useResourceTeams} from 'domain/resource/hooks/useResourceTeams';
import {ResourceId} from 'domain/resource/model';
import {TeamsTreePanel} from 'domain/team/widgets/TreePanel';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  resourceId: ResourceId;
};

export function ResourceTeamsPanel({resourceId}: Props) {
  const intl = useIntl();
  const {byId} = useResourceTeams(resourceId);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();

  if (!byId) {
    return null;
  }

  return (
    <TeamsTreePanel
      collapseMode='collapse'
      isExpandedByDefault
      onSearch={setSearchQuery}
      searchQuery={searchQuery}
      teamById={byId}
      title={intl.formatMessage(messages.panelTitle)}
      withMarginBottom={false}
    />
  );
}
