import React from 'react';
import styles from '../../DataTable.css';

export const ScrollWrapper = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>(
  function ScrollWrapper(props, ref) {
    return <div ref={ref} {...props} className={styles.scrollView} />;
  },
);

export const ManualScrollWrapper = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>(
  function ManualScrollWrapper({style, onScroll, ...props}, ref) {
    return <ScrollWrapper ref={ref} {...props} style={{...style, height: 'auto'}} />;
  },
);
