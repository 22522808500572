import {ScheduleItem} from 'domain/duty/model/scheduleItem';
import {TimelineItemWithoutGroup} from '../types';

export function getIssueIconItems(items: ScheduleItem[]): TimelineItemWithoutGroup<'issueIcon'>[] {
  return items
    .map((item) => ({
      start: item.startDate,
      end: item.endDate,
      data: {
        item,
      },
    }))
    .filter((item) => item.data.item.issues.length > 0);
}
