import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import React from 'react';
import type {DutyTimelineSize} from '../../types';
import {SizeButton} from './SizeButton';

type PropsType = {
  value: DutyTimelineSize;
  sizes: DutyTimelineSize[];
  onChange: (value: DutyTimelineSize) => void;
};

export function SelectSize({value, sizes, onChange}: PropsType) {
  if (sizes.length <= 1) {
    return null;
  }

  return (
    <ButtonGroup>
      {sizes.map((size) => (
        <SizeButton key={size} selected={value === size} size={size} onClick={onChange} />
      ))}
    </ButtonGroup>
  );
}
