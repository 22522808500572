import React from 'react';
import {ColumnsSelect} from '../../components/ColumnsSelect';
import {ColumnsTreeSelect} from '../../components/ColumnsTreeSelect';
import {RowActionsDisclosure} from '../../components/RowActionsDisclosure';
import {Column, ColumnTreeOptions, GetRowActions} from '../../types';
import styles from './index.css';

export const CONFIG_COLUMN_ID = '__config';

type Options<Item> = {
  ariaLabel: string;
  availableColumns: Column<Item>[];
  visibleColumnIds: string[];
  columnTreeOptions?: ColumnTreeOptions;
  rowActionsAriaLabel: string;
  onColumnsVisibilityChange: (visibleColumns: string[]) => void;
  getRowActions?: GetRowActions<Item>;
};
export function getConfigColumn<Item>({
  columnTreeOptions,
  rowActionsAriaLabel,
  getRowActions,
  ...options
}: Options<Item>): Column<Item> | null {
  return {
    id: CONFIG_COLUMN_ID,
    defaultWidth: 64,
    resizable: false,
    noStretch: true,
    sticky: 'right',
    align: 'center',
    name: (
      <div className={styles.configCell}>
        {columnTreeOptions ? (
          <ColumnsTreeSelect columnTreeOptions={columnTreeOptions} {...options} />
        ) : (
          <ColumnsSelect {...options} />
        )}
      </div>
    ),
    render: (item) =>
      getRowActions ? (
        <RowActionsDisclosure item={item} rowActionsAriaLabel={rowActionsAriaLabel} getRowActions={getRowActions} />
      ) : null,
  };
}
