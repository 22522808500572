import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {OfficeTooltip} from 'domain/officeMap/office/widgets/Tooltip';
import {User} from 'domain/user/model';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {generalMessages} from 'i18n/messages/general';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {messages} from '../messages';
import styles from './SeatLessUser.css';

type Props = {
  user: User;
};

export const SeatLessUser = ({user}: Props) => {
  const intl = useIntl();
  const {open, close, isOpen} = usePopupState(true);
  useEffect(open, [open, user]);

  return (
    <OfficeTooltip
      content={
        <div className={styles.userInfo}>
          <ListedUser user={user} withSalaryManagerIcon />
        </div>
      }
      enabled={isOpen}
      onHide={close}
      placement='right'
    >
      <button className={styles.unknownSeat} onClick={open} type='button'>
        <span
          role='img'
          aria-label={intl.formatMessage(messages.seatForUserNotFound, {
            userName: intl.formatMessage(generalMessages.fullName, {
              firstName: user.firstName,
              lastName: user.lastName,
            }),
          })}
        >
          🤷
        </span>
      </button>
    </OfficeTooltip>
  );
};
