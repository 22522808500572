import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import type {DateLike, Timestamp} from '../types';

export type TimelineUnitDateFns = {
  start: (date: Date | Timestamp) => Date;
  end: (date: Date | Timestamp) => Date;
  add: (date: Date | Timestamp, amount: number) => Date;
};

export const day: TimelineUnitDateFns = {
  start: startOfDay,
  end: endOfDay,
  add: addDays,
};

export const noon: TimelineUnitDateFns = {
  start: (d) => addHours(startOfDay(d), 12),
  end: (d) => addHours(endOfDay(d), 12),
  add: addDays,
};

export const week: TimelineUnitDateFns = {
  start: startOfWeek,
  end: endOfWeek,
  add: addWeeks,
};

export const month: TimelineUnitDateFns = {
  start: startOfMonth,
  end: endOfMonth,
  add: addMonths,
};

export function normalizeDate(maybeDate: DateLike): Date {
  if (maybeDate instanceof Date) {
    return maybeDate;
  }

  const date = new Date(maybeDate);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Invalid date: ${maybeDate}`);
  }

  return date;
}

export function getTimestamp(dateLike: DateLike): number {
  if (typeof dateLike === 'number') {
    return dateLike;
  }

  if (dateLike instanceof Date) {
    return dateLike.getTime();
  }

  const date = new Date(dateLike);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Invalid date: ${date}`);
  }

  return date.getTime();
}
