import {ExtractSchemaType, object, oneOfEnum, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {countryCodeSchema} from 'domain/countryCode/model';
import {dutyLiteSchema} from 'domain/duty/model/lite';
import {userIdSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {ScheduleIssueType} from './type';

export const scheduleIssueSchema = object({
  duty: optional(dutyLiteSchema),
  endDate: optional(formattedDateSchema),
  publicHolidayCountry: optional(countryCodeSchema),
  startDate: formattedDateSchema,
  type: oneOfEnum(ScheduleIssueType),
  userId: userIdSchema,
}).map((issue) => ({
  ...issue,
  id: `${issue.userId}_${issue.startDate}_${issue.endDate}_${issue.type}_${issue.duty?.id}`,
}));

export type ScheduleIssue = ExtractSchemaType<typeof scheduleIssueSchema>;
