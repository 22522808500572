import {Tree} from 'components/ui/Tree';
import {UserMemberships} from 'domain/user/model/memberships';
import {UserMembershipCard} from 'domain/user/widgets/MembershipCard';
import React, {memo, useCallback, useState} from 'react';

type Props = {
  membership: UserMemberships[number];
  membershipsByParentId: Map<string | undefined, UserMemberships>;
  depth?: number;
};

export const UserMembershipTreeItem = memo(({membership, membershipsByParentId, depth = 0}: Props) => {
  const teamId = membership.team.id;
  const hasChildren = membershipsByParentId.has(teamId);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded((state) => !state), []);

  return (
    <Tree.Item
      key={teamId}
      depth={depth}
      expanded={hasChildren ? expanded : undefined}
      onArrowClick={toggleExpanded}
      content={<UserMembershipCard {...membership} />}
    >
      {membershipsByParentId
        .get(teamId)
        ?.map((childMembership) => (
          <UserMembershipTreeItem
            key={childMembership.team.id}
            membership={childMembership}
            membershipsByParentId={membershipsByParentId}
            depth={depth + 1}
          />
        ))}
    </Tree.Item>
  );
});
