import React, {forwardRef, type CSSProperties, type ForwardedRef, type RefAttributes} from 'react';
import {useGetDatePosition} from '../hooks/useGetDatePosition';
import {Group} from '../Group';
import {Guides} from '../Guides';
import {Months} from '../Months';
import {Noons} from '../Noons';
import {Now} from '../Now';
import {Units} from '../Units';
import {TimelineUnit, type GroupId, type TimelineProps} from '../types';
import {createInterval, isBoundedBy, mapGroupBy, unitDateFns} from '../utils';
import {Provider, useTimeline} from './Provider';
import styles from './index.css';

function TimelineInProvider<T extends Record<GroupId, unknown>>(
  props: TimelineProps<T> & {parentRef: ForwardedRef<HTMLDivElement>},
) {
  const {items: unfilteredItems, groups, parentRef} = props;
  const {unitWidth, boundary, width, unit, offsetMs} = useTimeline();
  const items = unfilteredItems.filter((item) => isBoundedBy(createInterval(item), boundary));
  const groupedItems = mapGroupBy(items, 'group');
  const dateFns = unitDateFns[unit];
  const getDatePosition = useGetDatePosition();
  const guideShift = getDatePosition(dateFns.start(boundary.start + offsetMs).getTime() - offsetMs);

  const style = {
    '--joom-timeline-units-distance': `${unitWidth}px`,
    '--joom-timeline-units-shift': `${guideShift}px`,
    '--joom-timeline-width': `${width}px`,
  } as CSSProperties;

  return (
    <section ref={parentRef} className={styles.Timeline} style={style}>
      <div className={styles.content}>
        {unit === TimelineUnit.DAY_NARROW ? <Months /> : null}
        <Guides type='unit'>
          <Units />
          <Noons>
            <Now />
            <div className={styles.groups}>
              {groups.map((group, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Group key={index} group={group} items={groupedItems.get(group.id) || []} />
              ))}
            </div>
          </Noons>
        </Guides>
      </div>
    </section>
  );
}

export const Timeline = forwardRef((props, ref) => (
  <Provider {...props}>
    <TimelineInProvider {...props} parentRef={ref} />
  </Provider>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)) as <T extends Record<GroupId, any>>(
  props: TimelineProps<T> & RefAttributes<HTMLDivElement>,
) => ReturnType<typeof TimelineInProvider>;
