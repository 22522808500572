import React from 'react';
import {useIntl} from 'react-intl';
import {Item} from '../Item';
import {Sticky} from '../Sticky';
import {useDateIntervals} from '../hooks/useDateIntervals';
import {month} from '../utils';
import styles from './index.css';

export function Months() {
  const months = useDateIntervals(month);
  const intl = useIntl();

  return (
    <div className={styles.Months}>
      {months.map((item) => (
        <Item key={item.date.getTime()} interval={item.interval}>
          <Sticky overflow='hidden'>
            <time className={styles.item}>{intl.formatDate(item.date, {month: 'long'})}</time>
          </Sticky>
        </Item>
      ))}
    </div>
  );
}
