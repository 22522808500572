export function addSearchParams(path: string, searchParams?: Record<string, string | string[]>): string {
  if (!searchParams) {
    return path;
  }

  const params = new URLSearchParams();
  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v));
    } else {
      params.append(key, value);
    }
  });

  return `${path}?${params.toString()}`;
}
