import {Page} from 'components/widgets/Page';
import {useHrConsolePageTabs} from 'domain/hrConsole/hooks/useHrConsolePageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {HrConsolePageTabs} from 'domain/hrConsole/widgets/PageTabs';
import {useSalaryRangesReadAccess} from 'domain/salaryRange/hooks/useSalaryRangesReadAccess';
import {SalaryRangesReadAccess} from 'domain/salaryRange/model/access';
import {SalaryRangesDownloadButton} from 'domain/salaryRange/widgets/DownloadButton';
import {SalaryRangesList} from 'domain/salaryRange/widgets/List';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';

export function HrConsoleSalaryRangesPage() {
  const intl = useIntl();
  const {showTabs, availableTabs} = useHrConsolePageTabs();
  const salaryRangesReadAccess = useSalaryRangesReadAccess();

  if (!salaryRangesReadAccess) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.hrConsole)}
      actions={salaryRangesReadAccess === SalaryRangesReadAccess.ANY && <SalaryRangesDownloadButton />}
      headerContent={
        showTabs && <HrConsolePageTabs availableTabs={availableTabs} activeTab={HrConsoleTab.CURRENT_SALARY_RANGES} />
      }
    >
      <SalaryRangesList />
    </Page>
  );
}
