import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {GetJobContractsParams} from 'domain/jobContract/api/getByUserId';
import {JobContract} from 'domain/jobContract/model';
import {JobContractReadAccess} from 'domain/jobContract/model/access';
import {
  getJobContractsByUserIdFx,
  getMyJobContractsFx,
  getSubordinateJobContractsFx,
} from 'domain/jobContract/stores/main';
import {$jobContracts} from 'domain/jobContract/stores/main/state';
import {UserFull} from 'domain/user/model';
import {Effect} from 'effector';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';
import {useJobContractReadAccess} from './useJobContractReadAccess';

type ReturnShape = {
  data: JobContract[] | undefined;
  dataState: DataState;
  hasReadAccess: boolean;
};

const handlerByAccess: Record<JobContractReadAccess, Effect<GetJobContractsParams, JobContract[]>> = {
  [JobContractReadAccess.ANY]: getJobContractsByUserIdFx,
  [JobContractReadAccess.SUBORDINATE]: getSubordinateJobContractsFx,
  [JobContractReadAccess.MY]: getMyJobContractsFx,
};

export const useJobContracts = (user?: UserFull): ReturnShape => {
  const readAccess = useJobContractReadAccess(user);

  const data = useStoreMap({
    store: $jobContracts,
    keys: [user?.id],
    fn: ({byUserId}, [id]) => (id ? (byUserId[id] ?? null) : null),
  });
  const dataState = useStoreMap({
    store: $jobContracts,
    keys: [user?.id],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (user?.id && readAccess && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess]({userId: user.id}).catch(toaster.interceptThenThrowError);
    }
  }, [user?.id, readAccess]);

  return {data: data ?? undefined, dataState, hasReadAccess: Boolean(readAccess)};
};
